<template>
  <div v-if="!loading">
    <b-card :header="formTitle" class="add-a-discountPeriod-form">
      <b-form @submit.prevent="saveDiscountPeriod" autocomplete="off" novalidate>
        <fieldset :disabled="formSubmitting">
          <!-- Period name -->
          <b-form-group id="period_name"
            label="Discount period name"
            label-for="discount_period_name">

            <b-input-group>
              <b-form-input id="discount_period_name"
                type="text"
                v-model="discountPeriod.itemName"
                data-vv-as="Discount Period Name"
                name="itemName"
                :state="$validateState(veeFields, errors, 'itemName')"
                :maxlength="validationRules.itemName.max"
                v-validate="validationRules.itemName">
              </b-form-input>

              <b-form-invalid-feedback id="itemName_feedback">
                <ul style="padding-left: 0; list-style: none;">
                  <li v-for="error in errors.collect('itemName')">{{ error }}</li>
                </ul>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-row>
            <b-col lg="6">
              <b-form-group
                id="start_end_dates"
                label="Start and End Dates"
                label-for="discountPeriod_start_end">

                <v-date-picker
                  id="discountPeriod_start_end"
                  mode="range"
                  v-model="discountPeriod.dateRangeSelected"
                  name="dateRangeSelected"
                  :min-date="datePickerLimits.minDate"
                  :max-date="datePickerLimits.maxDate"
                  v-validate="'required'"
                  data-vv-as="discount periods date range"
                  show-caps>
                  <b-input-group slot-scope="props">
                    <template #append>
                      <b-input-group-text>
                        <font-awesome-icon :icon="calendarIcon"></font-awesome-icon>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      type="text"
                      :readonly="true"
                      style="background: #ffffff;"
                      :value="props.inputValue"
                      @change.native="props.updateValue($event.target.value)"
                      :state="$validateState(veeFields, errors, 'dateRangeSelected')">
                    </b-form-input>
                    <b-form-invalid-feedback id="itemName_feedback">
                        <ul style="padding-left: 0; list-style: none;">
                          <li v-for="error in errors.collect('dateRangeSelected')">{{ error }}</li>
                        </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </v-date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="6">
              <b-form-group id="percentage_discount"
                label="Percentage discount"
                label-for="discountPeriod_percentage_discount">
                <b-input-group append="%">
                  <b-form-input
                    type="number"
                    id="discountPeriod_percentage_discount"
                    v-model="discountPeriod.discountPercentage"
                    data-vv-as="Discount Percentage"
                    name="discountPercentage"
                    :state="$validateState(veeFields, errors, 'discountPercentage')"
                    :min="validationRules.discountPercentage.min_value"
                    :max="validationRules.discountPercentage.max_value"
                    step="0.1"
                    v-validate="validationRules.discountPercentage">
                  </b-form-input>
                  <b-form-invalid-feedback id="discountPercentage_feedback" style="order: 99;">
                    <ul style="padding-left: 0; list-style: none;">
                      <li v-for="error in errors.collect('discountPercentage')">{{ error }}</li>
                    </ul>
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- All villas, or independently select -->
          <b-form-group label="Apply discount to">
            <b-form-radio-group id="radios2"
              v-model="allPropertiesSelected"
              name="allOrSomeVillas">
              <b-form-radio :value="true">All properties</b-form-radio>
              <b-form-radio :value="false">Selected properties</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <!-- Assigned Properties -->
          <b-form-group v-show="!allPropertiesSelected"
            class="villa-multiselect">
            <div class="top">
              Assign Properties ({{ properties_selected.length }})
            </div>

            <b-form-checkbox-group stacked
              v-model="properties_selected"
              name="discounted_villas"
              :options="properties_options">
            </b-form-checkbox-group>
          </b-form-group>

          <div class="saver">
            <b-row align-h="end">
              <b-col cols="12" xl="6" class="mb-3" v-if="$route.params.oltiId !== '0'">
                <i>
                  Prices of existing bookings will not be affected.
                </i>
              </b-col>
              <b-col cols="6" xl="3" v-if="$route.params.oltiId !== '0'" class="text-right">
                <b-button variant="danger" v-confirm="{
                      ok: dialog => deleteDiscountPeriod(),
                      message: 'This will delete this discount period - are you sure?',
                      okText: 'Yes',
                      cancelText: 'No'
                  }">Delete</b-button>
                </b-col>
                <b-col cols="6" xl="3" v-if="$route.params.oltiId === '0'" class="text-right">
                  <b-button variant="info" @click="$router.go(-1)">Cancel</b-button>
                </b-col>
                <b-col cols="6" xl="3" class="text-right">
                  <b-button variant="primary" type="submit" :disabled="JSON.stringify(discountPeriod) === discountPeriodDiff">
                  <template v-if="$route.params.oltiId === '0'">
                    Save
                  </template>
                  <template v-else>
                    Update
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </b-form>
    </b-card>
  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import moment from 'moment'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faCalendarAlt from '@fortawesome/fontawesome-free-solid/faCalendarAlt'
import loader from '@/components/global/loader'

export default {
  components: {
    FontAwesomeIcon,
    loader
  },

  mounted () {
    this.fetchDiscountPeriod()
  },

  methods: {
    deleteDiscountPeriod () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/discount-periods/' + this.$route.params.oltiId,
        method: 'DELETE'
      })
        .then(response => {
          this.$router.push({ path: '/discount-periods' })
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    saveDiscountPeriod () {
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.setLoadingState(true)
          this.formSubmitting = true
          const discountPeriod = {
            itemName: this.discountPeriod.itemName,
            discountPercentage: this.discountPeriod.discountPercentage,
            startDate: moment(this.discountPeriod.dateRangeSelected.start).format('YYYY-MM-DD'),
            endDate: moment(this.discountPeriod.dateRangeSelected.end).format('YYYY-MM-DD')
          }
          let assignedProperties
          if (this.allPropertiesSelected) {
            // mc-all-properties
            assignedProperties = 'mc-all-properties'
          } else {
            // individual properties selected
            assignedProperties = []
            this.properties_selected.forEach(propertyId => {
              assignedProperties.push(propertyId)
            })
          }
          discountPeriod.assignedProperties = assignedProperties
          this.$apiCaller({
            route: '/pricing-service/discount-periods/' + this.$route.params.oltiId,
            method: 'POST',
            data: discountPeriod
          })
            .then(response => {
              const alertMessage = 'This discount period has been saved'
              this.$dialog.alert({ body: alertMessage, title: 'Discount period saved' }, { html: false })
            })
            .finally(() => {
              this.setLoadingState(false)
              this.formSubmitting = false
              if (this.$route.params.oltiId === '0') {
                // this is a new one created - we navigate back to the feed else the oltiId is incorrect
                this.$router.push({ path: '/discount-periods' })
              }
            })
        } else {
          const invalidFields = document.getElementsByClassName('is-invalid')
          this.$scrollTo(invalidFields[0])
        }
      })
    },

    fetchDiscountPeriod () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/discount-periods/' + this.$route.params.oltiId
      })
        .then(response => {
          this.$set(this, 'validationRules', this.$validationRules(response.data.validationRules))
          const propertyOptions = []
          let propertyAddressEtc
          Object.keys(response.data.assignedPropertiesInfo).forEach(propertyId => {
            propertyAddressEtc = response.data.assignedPropertiesInfo[propertyId]
            propertyOptions.push({
              text: propertyAddressEtc,
              value: propertyId
            })
          })
          this.$set(this, 'properties_options', propertyOptions)
          let allPropertiesSelected = false
          const propertiesSelected = []
          if (response.data.hasOwnProperty('assignedTo')) {
            if (response.data.assignedTo.hasOwnProperty('mc-all-properties')) {
              allPropertiesSelected = true
            }
            if (response.data.assignedTo.hasOwnProperty('property')) {
              response.data.assignedTo.property.forEach(propertyId => {
                propertiesSelected.push(propertyId)
              })
            }
          }
          this.$set(this, 'allPropertiesSelected', allPropertiesSelected)
          this.$set(this, 'properties_selected', propertiesSelected)

          this.$set(this.datePickerLimits, 'minDate', moment().toDate())
          this.$set(this.datePickerLimits, 'maxDate', moment().add(36, 'months').toDate())

          if (parseInt(this.$route.params.oltiId) !== 0) {
            this.$set(this.discountPeriod, 'itemName', response.data.item.itemName)
            this.$set(this.discountPeriod.dateRangeSelected, 'start', moment(response.data.item.startDate, 'YYYY-MM-DD').toDate())
            this.$set(this.discountPeriod.dateRangeSelected, 'end', moment(response.data.item.endDate, 'YYYY-MM-DD').toDate())
            this.$set(this.discountPeriod, 'discountPercentage', response.data.item.discountPercentage)
          //   this.discountPeriodDiff = JSON.stringify(this.discountPeriod)
          } else {
            this.$set(this.discountPeriod.dateRangeSelected, 'start', moment().toDate())
            this.$set(this.discountPeriod.dateRangeSelected, 'end', moment().toDate())
          }
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },

  computed: {

    formTitle () {
      let discountPeriodTitle = ''

      if (this.$route.params.oltiId === '0') {
        discountPeriodTitle = 'New Discount Period'
      } else {
        discountPeriodTitle = 'Edit Discount Period'
      }

      if (this.discountPeriod.itemName) {
        discountPeriodTitle = this.discountPeriod.itemName
      }

      return discountPeriodTitle
    },

    calendarIcon () {
      return faCalendarAlt
    }
  },

  data () {
    return {
      loading: true,
      formSubmitting: false,
      discountPeriodDiff: null,
      datePickerLimits: {
        minDate: '',
        maxDate: ''
      },

      discountPeriod: {
        itemName: null,
        dateRangeSelected: {
          start: '',
          end: ''
        },
        discountPercentage: null
      },
      validationRules: {},
      allPropertiesSelected: false,
      properties_selected: [],
      properties_options: []
    }
  }
}
</script>
