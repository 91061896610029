<template>
  <div>
    <b-card :header="seasonTitle" class="add-a-season-form">
      <b-form>
        <b-row>
          <b-col cols="12" md="6">
            <template v-if="!isAvailabilityCalendar()">
            <b-form-group id="season_tariff"
              :label="seasonLabel"
              label-for="season_tariff_select">

                <b-form-select v-model="new_season_form.seasons_id" :options="convertToOptions()">
                <template #first>
                    <option :value="null" disabled>{{ seasonDefaultValue }}</option>
                </template>
              </b-form-select>
            </b-form-group>
            </template>
            <template v-else>
              <b-form-group id="reference" :label="seasonLabel">
                <b-form-input v-model="new_season_form.reference" maxlength="255"></b-form-input>
              </b-form-group>
            </template>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group id="start_end_dates"
                    label="Block range"
                    label-for="startEnd_date">

                    <v-date-picker
                      mode="range"
                      v-model="new_season_form.rangeSelected"
                      :min-date="new_season_form.rangeLimits.minDate"
                      :max-date="new_season_form.rangeLimits.maxDate"
                      show-caps
                      v-if="calendarIsSetup">

                      <b-input-group slot-scope="props">
                        <template #append>
                          <b-input-group-text>
                            <font-awesome-icon :icon="calendarIcon"></font-awesome-icon>
                          </b-input-group-text>
                        </template>

                        <b-form-input
                          :readonly="true"
                          style="background: #ffffff;"
                          type="text"
                          autocomplete="false"
                          :value="props.inputValue"
                          @change.native="props.updateValue($event.target.value)"></b-form-input>
                      </b-input-group>
                    </v-date-picker>

                    <small class="text-muted" v-html="description"></small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <b-button class="m-2 m-md-0" @click="onDelete" variant="danger" v-if="showDeleteButton()">{{ deleteLabel }}</b-button>
          </b-col>
          <b-col cols="12" md="6">
            <b-button class="m-2 m-md-0" @click="onSubmit" variant="primary" :disabled="this.new_season_form.seasons_id === null">Save</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faCalendarAlt from '@fortawesome/fontawesome-free-solid/faCalendarAlt'
import moment from 'moment'

export default {
  components: {
    FontAwesomeIcon
  },

  props: {
    seasonStart: {
      type: Date,
      required: false
    },

    seasonEnd: {
      type: Date,
      required: false
    },

    selectedSeason: {
      type: Object,
      required: false
    },

    availableSeasons: {
      type: Array,
      required: true
    },

    minDate: {
      type: Date,
      required: false
    },

    maxDate: {
      type: Date,
      required: false
    },

    options: {
      type: Object,
      required: false
    }
  },

  mounted () {
    this.calendarIsSetup = false
    if (this.selectedSeason) {
      this.editMode = true
      this.new_season_form.seasons_id = this.selectedSeason.seasons_id
      this.new_season_form.season_set_dates_id = this.selectedSeason.season_set_dates_id

      if (this.selectedSeason.reference) {
        this.new_season_form.reference = this.selectedSeason.reference
      }
    } else {
      this.editMode = false
      this.new_season_form.seasons_id = null
      this.new_season_form.season_set_dates_id = null
    }

    this.new_season_form.rangeSelected.start = this.seasonStart
    this.new_season_form.rangeSelected.end = this.seasonEnd

    // Take a copy of the selected dates, so we know what to delete
    this.new_season_form.previousRangeSelected.start = this.seasonStart
    this.new_season_form.previousRangeSelected.end = this.seasonEnd

    this.new_season_form.rangeLimits.minDate = this.minDate
    this.new_season_form.rangeLimits.maxDate = this.maxDate

    this.$nextTick(() => {
      this.calendarIsSetup = true
    })
  },

  methods: {
    onDelete (event) {
      event.preventDefault()
      this.$emit('delete', {
        selectedDates: this.new_season_form.rangeSelected,
        previousDates: {
          ...this.new_season_form.previousRangeSelected
        },
        season: {
          season_set_dates_id: this.new_season_form.season_set_dates_id,
          seasons_id: this.isAvailabilityCalendar() ? 1 : this.new_season_form.seasons_id
        }
      })
    },

    onSubmit (evt) {
      evt.preventDefault()
      this.$emit('submit', {
        selectedDates: {
          ...this.new_season_form.rangeSelected
        },
        previousDates: {
          ...this.new_season_form.previousRangeSelected
        },
        season: {
          seasons_id: this.new_season_form.seasons_id,
          season_set_dates_id: this.new_season_form.season_set_dates_id
        },
        reference: this.new_season_form.reference
      })
    },
    convertToOptions () {
      const valueOptions = this.availableSeasons.map((season, index) => {
        return {
          value: index + 1,
          text: season.name
        }
      })

      return valueOptions
    },
    isAvailabilityCalendar () {
      return this.options && this.options.type === 'availability'
    },
    showDeleteButton () {
      if (this.isAvailabilityCalendar()) {
        return this.new_season_form.season_set_dates_id !== 0
      }

      return true
    }
  },

  computed: {
    seasonLabel () {
      if (this.isAvailabilityCalendar()) {
        return 'Booking Reference'
      }

      if (this.editMode) {
        return 'Change season'
      } else {
        return 'Choose season'
      }
    },

    seasonTitle () {
      let label = 'season'

      if (this.isAvailabilityCalendar()) {
        label = 'availability'
      }

      return 'Edit ' + label + ' dates'
    },

    seasonDefaultValue () {
      let label = 'season'

      if (this.isAvailabilityCalendar()) {
        label = 'availability'
      }

      return 'Select a ' + label
    },

    calendarIcon () {
      return faCalendarAlt
    },
    description () {
      if (!this.isAvailabilityCalendar()) {
        return ''
      }

      const startDate = moment(this.new_season_form.rangeSelected.start).format('Do MMM YYYY')
      const endDate = moment(this.new_season_form.rangeSelected.end).add(1, 'days').format('Do MMM YYYY')
      const nights = moment(this.new_season_form.rangeSelected.end).diff(moment(this.new_season_form.rangeSelected.start), 'days') + 1

      return `Number of nights: <span class="text-orange">${nights}</span>, Check in on <span class="text-orange">${startDate}</span> and check out on <span class="text-orange">${endDate}</span>`
    },
    deleteLabel () {
      if (this.isAvailabilityCalendar()) {
        return 'Delete Booking'
      }

      return 'Delete'
    }
  },

  data () {
    return {
      calendarIsSetup: false,
      new_season_form: {
        seasons_id: null,
        season_set_dates_id: null,
        rangeSelected: {
          start: null,
          end: null
        },
        previousRangeSelected: {
          start: null,
          end: null
        },
        rangeLimits: {
          minDate: null,
          maxDate: null
        },
        reference: ''
      },

      editMode: false
    }
  }
}
</script>
