<template>
  <div v-if="!loading">
    <div class="title-container">
      <b-row>
        <b-col cols="12">
          <h1>{{ $route.name }}</h1>
        </b-col>
      </b-row>
    </div>

    <div id="filterBar" style="margin-bottom:20px;">
      <b-row>
        <b-col cols="12" lg="4">
          <b-form inline>
            <span class="rate-type" :class="!webPriceInsteadOfNetPricesWDisplayed?'selected':''">Net Price</span> <b-form-checkbox switch style="display:inline;margin:0;padding-right:0px;padding-left:52px;" v-model="webPriceInsteadOfNetPricesWDisplayed"></b-form-checkbox> <span class="rate-type" :class="webPriceInsteadOfNetPricesWDisplayed?'selected':''">Web Display Price</span>
          </b-form>
        </b-col>
        <b-col cols="12" lg="4">
          <b-form inline>
            <b-input-group size="sm" style="width:100%;" class="mt-2 mt-lg-0">
            <label for="bedroomFilterSelect" class="mr-3">Filter:</label>
            <b-form-select
              :options="bedroomFilterOptions"
              v-model="bedroomFilterSelected"
              id="bedroomFilterSelect">
            </b-form-select>
            </b-input-group>
          </b-form>
        </b-col>
        <b-col cols="12" lg="4">
          <b-form inline>
            <b-input-group size="sm" style="width:100%;" class="mt-2 mt-lg-0">
              <label for="textFilter" class="mr-3">Search:</label>
              <b-form-input v-model="searchTextFilter" placeholder="Search" debounce="300" id="textFilter"></b-form-input>
              <template v-slot:append v-if="searchTextFilter.length">
                <b-button @click="resetSearchTextFilter">✕</b-button>
              </template>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <div class="villa-feed">
      <rates-feed-property v-for="(property, key) in propertyRatesFeed" :key="property.villaCode"
        :imgSrc="property.coverImage.imgUrl"
        :to="viewRatesUrl(property)"
        :buttonText="property.rateAssigned === true ? 'View / Edit' : 'Set up Rates'"
        :villaCode="property.villaCode"
        :villaAddress="property.address1"
        :rateAssigned="property.rateAssigned"
        :knockedBack="property.paused || property.unbookable"
        :paused="property.paused"
        :unbookable="property.unbookable"
        :matchesBedroomFilter="bedroomFilterSelected === null || bedroomFilterSelected === property.bedrooms"
        :matchesSearchTextFilter="(property.textStringForFilter.indexOf(searchTextFilter.toUpperCase()) > -1)"
        :rate="property.rateAssigned?property.rate:{}"
        :webPriceInsteadOfNetPricesWDisplayed="webPriceInsteadOfNetPricesWDisplayed"
        :external="!!property.sourceType"
        :sourceType="property.sourceType || 'DV'"
        :sourceTypeId="property.sourceTypeId || 0"
        >
        {{property.textStringForFilter}}
      </rates-feed-property>
    </div>
  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import loader from '@/components/global/loader'
import ratesFeedProperty from '@/components/logged-in/rates/rates-feed-property'
export default {
  components: {
    loader,
    'rates-feed-property': ratesFeedProperty
  },

  name: 'VillaRatesFeed',

  mounted () {
    this.fetchRates()
  },

  methods: {
    fetchRates () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/property-rates/feed'
      })
        .then(response => {
          const feed = []
          const uniqueBedrooms = []
          const uniqueBedroomCounts = {}
          let propertyInfo
          let textStringForFilter
          response.data.feed.forEach(feedItem => {
            propertyInfo = feedItem
            textStringForFilter = []
            textStringForFilter.push(propertyInfo.villaCode)
            if (propertyInfo.paused) {
              textStringForFilter.push('UNDER MAINTENANCE')
            }
            if (propertyInfo.unbookable) {
              textStringForFilter.push('UNBOOKABLE')
            }
            textStringForFilter.push(propertyInfo.address1)
            propertyInfo.rateAssigned = false
            if (response.data.rates.hasOwnProperty(propertyInfo.villaId)) {
              propertyInfo.rateAssigned = true
              propertyInfo.rate = response.data.rates[propertyInfo.villaId]
              textStringForFilter.push(propertyInfo.rate.seasonSetName)
              textStringForFilter.push(propertyInfo.rate.cleaningFeeName)
              textStringForFilter.push(propertyInfo.rate.poolHeatingName)
            } else {
              textStringForFilter.push('Rates have not been set on this property yet.')
            }
            propertyInfo.textStringForFilter = textStringForFilter.join(' ').toUpperCase()
            feed.push(propertyInfo)
            if (!uniqueBedroomCounts.hasOwnProperty(propertyInfo.bedrooms)) {
              uniqueBedroomCounts[propertyInfo.bedrooms] = 0
            }
            uniqueBedroomCounts[propertyInfo.bedrooms]++
            uniqueBedrooms.push(propertyInfo.bedrooms)
          })
          feed.sort((a, b) => {
            return a.villaCode > b.villaCode ? 1 : -1
          })
          this.propertyRatesFeed = feed
          this.bedroomFilterOptions = [
            { value: null, text: '-- All Bedrooms -- (' + response.data.feed.length + ')' }
          ]
          Object.keys(uniqueBedroomCounts).forEach(numberOfBeds => {
            this.bedroomFilterOptions.push(
              {
                value: numberOfBeds,
                text: numberOfBeds + ' Bedrooms (' + uniqueBedroomCounts[numberOfBeds] + ')'
              }
            )
          })
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    resetSearchTextFilter () {
      this.searchTextFilter = ''
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    },

    viewRatesUrl (property) {
      return `/rates/${property.villaId}`
    }
  },

  data () {
    return {
      bedroomFilterOptions: [],
      bedroomFilterSelected: null,
      searchTextFilter: '',
      loading: true,
      webPriceInsteadOfNetPricesWDisplayed: false,
      propertyRatesFeed: []
    }
  }
}
</script>

<style lang="scss">
#filterBar {
  .rate-type{
    color: #ccc;
    &.selected {
      color:inherit;
    }
  }

}
	// @import './src/assets/styles/_variables.scss';
	// @import './node_modules/bootstrap/scss/bootstrap.scss';
</style>
