<template>
	<b-container>

    <!-- <div style="position: fixed; top: 0px; left: 0px; z-index: 10000;" class="btn btn-default">
        <div class="d-block d-sm-none">Extra Small (xs)</div>
        <div class="d-none d-sm-block d-md-none">Small (sm)</div>
        <div class="d-none d-md-block d-lg-none">Medium (md)</div>
        <div class="d-none d-lg-block d-xl-none">Large (lg)</div>
        <div class="d-none d-xl-block">Extra Large (xl)</div>
    </div> -->

		<b-row>
			<b-col>
				<div class="logout">
					<b-link href="" @click="logout">Log out</b-link>
				</div>
			</b-col>
			<b-col lg="8">
				<div class="user-welcome" v-html="loggedInUserInfo">
				</div>
			</b-col>
		</b-row>

    <div class="new-header">
      <div class="owner-login">Owner Login</div>
      <div class="swoosh-bg-image" :style="'background-image:url('+require('@/assets/images/Masthead-swoosh-1440x122.png')+')'">
        <div class="dv-logo-area text-center">
          <b-img :src="require('@/assets/images/dv-logo.jpg')"></b-img>
          <span class="strapline">Specialists in Orlando villa holidays<span class="since-part"> since 1999</span></span>
        </div>
        <div class="contact-area">
         <span class="tel">(+44)1892 836822</span>
         <div class="email">
           <b-link href="mailto:info@debbiesvillas.co.uk">info@debbiesvillas.co.uk</b-link>
         </div>
       </div>
     </div>
    </div>

		<div style="margin-top:15px;">
			<!-- <b-breadcrumb v-bind:items="breadcrumbItems"/> -->

			<b-row>
				<b-col md="4" lg="3">
					<b-navbar toggleable="md">

						<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

						<b-collapse is-nav id="nav_collapse">

							<b-navbar-nav class="flex-column">
								<b-nav-item :to="navigationLink"
											v-for="navigationLink in navigationLinks"
											:key="navigationLink.name">
									{{navigationLink.name}}
									<b-badge variant="danger"
											 v-if="navigationLink.name === 'Messages'">
										{{ $store.getters.getMessages }}
									</b-badge>
								</b-nav-item>
							</b-navbar-nav>

						</b-collapse>
					</b-navbar>

					<aside class="need-help-card">
						<b-card header="Need help?">
							<ul>
								<li>
									<b-link href="mailto:info@debbiesvillas.co.uk">
										<font-awesome-layers class="fa-lg">
											<font-awesome-icon :icon="circleIcon"
															   transform="grow-12"/>
											<font-awesome-icon :icon="envelopeIcon"
															   transform="shrink-3"
															   style="color: #ffffff;"/>
										</font-awesome-layers>
										info@debbiesvillas.co.uk
									</b-link>
								</li>
								<li>
									<b-link href="tel:00441892836822">
										<font-awesome-layers class="fa-lg">
											<font-awesome-icon :icon="circleIcon"
															   transform="grow-12"/>
											<font-awesome-icon :icon="phoneIcon"
															   transform="shrink-3"
															   style="color: #ffffff;"/>
										</font-awesome-layers>
										(+44)1892 836822
									</b-link>
								</li>
                <li>
									<b-link :href="theUserGuidePdfUrl" target="_blank">
										<font-awesome-layers class="fa-lg">
											<font-awesome-icon :icon="circleIcon"
															   transform="grow-12"/>
											<font-awesome-icon :icon="fileIcon"
															   transform="shrink-3"
															   style="color: #ffffff;"/>
										</font-awesome-layers>
										View User Guide
									</b-link>
								</li>
							</ul>
						</b-card>
					</aside>
				</b-col>
				<b-col md="8" lg="9">
					<router-view></router-view>
				</b-col>
			</b-row>
		</div>
	</b-container>
</template>

<script>
import { userGuidePdfUrl } from '@/variables.js'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import faEnvelope from '@fortawesome/fontawesome-free-solid/faEnvelope'
import faPhone from '@fortawesome/fontawesome-free-solid/faPhone'
import faCircle from '@fortawesome/fontawesome-free-solid/faCircle'
import faFile from '@fortawesome/fontawesome-free-solid/faFile'
import { LOG_OUT } from '../store/actions/authnew'

export default {
  components: {
    FontAwesomeIcon,
    FontAwesomeLayers
  },

  computed: {
    loggedInUserInfo () {
      let loggedInUserInfo
      switch (this.$store.getters.userRole) {
        case 'MC_OWNER':
          loggedInUserInfo = this.loggedInUserInfoFromMcOwnerInfo(this.$store.getters.mcOwnerInfo)
          break
        case 'MC_OWNER_DV_ADMIN_MASTER':
          loggedInUserInfo = 'Logged in as DV Master on behalf of<br />' + (this.$store.getters.mcOwnerOnBehalf || 'Not set')
          break
      }
      return loggedInUserInfo
    },

    headerImage () {
      return require('../assets/images/header.jpg')
    },

    envelopeIcon () {
      return faEnvelope
    },

    phoneIcon () {
      return faPhone
    },

    circleIcon () {
      return faCircle
    },

    fileIcon () {
      return faFile
    },
    /*
      breadcrumbItems () {
        // BREADCRUMBS
        let breadCrumbItems = []
        let ai = 0

        // Decide which items should be added to the breadcrumbs
        for (let i = 0; i < this.$route.matched.length; i++) {
          if (this.$route.matched[i].meta.breadcrumb) {
            let breadCrumbName

            // console.log(this.$route.matched[i].meta.breadcrumbTitle)

            if (this.$route.matched[i].meta.breadcrumbTitle) {
              breadCrumbName = this.$route.matched[i].meta.breadcrumbTitle
            }

            if (this.$route.matched[i].meta.breadCrumbAjax) {
              breadCrumbName = this.getRouteNameByID()
            } else {
              breadCrumbName = this.$route.matched[i].meta.description
            }

            breadCrumbItems[ai] = {
              text: breadCrumbName,
              to: {name: this.$route.matched[i].name}
            }

            ai++
          }
        }
        // console.log(breadCrumbItems)
        return breadCrumbItems
      },
      */
    navigationLinks () {
      // NAVIGATION
      const navMenu = []
      const MainRoute = this.$router.options.routes.find(x => x.name === 'Main')
      // console.log(MainRoute)

      // let mainRoutesChildren = this.$router.options.routes[0].children
      // console.log(mainRoutesChildren)

      MainRoute.children.forEach(ChildRoute => {
        let showInNav = true
        if (ChildRoute.hasOwnProperty('meta')) {
          if (ChildRoute.meta.hasOwnProperty('showInNav')) {
            showInNav = ChildRoute.meta.showInNav
          }
          if (ChildRoute.meta.hasOwnProperty('userRoles')) {
            if (!ChildRoute.meta.userRoles.includes(localStorage.getItem('userRole'))) {
              showInNav = false
            }
          }
        }
        if (showInNav) {
          navMenu.push(ChildRoute)
        }
      })
      //
      // for (let i = 0; i < this.$router.options.routes[0].children.length; i++) { // get 'Main' route
      //   // console.log(this.$router.options.routes[1].children[i])
      //   navMenu.push(this.$router.options.routes[0].children[i])
      // }

      return navMenu
    }
  },

  methods: {
    loggedInUserInfoFromMcOwnerInfo (mcOwnerInfo) {
      const loggedInInfo = [
        // mcOwnerInfo.mcLetterCode + ' = '
      ]
      const keys = [
        'clientTitle',
        'clientForename',
        'clientSurname',
        'clientCompName',
        'clientEmail'
      ]
      let userInfoToPush
      keys.forEach((key) => {
        userInfoToPush = ''
        if (mcOwnerInfo[key]) {
          if (key === 'clientEmail') {
            userInfoToPush += '<small>('
          }
          userInfoToPush += mcOwnerInfo[key]
          if (key === 'clientEmail') {
            userInfoToPush += ')</small>'
          }
        }
        if (userInfoToPush) {
          loggedInInfo.push(userInfoToPush)
        }
      })
      return loggedInInfo.join(' ')
    },

    getRouteNameByID (routeInfo) {
      return 'AJAX, BASED ON PARAM [ID] (' + this.$route.params.recordId + ')'
    },

    logout (event) {
      // event.preventDefault()
      this.$store.dispatch(LOG_OUT, { timedOut: false })
    }
  },

  data () {
    return {
      theUserGuidePdfUrl: userGuidePdfUrl
    }
  }
}
</script>

<style lang="scss">
	@import "../assets/styles/_variables";
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	// @import "../assets/styles/global";

  .new-header {
    position: relative;
    height: 142px;
    color: #423191;
    font-weight: bold;
    .swoosh-bg-image {
      height:142px;
      position: absolute;
      left:0;
      right:0;
      background-position: -500px 0;
      background-repeat: no-repeat;
      background-size: 1900px 142px;
      z-index: 2;
      transition:background-position 0.3s ease;

      @include media-breakpoint-down(lg) {
        background-position: -440px 0;
        background-size: 1600px 142px;
      }

      @include media-breakpoint-down(md) {
        background-position: -340px 0;
        background-size: 1350px 142px;
      }

      @include media-breakpoint-down(sm) {
        background-position: -250px 0;
        background-size: 950px 142px;
      }
      @include media-breakpoint-down(xs) {
        background-position: -250px 0;
        background-size: 850px 100px;
        height: 100px;
      }
    }
    @include media-breakpoint-down(xs) {
      height: 100px;
    }
    a {
      color: #423191;
      &:hover{
        color: #423191;
      }
    }
    .dv-logo-area {
      position: absolute;
      top:20px;
      width:440px;
      transition: width 0.3s ease;
      img{
        width:265px;
      }
      .strapline{
        font-size: 12px;
        line-height: 28px;
        display: block;
      }
      @include media-breakpoint-down(lg) {
        width:350px;
      }
      @include media-breakpoint-down(md) {
        width:350px;
        img{
          width:240px;
        }
        .strapline{
          font-size:12px;
          line-height: 26px;
          .since-part {
            display:none;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        margin-top:10px;
        width:250px;
        img{
          width:200px;
        }
        .strapline{
          font-size:10px;
          line-height: 18px;
        }
      }
      @include media-breakpoint-down(xs) {
        top:5px;
        width:150px;
        img{
          width:150px;
        }
        .strapline{
          font-size:7px;
          line-height: 18px;
        }
      }
    }
    .contact-area{
      position: absolute;
      right:10px;
      width:260px;
      text-align:right;
      top: 30px;
      .tel {
        font-size:20px;
      }
      @include media-breakpoint-down(md) {
        text-align:center;
        color:white;
        background: rgba(46,176,221,.9);
        border-radius: 3px;
        font-size:14px;
        width:200px;
        .tel{
          font-size: 18px;
        }
        a{
          color:white;
        }
      }
      @include media-breakpoint-down(xs) {
        font-size:10px;
        width:160px;
        .tel{
          font-size: 12px;
        }
        a{
          color:white;
        }
      }
    }

    .owner-login{
      position: absolute;
      background-color:#cceeff;
      line-height: 40px;
      text-align:right;
      font-size:24px;
      padding-right:10px;
      bottom:0px;
      left:0;
      right:0;
      z-index:1;
      @include media-breakpoint-down(xs) {
        font-size:14px;
        line-height:24px;
      }
    }
  }

  .header {
    // display: none;
  }

	.user-welcome, .logout {
		font-size: 0.9em;
		color: $gray-600;
    margin-bottom: 5px;
	}

  .user-welcome {
    text-align: right;
  }

	.modal-dialog {
		text-align: center;

		.seconds-remaining {
			color: #ffffff;
			font-weight: bold;
			background: $danger;
			border-radius: 50%;
			margin: 10px;
			width: 100px;
			height: 100px;
			position: relative;
			font-size: 1.7em;

			.inner {
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-moz-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				-o-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}
		}
	}
</style>
