<template>
  <div v-if="!loading" >
    <div>
      <div class="title-container">
        <b-row>
          <b-col cols="6" lg="8">
            <h1>{{ $route.name }}</h1>
          </b-col>
          <b-col cols="6" lg="4">
            <div class="pl-2 pr-2">
              <b-button style="margin: 10px 0;" block variant="secondary" @click="openNewSeasonSetModal">
                New Season Set
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-list-group v-if="seasonSets.length">
        <b-list-group-item v-for="seasonSet in seasonSets" :key="seasonSet.owner_link_to_item_id"
                  :to="'/season-sets/' + seasonSet.owner_link_to_item_id">
          {{ seasonSet.itemName }}
        </b-list-group-item>
      </b-list-group>
      <template v-else>You do not have any season sets.</template>

      <b-modal id="seasonSetModal" size="lg"
        modal-class="availability-modal"
        lazy centered hide-footer title="Create new season set">
           <b-form @submit.prevent="createSeasonSet" autocomplete="off" novalidate>
             <fieldset :disabled="formSubmitting">
           <b-row>
             <b-col>
                   <label for="itemName">Season Set Name</label>
                   <b-input-group>
                    <b-form-input
                      v-model="newSeasonSetName"
                      id="itemName"
                      type="text"
                      data-vv-as="Season Set Name"
                      name="itemName"
                      :state="$validateState(veeFields, errors, 'itemName')"
                      :maxlength="validationRules.itemName.max"
                      v-validate="validationRules.itemName">
                    </b-form-input>
                       <b-form-invalid-feedback id="itemName_feedback">
                         <ul style="padding-left: 0; list-style: none;">
                           <li v-for="error in errors.collect('itemName')">{{ error }}</li>
                         </ul>
                       </b-form-invalid-feedback>
                   </b-input-group>
             </b-col>
           </b-row>
           <b-row class="mt-4">
             <b-col md="6">
               <b-button class="m-2 m-md-0" variant="info" @click="closeNewModal">Cancel</b-button>
             </b-col>
             <b-col md="6">
               <b-button class="m-2 m-md-0" variant="primary" type="submit">Create New</b-button>
             </b-col>
           </b-row>
         </fieldset>
       </b-form>
     </b-modal>
    </div>
  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import loader from '@/components/global/loader'
export default {
  components: {
    loader
  },

  mounted () {
    this.fetchSeasonSets()
  },

  methods: {
    createSeasonSet () {
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.$bvModal.hide('seasonSetModal')
          this.setLoadingState(true)
          this.formSubmitting = true
          const seasonSets = {
            itemName: this.newSeasonSetName
          }
          this.$apiCaller({
            route: '/pricing-service/season-sets/0', // 0 hard coded as from this feed page we're creating a new one.
            method: 'POST',
            data: seasonSets
          })
            .then(response => {
              const alertMessage = 'The season set ' + this.newSeasonSetName + ' has just been created you can now add dates to it.'
              this.$dialog.alert({ body: alertMessage, title: 'Season set created' }, { html: false }).then(() => {
                this.fetchSeasonSets()
              })
            })
            .finally(() => {
              this.setLoadingState(false)
              this.formSubmitting = false
              this.newSeasonSetName = null
            })
        }
      })
    },

    openNewSeasonSetModal (event) {
      event.preventDefault()
      this.$bvModal.show('seasonSetModal')
    },

    closeNewModal (event) {
      event.preventDefault()
      this.$bvModal.hide('seasonSetModal')
      this.newSeasonSetName = null
    },

    fetchSeasonSets () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/season-sets'
      })
        .then(response => {
          this.seasonSets = response.data.feedItems
          this.validationRules = this.$validationRules(response.data.validationRules)
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },

  data () {
    return {
      loading: true,
      formSubmitting: false,
      newSeasonSetName: null,
      validationRules: {},
      seasonSets: []
    }
  }
}
</script>
