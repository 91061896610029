<template>
  <div>
  <div class="view-edit-rates" ref="ratesContainer" v-if="!loading">
    <div class="title-container blue" v-if="propertyInfo.hasOwnProperty('coverImage')">
      <b-row>
        <b-col cols="12" lg="8" order="2" order-lg="1">
          <h1 class="alt">Edit property rates ({{ propertyInfo.villaCode }})<br/>
            <small>{{ propertyInfo.address1 }}</small>
          </h1>
        </b-col>
        <b-col cols="12" lg="4" order="1" order-lg="2">
          <div class="circle-image-container">
            <b-img :src="propertyInfo.coverImage.imgUrl+(propertyInfo.coverImage.imgUrl.includes('googleusercontent')?'-c':'')" rounded="circle" fluid/>
          </div>
        </b-col>
      </b-row>
    </div>

		<div class="season-container">
      <b-form @submit.prevent="checkPropertyRatesForm" autocomplete="off" novalidate>
          <fieldset :disabled="formSubmitting">
      <div class="season-currency" v-if="displayFormsToEdit.currency">
        <b-row>
          <b-col lg="6">
            <template v-if="propertyRates.propertySetup">
              You are pricing this property in <strong>{{ propertyRates.savedCurrency.currencyCode ? propertyRates.savedCurrency.currencyCode : propertyRates.loadedCurrency.currencyCode }}</strong>.
            </template>

            <template v-if="(!propertyRates.propertySetup && !currencyModal) /*&& propertyRates.rateYears.hasOwnProperty(new Date().getFullYear())*/">
              <h3>Almost there...</h3>
              <p>Before your property can go live on our website, you need to tell us how much you would like to charge your guests.</p>
              <p>To do this, first choose a currency that you would like to set your prices in:</p>
            </template>

            <b-button variant="info" size="sm" @click="openCurrencyWarningModal" v-if="propertyRates.propertySetup || (!propertyRates.propertySetup && !currencyModal)">
              <template v-if="!propertyRates.propertySetup && !currencyModal">Set property currency</template>
              <template v-if="propertyRates.propertySetup">Change currency</template>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <template v-if="propertyRates.propertySetup">
        <div class="pricing vld-parent" ref="seasonContainer" v-if="displayFormsToEdit.propertyRateYears">
          <div class="explainer">
            <strong>These are the amounts you wish to receive per night, in each season including all taxes.<br/>
              If you only set rates for {{ new Date().getFullYear() }}, we will use those values for {{ new Date().getFullYear() + 1 }} and {{ new Date().getFullYear() + 2 }}.
            </strong>
          </div>
          <div class="year-container" v-for="(rateYear, yearKey) in propertyRates.rateYears" :key="yearKey">
            <h2 :class="{'bg-danger invalid-row': !rowValidStatus[yearKey]}">
              {{ yearKey }}
              <template v-if="yearKey > new Date().getFullYear()">
                <small>
                  <template v-if="rowValidStatus[yearKey]">
                    (optional)
                  </template>

                  <template v-else>
                    Please complete all seasons in this year
                  </template>
                </small>
              </template>
            </h2>
            <b-row>
              <b-col md="6" lg="3" v-for="(season) in ['Low', 'Mid', 'High', 'Peak']" :key="yearKey + '_' + season">
                <div class="season-price">
                  <label :for="yearKey + '_' + season">{{ season }}</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text>
                        <span v-html="currencySymbol(propertyRates.savedCurrency.currencyCode ? propertyRates.savedCurrency.currencyCode : propertyRates.selectedCurrency.currencyCode)"/></span>
                      </b-input-group-text>
                    </template>

                    <b-form-input
                      :data-vv-as="season + ' ' + yearKey"
                      :name="'season_' + yearKey + '_' + season"
                      :state="$validateState(veeFields, errors, 'season_' + yearKey + '_' + season/*, !rowValidStatus[yearKey]*/)"
                      v-validate="propertyRates.validationRules['season_' + yearKey + '_' + season]"
                      v-model.number="propertyRates.rateYears[yearKey][season]"
                      :id="yearKey + '_' + season"
                      type="number"
                      :min="propertyRates.validationRules['season_' + yearKey + '_' + season].min_value"
                      :max="propertyRates.validationRules['season_' + yearKey + '_' + season].max_value"
                      step="0.01"
                      @input="seasonValueUpdated"
                      :class="{'is-invalid': !rowValidStatus[yearKey] && !propertyRates.rateYears[yearKey][season]}"></b-form-input>

                    <b-form-invalid-feedback :id="yearKey + '_' + season + '_feedback'">
                        <ul style="padding-left: 0; list-style: none;">
                          <li v-for="error in errors.collect('season_' + yearKey + '_' + season)">{{ error }}</li>
                        </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                  <small>
                    Web display price: <span v-html="currencySymbol('GBP')"></span>{{calculateWebDisplayPrice(propertyRates.rateYears[yearKey][season]) | currency({symbolSpacing: false, fractionCount: 0})}}
                  </small>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-for="(discountType) in ['Late', 'Long']" :key="discountType" v-if="displayFormsToEdit.discounts">
          <div class="pricing pt-0 vld-parent">
            <div class="year-container mb-0">
              <h2 v-if="discountType == 'Late'">Late Deal Discount <small>(booking is made at this number of nights or less before arrival)</small></h2>
              <h2 v-if="discountType == 'Long'">Long Stay Discount <small>(booking lasts equal to or longer than a number of nights)</small></h2>
              <b-row>
                <b-col lg="4">
                  <div class="season-price">
                    <b-form-group :label="discountType + ' Discount'">
                      <b-form-select v-model="propertyRates['discount' + discountType].available" @change="zeroOutDiscountPercentages($event, discountType)">
                        <option :value="true">Enabled</option>
                        <option :value="false">Disabled</option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </b-col>

                <b-col lg="4">
                  <div class="season-price">
                    <label :for="discountType.toLowerCase() + 'Days'">{{discountType}} Days</label>
                    <b-input-group>
                      <b-form-input
                      :data-vv-as="discountType + ' Days'"
                      :name="discountType.toLowerCase() + '_days'"
                      :state="$validateState(veeFields, errors, (discountType.toLowerCase() + '_days'), !propertyRates['discount' + discountType].available)"
                      v-validate="propertyRates.validationRules[discountType.toLowerCase() + 'Days']"
                      :disabled="!propertyRates['discount' + discountType].available"
                      v-model.number="propertyRates['discount' + discountType].days"
                      :id="discountType.toLowerCase() + 'Days'"
                      type="number"
                      :min="propertyRates.validationRules[discountType.toLowerCase() + 'Days'].min_value"
                      :max="propertyRates.validationRules[discountType.toLowerCase() + 'Days'].max_value"
                      ></b-form-input>
                      <b-form-invalid-feedback :id="discountType.toLowerCase() + 'Days_feedback'">
                          <ul style="padding-left: 0; list-style: none;">
                            <li v-for="error in errors.collect(discountType.toLowerCase() + '_days')">{{ error }}</li>
                          </ul>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </b-col>

                <b-col lg="4">
                  <div class="season-price">
                    <label :for="discountType.toLowerCase() + 'percentage'">{{discountType}} Discount Percentage</label>
                    <b-input-group append="%">
                      <b-form-input
                      :data-vv-as="discountType + ' Days Discount'"
                      :name="discountType.toLowerCase() + '_days_discount'"
                      :state="$validateState(veeFields, errors, (discountType.toLowerCase() + '_days_discount'), !propertyRates['discount' + discountType].available)"
                      v-validate="propertyRates.validationRules[discountType.toLowerCase() + 'DiscountPercentage']"
                      :disabled="!propertyRates['discount' + discountType].available"
                      v-model.number="propertyRates['discount' + discountType].percentage"
                      :id="discountType.toLowerCase() + 'percentage'"
                      type="number"
                      step="0.1"
                      :min="propertyRates.validationRules[discountType.toLowerCase() + 'DiscountPercentage'].min_value"
                      :max="propertyRates.validationRules[discountType.toLowerCase() + 'DiscountPercentage'].max_value"
                      ></b-form-input>

                      <b-form-invalid-feedback :id="discountType.toLowerCase() + 'Discount_feedback'" style="order: 99;">
                        <ul style="padding-left: 0; list-style: none;">
                          <li v-for="error in errors.collect(discountType.toLowerCase() + '_days_discount')">{{ error }}</li>
                        </ul>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="pricing pt-0 vld-parent" ref="peakContainer" v-if="displayFormsToEdit.discounts">
          <div class="year-container mb-0">
            <h2>Peak Nights Discount</h2>
            <b-row>
              <b-col lg="4">
                <div class="season-price">
                  <b-form-group label="Discount Peak Nights">
                    <b-form-select v-model="propertyRates.discountPeak">
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div class="pricing pt-0 vld-parent" ref="cleaningContainer" v-if="displayFormsToEdit.poolHeating || displayFormsToEdit.cleaningFee">
          <div class="year-container mb-0">
            <h2 v-text="poolHeatingAndCleaningFeeSectionName()"></h2>
            <b-row>
              <b-col lg="6" v-if="displayFormsToEdit.poolHeating">
                <template v-if="propertyInfo.hasOwnProperty('hasPool') && propertyInfo.hasPool === '1'">
                  <div class="season-price">
                    <b-form-group label="Pool Heating">
                      <b-form-select v-model="propertyRates.selectedPoolHeating" :options="propertyRates.availablePoolHeatingOptions"></b-form-select>
                    </b-form-group>
                  </div>

                  <div class="rate-info-container">
                    <template v-if="propertyRates.selectedPoolHeating === null">
                      <h3 style="font-size: 1.2em;">DV Default pool heating rate</h3>
                      <p>This is the default pool heating rate we assign to all new properties. You can choose to continue using it, or <b-link to="/pool-heating">create your own</b-link>.</p>
                    </template>

                    <template v-else>
                      <h3 style="font-size: 1.2em;">{{ poolHeatingDetails.itemName }}</h3>
                      <p>This is a custom pool heating rate you have created.</p>
                    </template>
                    <ul>
                      <li>
                        <strong>Minimum nights charged:</strong> {{ poolHeatingDetails.minNights }}
                      </li>
                      <li>
                        <strong>Setup Fee:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ poolHeatingDetails.setupRate | currency }}
                      </li>
                      <li>
                        <strong>Nightly Rate:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ poolHeatingDetails.nightlyRate | currency }}
                      </li>
                    </ul>
                    <template v-if="poolHeatingDetails.longDiscountAvailable">
                      <h4 style="font-size: 1em;">Long Stay Discount:</h4>
                      <p>A <strong>{{ `${(poolHeatingDetails.longDiscount) * 100}%` }} discount</strong> will be applied to this pool heating rate, if a booking on this property is greater than or equal to <strong>{{ `${poolHeatingDetails.longDays} nights` }}</strong>.</p>
                    </template>
                    <h4 style="font-size: 1em;">Example pricing:</h4>
                    <div style="border: 1px solid #d3d3d3; padding: 5px; margin-bottom: 10px;">
                      <p>On a booking that lasts 14 nights, the amount you will receive for pool heating would be:</p>
                      <ul>
                        <li>
                          <strong>Pool Heating Fees:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ examplePoolHeatingRate(propertyRates.selectedPoolHeating, 14) }}
                        </li>
                        <li v-if="poolHeatingDetails.longDiscountAvailable && poolHeatingDetails.longDays <= 14">
                          <strong>Long Stay Discount:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ 0 - (examplePoolHeatingRate(propertyRates.selectedPoolHeating, 14) *  poolHeatingDetails.longDiscount) | currency }}
                        </li>
                        <li v-if="poolHeatingDetails.longDiscountAvailable && poolHeatingDetails.longDays <= 14">
                          <strong>Total Received:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ examplePoolHeatingRate(propertyRates.selectedPoolHeating, 14) - (examplePoolHeatingRate(propertyRates.selectedPoolHeating, 14) *  poolHeatingDetails.longDiscount) | currency }}
                        </li>
                      </ul>
                    </div>
                    <div style="border: 1px solid #d3d3d3; padding: 5px;">
                      <p>On a booking that lasts 3 nights, the amount you will receive for pool heating would be:</p>
                      <ul>
                        <li>
                          <strong>Pool Heating Fees:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ examplePoolHeatingRate(propertyRates.selectedPoolHeating, 3) }}
                        </li>
                        <li v-if="poolHeatingDetails.longDiscountAvailable && poolHeatingDetails.longDays <= 3">
                          <strong>Long Stay Discount:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ 0 - (examplePoolHeatingRate(propertyRates.selectedPoolHeating, 3) *  poolHeatingDetails.longDiscount) | currency }}
                        </li>
                        <li v-if="poolHeatingDetails.longDiscountAvailable && poolHeatingDetails.longDays <= 3">
                          <strong>Total Received:</strong> <span v-html="currencySymbol(poolHeatingDetails.currencyCode)"></span>{{ examplePoolHeatingRate(propertyRates.selectedPoolHeating, 3) - (examplePoolHeatingRate(propertyRates.selectedPoolHeating, 3) *  poolHeatingDetails.longDiscount) | currency }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <h3 style="font-size: 1em; font-weight: normal; padding-top: 10px; margin-bottom: 9px;">Pool Heating</h3>
                  <div style="border: 1px solid #d3d3d3; padding: 5px;">
                    <p>It is not possible to assign a pool heating tariff to this property, as our records indicate that this property does not have a pool.</p>
                    <p>If this information is incorrect, please contact us.</p>
                  </div>
                </template>
              </b-col>

              <b-col lg="6" v-if="displayFormsToEdit.cleaningFee">
                <div class="season-price mt-4 mt-lg-0">
                  <b-form-group label="Cleaning Fee">
                    <b-form-select v-model="propertyRates.selectedCleaningFee" :options="propertyRates.availableCleaningFeesOptions"></b-form-select>
                  </b-form-group>
                </div>

                <div class="rate-info-container">
                  <template v-if="propertyRates.selectedCleaningFee === null">
                    <h3 style="font-size: 1.2em;">DV Default cleaning fee</h3>
                    <p>
                      This is the default cleaning fee we assign to all new properties. You can choose to continue using it, or <b-link to="/cleaning-fees">create your own</b-link>.
                    </p>

                    <p>This fee will apply if a booking on this property is <strong>less than 7 nights</strong>. The cost is equal to the average nightly rate for a given booking.</p>

                    <h4 style="font-size: 1em;">Example pricing:</h4>
                    <div style="border: 1px solid #d3d3d3; padding: 5px; margin-bottom: 10px;">
                      <p>
                        The cost will be calculated after converting this property's rates into Pound Sterling (GBP).
                      </p>
                      <p>
                        For example, a booking that lasts 4 nights:
                      </p>

                      <ul>
                        <li>
                          <strong>Night 1:</strong> <span v-html="currencySymbol('GBP')"/></span>{{ 100 | currency }}
                        </li>
                        <li>
                          <strong>Night 2:</strong> <span v-html="currencySymbol('GBP')"/></span>{{ 200 | currency }}
                        </li>
                        <li>
                          <strong>Night 3:</strong> <span v-html="currencySymbol('GBP')"/></span>{{ 60 | currency }}
                        </li>
                        <li>
                          <strong>Night 4:</strong> <span v-html="currencySymbol('GBP')"/></span>{{ 40 | currency }}
                        </li>
                      </ul>

                      <strong>Average Nightly Rate:</strong> <span v-html="currencySymbol('GBP')"/></span>{{ (100 + 200 + 60 + 40) / 4 | currency }}
                    </div>
                  </template>

                  <template v-else>
                    <h3 style="font-size: 1.2em;">{{ cleaningFeeDetails.itemName }}</h3>
                    <p>This is a custom cleaning fee you have created.</p>

                    <ul>
                      <li>
                        <strong>Fee amount:</strong> <span v-html="currencySymbol(cleaningFeeDetails.currencyCode)"/></span>{{ cleaningFeeDetails.amount | currency }}
                      </li>
                      <li>
                        <strong>Fee disabled:</strong> If booking is <strong>greater than</strong> {{ cleaningFeeDetails.disabledDays }} nights
                      </li>
                    </ul>
                  </template>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div class="pricing pt-0 vld-parent" ref="seasonSetContainer"  v-if="displayFormsToEdit.seasonSets">
          <div class="year-container mb-0">
            <h2>Season Sets</h2>
            <b-row>
              <b-col sm="6">
                <div class="season-price">
                  <b-form-group label="Season Set">
                    <b-form-select v-model="propertyRates.selectedSeasonSet" :options="propertyRates.availableSeasonSetsOptions"></b-form-select>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <!-- start dvmarkup -->
        <div v-if="this.displayFormsToEdit.DVMarkup">
          <div class="pricing pt-0 vld-parent">
            <div class="year-container mb-0">
              <h2>DV Markup</h2>
              <b-row>
                <b-col lg="6">
                  <div class="season-price">
                    <label for="DVMarkupPercentage" style="text-transform:capitalize;">DV Markup Percentage</label>
                    <b-input-group append="%">
                      <b-form-input
                      data-vv-as="DV Markup Percentage"
                      name="DVMarkupPercentage"
                      :state="$validateState(veeFields, errors, 'DVMarkupPercentage')"
                      v-validate="propertyRates.validationRules.DVMarkupPercentage"
                      v-model.number="propertyRates.DVMarkupPercentage"
                      id="DVMarkupPercentage"
                      type="number"
                      step="0.1"
                      :min="propertyRates.validationRules.DVMarkupPercentage.min_value"
                      :max="propertyRates.validationRules.DVMarkupPercentage.max_value"
                      ></b-form-input>

                      <b-form-invalid-feedback style="order: 99;">
                        <ul style="padding-left: 0; list-style: none;">
                          <li v-for="error in errors.collect('DVMarkupPercentage')">{{ error }}</li>
                        </ul>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div> <!-- end DVMarkupPercentage -->

        <!-- start minBookableNights and minBookableNightsPeakInclusive -->
        <div v-if="this.displayFormsToEdit.bookableNightsMin">
          <div class="pricing pt-0 vld-parent">
            <div class="year-container mb-0">
              <h2>Minimum Bookable Nights</h2>
              <b-row>
                <b-col lg="6" v-for="minBookableType in [{type:'bookableNightsMin', name:'Minimum Bookable Nights'}, {type:'bookableNightsMinPeakInclusive', name:'Minimum Bookable Nights Peak Inclusive'}]" :key="minBookableType.type">
                  <div class="season-price">
                    <label :for="minBookableType.type">{{minBookableType.name}}</label>
                    <b-input-group>

                      <b-form-input
                      :data-vv-as="minBookableType.name"
                      :name="minBookableType.type"
                      :state="$validateState(veeFields, errors, minBookableType.type)"
                      v-validate="propertyRates.validationRules[minBookableType.type]"
                      v-model.number="propertyRates[minBookableType.type]"
                      :id="minBookableType.type"
                      type="number"
                      step="1"
                      :min="propertyRates.validationRules[minBookableType.type].min_value"
                      :max="propertyRates.validationRules[minBookableType.type].max_value"
                      ></b-form-input>

                      <b-form-invalid-feedback style="order: 99;">
                        <ul style="padding-left: 0; list-style: none;">
                          <li v-for="error in errors.collect(minBookableType.type)">{{ error }}</li>
                        </ul>
                      </b-form-invalid-feedback>

                    </b-input-group>
                    <small v-if="minBookableType.type === 'bookableNightsMinPeakInclusive'">
                      This is the minimum number of nights that can be booked if any 1 night is a peak date
                    </small>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div> <!-- end minBookableNights and minBookableNightsPeakInclusive -->

        <div class="pricing vld-parent" ref="saveContainer">
          <div class="saver">
            <b-row>
              <b-col md="12">
                <i>Prices of existing bookings will not be affected.</i>
              </b-col>
            </b-row>
            <b-row align-h="end" class="mt-4">
              <b-col sm="6" lg="3">
                <b-button class="mt-4 mt-sm-0" variant="primary" type="submit">Save</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </fieldset>
    </b-form>
		</div>
  </div>
    <loader width="100px" height="100px" v-else></loader>

    <b-modal
    v-model="currencyModal"
    :ok-disabled="!(propertyRates.selectedCurrency.currencyCode)"
    :ok-only="!propertyRates.propertySetup"
    :cancel-disabled="!propertyRates.propertySetup"
    :no-close-on-backdrop="!propertyRates.propertySetup"
    :no-close-on-esc="!propertyRates.propertySetup"
    size="lg"
    ref="currencyWarningModal"
    centered
    title="Select Property Currency"
    @hide="modalHide">
      <template v-if="!propertyRates.propertySetup">
        <h3>Welcome!</h3>
        <p>
          To get started pricing your property, first tell us which currency you wish to work with.
        </p>
      </template>

      <b-form-group label="Select a currency:">
        <b-form-select v-model="propertyRates.selectedCurrency.id" :options="propertyRates.availableCurrencies" />
      </b-form-group>

      <b-alert show variant="danger" class="mt-4" v-if="propertyRates.propertySetup">
        <p class="mb-0">
          If you change the currency on this property, you will need to enter new prices for each season.
        </p>
      </b-alert>

      <p v-if="propertyRates.propertySetup">
        Click <strong>OK</strong> to save your selection, or click <strong>Cancel</strong> to discard your changes.
      </p>
    </b-modal>
	</div>
</template>

<script>
import loader from '@/components/global/loader'
export default {
  components: {
    loader
  },
  name: 'property-rates',

  beforeRouteLeave (to, from, next) {
    if (localStorage.length > 0) {
      let formHasUnsavedChanges = false
      const updatedValues = this.createPropertyRatesObjectStringForComparison()
      if (updatedValues !== this.retrievedRates) {
        formHasUnsavedChanges = true
      }
      if (formHasUnsavedChanges) {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    } else {
      // localstorage has been wiped so should be redirecting to the login page as a forced logout - so just allow it
      next()
    }
  },

  watch: {
    /* GE - The following updates the currency code when the select value is changed on the currency select form */
    'propertyRates.selectedCurrency.id': {
      handler: function (newVal) {
        if (newVal !== '') {
          for (let i = 0; i < this.propertyRates.availableCurrencies.length; i++) {
            if (this.propertyRates.availableCurrencies[i].value === newVal) {
              this.propertyRates.selectedCurrency.currencyCode = this.propertyRates.availableCurrencies[i].text
              break
            }
          }
        }
      }
    }
  },

  mounted () {
    this.fetchPropertyRates()
  },

  computed: {
    poolHeatingDetails () {
      if (this.propertyRates.selectedPoolHeating === null) {
        return this.propertyRates.defaultPoolHeating
      } else {
        const customPoolHeat = this.rateInfo.poolHeating_available.filter(obj => {
          return obj.owner_link_to_item_id === this.propertyRates.selectedPoolHeating
        })
        return customPoolHeat[0]
      }
    },

    cleaningFeeDetails () {
      if (this.propertyRates.selectedCleaningFee === null) {
        return false // doesnt really return anything anywhere as this is only in the dom if the selected cleaning fee is not null
      } else {
        const customCleaningFee = this.rateInfo.cleaningFees_available.filter(obj => {
          return obj.owner_link_to_item_id === this.propertyRates.selectedCleaningFee
        })
        return customCleaningFee[0]
      }
    }
  },

  methods: {

    poolHeatingAndCleaningFeeSectionName () {
      const sectionName = []
      if (this.displayFormsToEdit.poolHeating) {
        sectionName.push('Pool Heating')
      }
      if (this.displayFormsToEdit.cleaningFee) {
        sectionName.push('Cleaning Fee')
      }
      return sectionName.join(' & ') + ' Tariffs'
    },

    calculateWebDisplayPrice (srcAmount) {
      if (typeof srcAmount === 'number') {
        const amountIncludingCommission = (srcAmount / (1 - (this.propertyRates.DVMarkupPercentage / 100)))
        const currencyPair = this.propertyRates.selectedCurrency.currencyCode + 'GBP'
        const amountIncludingCommissionInGBP = (amountIncludingCommission * this.exchangeRateInfo[currencyPair])
        return Math.round(amountIncludingCommissionInGBP)
      } else {
        return ''
      }
    },

    storeRetrievedToDetectChanges () {
      const retrievedRatesObjectToStore = this.createPropertyRatesObjectStringForComparison()
      this.retrievedRates = retrievedRatesObjectToStore
    },

    createPropertyRatesObjectStringForComparison () {
      const propertyRatesObjectString = {}
      propertyRatesObjectString.selectedCurrencyId = this.propertyRates.selectedCurrency.id
      propertyRatesObjectString.selectedPoolHeating = this.propertyRates.selectedPoolHeating
      propertyRatesObjectString.selectedCleaningFee = this.propertyRates.selectedCleaningFee
      propertyRatesObjectString.selectedSeasonSet = this.propertyRates.selectedSeasonSet
      if (this.propertyRates.hasOwnProperty('DVMarkupPercentage')) {
        propertyRatesObjectString.DVMarkupPercentage = this.propertyRates.DVMarkupPercentage
      }
      propertyRatesObjectString.selectedSeasonSet = this.propertyRates.selectedSeasonSet
      propertyRatesObjectString.rateYears = this.propertyRates.rateYears
      propertyRatesObjectString.discounts = [this.propertyRates.discountLate, this.propertyRates.discountLong, this.propertyRates.discountPeak]
      return JSON.stringify(propertyRatesObjectString)
    },

    seasonValueUpdated () {
      // set year rows valid, removed the red status bar from year rows - this is updated when any rateyear value is entered and also when currency changed or first set
      this.resetInvalidYearRows()
    },

    resetInvalidYearRows () {
      let year
      for (const i in this.years) {
        year = this.years[i]
        this.$set(this.rowValidStatus, year, true)
      }
    },

    examplePoolHeatingRate (type, nights) {
      const rate = (this.poolHeatingDetails.setupRate + ((this.poolHeatingDetails.minNights > nights ? this.poolHeatingDetails.minNights : nights) * this.poolHeatingDetails.nightlyRate))
      return Number.parseFloat(rate).toFixed(2)
    },

    zeroOutDiscountPercentages (enabled, discountPrefix) {
      if (!enabled) {
        this.propertyRates['discount' + discountPrefix].days = ''
        this.propertyRates['discount' + discountPrefix].percentage = ''
      }
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    },

    refreshPropertyRatesUI () {
      this.years = [
        new Date().getFullYear(),
        new Date().getFullYear() + 1,
        new Date().getFullYear() + 2
      ]

      const seasons = [
        'Low',
        'Mid',
        'High',
        'Peak'
      ]

      // setup empty rates and rateYears for property if not returned from API - assume new property
      const rateYears = {} //
      let rateYear, yearSeasonKey, hasRateYearValues, rateYearSeasonKey
      const currentYear = parseInt(new Date().getFullYear())
      this.years.forEach(year => {
        rateYear = {}
        hasRateYearValues = (this.rateInfo.propertyRate.hasOwnProperty('years') && this.rateInfo.propertyRate.years.hasOwnProperty(year))
        let validationString = this.rateInfo.validationRules.rateYears
        if (year !== currentYear) {
          validationString = validationString.replace('required|', '')
        }
        seasons.forEach(season => {
          rateYear[season] = ''
          if (hasRateYearValues) {
            rateYearSeasonKey = 'rate' + season
            if (this.rateInfo.propertyRate.years[year].hasOwnProperty(rateYearSeasonKey)) {
              rateYear[season] = this.rateInfo.propertyRate.years[year][rateYearSeasonKey]
            }
          }
          // add the validation rule for this year and season
          yearSeasonKey = 'season_' + year + '_' + season
          this.rateInfo.validationRules[yearSeasonKey] = validationString
        })
        rateYears[year] = rateYear
      })
      this.$set(this.propertyRates, 'rateYears', rateYears)

      // Set up available currencies
      const availableCurrenciesArray = [
        {
          text: 'Select an option',
          value: '',
          disabled: true
        }
      ]
      let currency
      for (const i in this.rateInfo.availableCurrencies) {
        currency = this.rateInfo.availableCurrencies[i]
        availableCurrenciesArray.push({
          text: currency.currencyCode,
          value: currency.currencies_id
        })
      }

      this.$set(this.propertyRates, 'availableCurrencies', availableCurrenciesArray)
      this.$set(this.propertyRates, 'validationRules', this.$validationRules(this.rateInfo.validationRules))

      // // Set up default selected / loaded currencies
      this.$set(this.propertyRates, 'loadedCurrency', { id: '', currencyCode: '' })
      this.$set(this.propertyRates, 'selectedCurrency', { id: '', currencyCode: '' })
      this.$set(this.propertyRates, 'savedCurrency', { id: '', currencyCode: '' })

      let currencyIdSelected = ''
      let currencyCodeSelected = ''
      // first take it from the default if set
      if (this.rateInfo.defaults.propertyRate) {
        currencyIdSelected = this.rateInfo.defaults.propertyRate.currencies_id
        currencyCodeSelected = this.rateInfo.defaults.propertyRate.currencyCode
      }
      // then overwrite it with the previous value if set.
      if (this.rateInfo.propertyRate) {
        currencyIdSelected = this.rateInfo.propertyRate.currencies_id
        currencyCodeSelected = this.rateInfo.propertyRate.currencyCode
      }

      // Overwrite currency values, if something has come back from API -in either defaults or property specific
      if (currencyIdSelected && currencyCodeSelected) {
        this.$set(this.propertyRates, 'loadedCurrency', {
          id: currencyIdSelected,
          currencyCode: currencyCodeSelected
        })
        this.$set(this.propertyRates, 'selectedCurrency', {
          id: currencyIdSelected,
          currencyCode: currencyCodeSelected
        })
        this.$set(this.propertyRates, 'savedCurrency', {
          id: currencyIdSelected,
          currencyCode: currencyCodeSelected
        })
      }

      const discountTypes = ['Late', 'Long']
      let discountAvailable, discountDays, discountPercentage
      discountTypes.forEach(discountType => {
        // 1st set them to the defaults if defaults are set
        if (this.rateInfo.defaults.propertyRate) {
          discountAvailable = Boolean(this.rateInfo.defaults.propertyRate[discountType.toLowerCase() + 'DiscountAvailable'])
          if (discountAvailable) {
            discountDays = this.rateInfo.defaults.propertyRate[discountType.toLowerCase() + 'Days']
            discountPercentage = this.rateInfo.defaults.propertyRate[discountType.toLowerCase() + 'DiscountPercentage']
          } else {
            discountDays = ''
            discountPercentage = ''
          }
        }
        // then overwrite it with the previous value if set.
        if (this.rateInfo.propertyRate) {
          discountAvailable = Boolean(this.rateInfo.propertyRate[discountType.toLowerCase() + 'DiscountAvailable'])
          if (discountAvailable) {
            discountDays = this.rateInfo.propertyRate[discountType.toLowerCase() + 'Days']
            discountPercentage = this.rateInfo.propertyRate[discountType.toLowerCase() + 'DiscountPercentage']
          } else {
            discountDays = ''
            discountPercentage = ''
          }
        }
        this.$set(this.propertyRates, 'discount' + discountType, {
          days: discountDays,
          available: discountAvailable,
          percentage: discountPercentage
        })
      })

      let discountPeak = 1
      // 1st set discount peak to the defaults if defaults are set
      if (this.rateInfo.defaults.propertyRate) {
        discountPeak = this.rateInfo.defaults.propertyRate.discountPeak
      }
      // then overwrite it with the actual value for the property
      if (this.rateInfo.propertyRate) {
        discountPeak = this.rateInfo.propertyRate.discountPeak
      }
      this.$set(this.propertyRates, 'discountPeak', Boolean(discountPeak))

      const availablePoolHeatingArray = [
        {
          text: 'DV Default',
          value: null
        }
      ]
      let poolHeating
      for (const i in this.rateInfo.poolHeating_available) {
        poolHeating = this.rateInfo.poolHeating_available[i]
        availablePoolHeatingArray.push({
          text: poolHeating.currencyCode + '-' + poolHeating.itemName,
          value: poolHeating.owner_link_to_item_id
        })
      }

      // console.log(availablePoolHeatingArray)
      this.$set(this.propertyRates, 'availablePoolHeatingOptions', availablePoolHeatingArray)
      this.$set(this.propertyRates, 'defaultPoolHeating', this.rateInfo.poolHeating_default)
      if (this.rateInfo.propertyRate.hasOwnProperty('pool_heating_id') && this.rateInfo.propertyRate.pool_heating_id > 0) {
        this.$set(this.propertyRates, 'selectedPoolHeating', this.rateInfo.propertyRate.pool_heating_id)
      }

      const cleaningFeesArray = [
        {
          text: 'DV Default',
          value: null
        }
      ]
      let cleaningFee
      for (const i in this.rateInfo.cleaningFees_available) {
        cleaningFee = this.rateInfo.cleaningFees_available[i]
        cleaningFeesArray.push({
          text: cleaningFee.currencyCode + '-' + cleaningFee.itemName,
          value: cleaningFee.owner_link_to_item_id
        })
      }

      // console.log(cleaningFeesArray)
      this.$set(this.propertyRates, 'availableCleaningFeesOptions', cleaningFeesArray)
      if (this.rateInfo.propertyRate.hasOwnProperty('cleaning_fees_id') && this.rateInfo.propertyRate.cleaning_fees_id > 0) {
        this.$set(this.propertyRates, 'selectedCleaningFee', this.rateInfo.propertyRate.cleaning_fees_id)
      }

      const seasonSetsArray = [
        {
          text: 'DV Default',
          value: null
        }
      ]
      let seasonSet
      for (const i in this.rateInfo.seasonSets_available) {
        seasonSet = this.rateInfo.seasonSets_available[i]
        seasonSetsArray.push({
          text: seasonSet.itemName,
          value: seasonSet.owner_link_to_item_id
        })
      }
      // console.log(seasonSetsArray)
      this.$set(this.propertyRates, 'availableSeasonSetsOptions', seasonSetsArray)
      if (this.rateInfo.propertyRate.hasOwnProperty('season_sets_id') && this.rateInfo.propertyRate.season_sets_id > 0) {
        this.$set(this.propertyRates, 'selectedSeasonSet', this.rateInfo.propertyRate.season_sets_id)
      }

      // 1st set it to the default then override that if we have a specific value
      // DVMarkupPercentage onluy editable and submitted if dvadmin - however required to display the web prices
      if (this.rateInfo.defaults.propertyRate.hasOwnProperty('DVMarkupPercentage')) {
        this.$set(this.propertyRates, 'DVMarkupPercentage', this.rateInfo.defaults.propertyRate.DVMarkupPercentage)
      }
      if (this.rateInfo.propertyRate.hasOwnProperty('DVMarkupPercentage')) {
        this.$set(this.propertyRates, 'DVMarkupPercentage', this.rateInfo.propertyRate.DVMarkupPercentage)
      }

      // add the minimum bookable nights properties if they are in the response - these will only be editable if we're dvadmin as mcs cannot edit these values
      const minimumBookableNightTypes = ['bookableNightsMin', 'bookableNightsMinPeakInclusive']
      let minimumBookableNightType
      for (const i in minimumBookableNightTypes) {
        minimumBookableNightType = minimumBookableNightTypes[i]
        // copy it from the defaults first and then overide the individual one if its set
        if (this.rateInfo.defaults.propertyRate.hasOwnProperty(minimumBookableNightType)) {
          this.$set(this.propertyRates, minimumBookableNightType, this.rateInfo.defaults.propertyRate[minimumBookableNightType])
        }
        if (this.rateInfo.propertyRate.hasOwnProperty(minimumBookableNightType)) {
          this.$set(this.propertyRates, minimumBookableNightType, this.rateInfo.propertyRate[minimumBookableNightType])
        }
      }

      const userRole = localStorage.getItem('userRole')
      if (userRole === 'MC_OWNER_DV_ADMIN_MASTER') {
        this.isDvAdmin = true
      } else {
        this.isDvAdmin = false
      }
      this.storeRetrievedToDetectChanges()
      this.setFormsToDisplay(this.rateInfo.requiredElements)
    },

    /**
     * Sets the forms to display booleans whch then control whether they are output on the page
     *
     * @param {Object} requiredElements
     * @param {Boolean} requiredElements.currency
     * @param {Boolean} requiredElements.propertyRateYears
     * @param {Boolean} requiredElements.discounts
     * @param {Boolean} requiredElements.poolHeating
     * @param {Boolean} requiredElements.cleaningFee
     * @param {Boolean} requiredElements.seasonSets
     *
     * @return {void}
     */
    setFormsToDisplay (requiredElements) {
      Object.entries(requiredElements).forEach(([key, value]) => {
        this.$set(this.displayFormsToEdit, key, value)
      })
    },

    async fetchPropertyRates () {
      this.setLoadingState(true)
      const propertyRatesResponse = await this.$apiCaller({
        route: '/property-rates/' + this.$route.params.propertyId
      })
      this.propertyInfo = propertyRatesResponse.data.propertyInfo
      this.rateInfo = propertyRatesResponse.data.rateInfo
      this.exchangeRateInfo = propertyRatesResponse.data.exchangeRateInfo
      this.refreshPropertyRatesUI()
      if (!this.rateInfo.propertyRate) {
        if (!this.propertyRates.loadedCurrency.id) {
          this.openCurrencyWarningModal()
        } else {
          this.$set(this.propertyRates, 'propertySetup', true) // more of a currency ius setup - but allows displaying the ofrm etc - this whole page and logic needs refactoring.
          this.resetInvalidYearRows() // we need to do this - else theyre all errored red -
        }
      } else {
        this.$set(this.propertyRates, 'propertySetup', true)
        this.resetInvalidYearRows()
      }
      this.setLoadingState(false)
    },

    openCurrencyWarningModal () {
      this.$nextTick(() => {
        this.$refs.currencyWarningModal.show()
      })
    },

    modalHide (event) {
      if (event.trigger === 'ok') {
        this.$set(this.propertyRates, 'propertySetup', true)
        this.updatePropertyCurrency()
      } else {
        if (this.propertyRates.savedCurrency.currencyCode) {
          this.cancelPropertyCurrency()
        }
      }
    },

    updatePropertyCurrency () {
      this.propertyRates.savedCurrency = {
        id: this.propertyRates.selectedCurrency.id,
        currencyCode: this.propertyRates.selectedCurrency.currencyCode
      }
      const currencyHasBeenChanged = (this.propertyRates.selectedCurrency.id !== this.propertyRates.loadedCurrency.id)
      // need to set rowvalidstatus per each year when we're changing values - or emptying values if currency is changing
      Object.keys(this.propertyRates.rateYears).forEach(year => {
        Object.keys(this.propertyRates.rateYears[year]).forEach(season => {
          if (currencyHasBeenChanged) {
            this.propertyRates.rateYears[year][season] = ''
          } else {
            // load backup Rates - NOT sure why Alex added this or whether we need it?
            // this.propertyRates.rateYears[year][season] = this.propertyRatesBackup.backup.rates[year][season]
          }
        })
      })
      this.resetInvalidYearRows()
    },

    cancelPropertyCurrency () {
      this.propertyRates.selectedCurrency = {
        id: this.propertyRates.savedCurrency.id,
        currencyCode: this.propertyRates.savedCurrency.currencyCode
      }
    },

    validateYearRows () {
      return new Promise((resolve) => {
        const yearChecker = (year, checkSibling = false) => {
          let rowValid = null
          if (this.propertyRates.rateYears[year].Low && this.propertyRates.rateYears[year].Mid && this.propertyRates.rateYears[year].High && this.propertyRates.rateYears[year].Peak) {
            rowValid = true
          } else if (!this.propertyRates.rateYears[year].Low && !this.propertyRates.rateYears[year].Mid && !this.propertyRates.rateYears[year].High && !this.propertyRates.rateYears[year].Peak) {
            if (!checkSibling) {
              rowValid = true
            } else {
              if (parseInt(year) !== parseInt(new Date().getFullYear())) {
                if (this.propertyRates.rateYears.hasOwnProperty(year + 1)) {
                  if (this.propertyRates.rateYears[year + 1].Low || this.propertyRates.rateYears[year + 1].Mid || this.propertyRates.rateYears[year + 1].High || this.propertyRates.rateYears[year + 1].Peak) {
                    rowValid = false
                  } else {
                    rowValid = true
                  }
                }
              }
            }
          } else {
            rowValid = false
          }
          return rowValid
        }
        for (const year in this.propertyRates.rateYears) {
          if (this.propertyRates.rateYears.hasOwnProperty(year)) {
            this.$set(this.rowValidStatus, year, yearChecker(year))
            if (this.propertyRates.rateYears.hasOwnProperty(year - 1)) {
              this.$set(this.rowValidStatus, (year - 1), yearChecker(year - 1, true))
            }
          }
        }

        const invalidRows = []
        for (const year in this.rowValidStatus) {
          if (this.rowValidStatus.hasOwnProperty(year)) {
            if (!this.rowValidStatus[year]) {
              invalidRows.push(year)
            }
          }
        }
        if (invalidRows.length) {
          resolve(false)
        } else {
          resolve(true)
        }
      })
    },

    /**
     * Checks the form for validation errors and then calls the method to submit the form values if there are no validation errors
     * @return {void}
     */
    async checkPropertyRatesForm () {
      // console.log('TODO - We also need to check if there are form differences - maybe have a computed boolean - we can also disable the submit button if no changes.')
      const validValues = await this.$validator.validate()

      // we only need the rate years validated if we're requiring them to be submitted - we validate this here rather than below as we want the invlid validation highlighted in the form if we scroll to a value above it
      const validYearRows = this.displayFormsToEdit.propertyRateYears ? await this.validateYearRows() : true

      if (!validValues) {
        setTimeout(() => {
          const invalidFields = document.getElementsByClassName('is-invalid')
          this.$scrollTo(invalidFields[0])
        }, 250)
        return // validation has failed - no point continuing with this function
      }

      if (!validYearRows) {
        setTimeout(() => {
          const invalidRows = document.getElementsByClassName('invalid-row')
          this.$scrollTo(invalidRows[0])
        }, 250)
        return // validation has failed - no point continuing with this function
      }

      // at this point the form is valid so lets submit the values
      this.submitPropertyRatesForm()
    },

    /**
     * Submits the form values to the API
     * @return {void}
     */
    async submitPropertyRatesForm () {
      this.formSubmitting = true
      this.setLoadingState(true)

      const propertyRatesToSubmit = {}

      if (this.displayFormsToEdit.discounts) {
        propertyRatesToSubmit.lateDays = 0
        propertyRatesToSubmit.lateDiscountPercentage = 0
        propertyRatesToSubmit.lateDiscountAvailable = 0
        propertyRatesToSubmit.longDays = 0
        propertyRatesToSubmit.longDiscountPercentage = 0
        propertyRatesToSubmit.longDiscountAvailable = 0
        propertyRatesToSubmit.discountPeak = (this.propertyRates.discountPeak ? 1 : 0)

        const discountTypes = ['Late', 'Long']
        discountTypes.forEach(discountType => {
          const discountDays = this.propertyRates['discount' + discountType].days
          const discountAvailable = this.propertyRates['discount' + discountType].available
          const discountPercentage = this.propertyRates['discount' + discountType].percentage
          if (discountAvailable) { // discount is enabled - overwrite the zeroed values
            propertyRatesToSubmit[discountType.toLowerCase() + 'Days'] = discountDays
            propertyRatesToSubmit[discountType.toLowerCase() + 'DiscountPercentage'] = discountPercentage
            propertyRatesToSubmit[discountType.toLowerCase() + 'DiscountAvailable'] = 1 // we want this as a one - if we use the discountAvailable value - thats true so we hard code a 1 here as thats what the backend database wants
          }
        })
      }

      if (this.displayFormsToEdit.currency) {
        propertyRatesToSubmit.currencies_id = this.propertyRates.selectedCurrency.id
      }

      if (this.displayFormsToEdit.poolHeating) {
        propertyRatesToSubmit.pool_heating_id = (this.propertyRates.selectedPoolHeating ? this.propertyRates.selectedPoolHeating : 0)
      }

      if (this.displayFormsToEdit.cleaningFee) {
        propertyRatesToSubmit.cleaning_fees_id = (this.propertyRates.selectedCleaningFee ? this.propertyRates.selectedCleaningFee : 0)
      }

      if (this.displayFormsToEdit.seasonSets) {
        propertyRatesToSubmit.season_sets_id = (this.propertyRates.selectedSeasonSet ? this.propertyRates.selectedSeasonSet : 0)
      }

      if (this.displayFormsToEdit.propertyRateYears) {
        propertyRatesToSubmit.property_rates_years = this.propertyRates.rateYears
      }

      if (this.displayFormsToEdit.DVMarkup) {
        propertyRatesToSubmit.DVMarkupPercentage = this.propertyRates.DVMarkupPercentage
      }

      // add the minBookableNights fields if we're dvadmin
      if (this.displayFormsToEdit.bookableNightsMin) {
        propertyRatesToSubmit.bookableNightsMin = this.propertyRates.bookableNightsMin
        propertyRatesToSubmit.bookableNightsMinPeakInclusive = this.propertyRates.bookableNightsMinPeakInclusive
      }

      // console.log(this.displayFormsToEdit)

      const submittedResponse = await this.$apiCaller({
        route: '/property-rates/' + this.$route.params.propertyId,
        method: 'POST',
        data: propertyRatesToSubmit
      })

      this.storeRetrievedToDetectChanges()
      const alertMessage = 'This property rate has been saved'
      this.$dialog.alert({ body: alertMessage, title: 'Property rate saved' }, { html: false })

      this.formSubmitting = false
      this.setLoadingState(false)
      console.log(submittedResponse.data)
    }
  },

  data () {
    return {
      loading: true,
      formSubmitting: false,
      propertyInfo: {},
      exchangeRateInfo: {},
      currencyModal: null,
      rowValidStatus: {},
      propertyRates: {
        propertySetup: false, // is this a new property

        selectedCurrency: { // For intermediate selection
          id: '',
          currencyCode: ''
        },

        savedCurrency: { // Once saved
          id: '',
          currencyCode: ''
        },

        loadedCurrency: { // As it comes back from the API
          id: '',
          currencyCode: ''
        },

        availableCurrencies: [],

        rateYears: {},

        discountLate: {
          days: '',
          percentage: '',
          available: false
        },

        discountLong: {
          days: '',
          percentage: '',
          available: false
        },

        discountPeak: true,

        defaultPoolHeating: {},
        availablePoolHeatingOptions: [],
        selectedPoolHeating: null,
        availableCleaningFeesOptions: [],
        selectedCleaningFee: null,
        availableSeasonSetsOptions: [],
        selectedSeasonSet: null,

        validationRules: {},
        DVMarkupPercentage: '',
        bookableNightsMin: '',
        bookableNightsMinPeakInclusive: ''
      },
      displayFormsToEdit: {
        currency: true,
        propertyRateYears: true,
        discounts: true,
        poolHeating: true,
        cleaningFee: true,
        seasonSets: true
      },
      propertyRatesBackup: {},
      isDvAdmin: false
    }
  }
}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/sections/rates/view-edit-rates.scss';
</style>
