<template>
  <div v-if="!loading">
    <div class="title-container">
      <b-row>
        <b-col cols="12">
          <h1>{{ $route.name }}</h1>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-form @submit.prevent="setWayAndAmount" autocomplete="off">
        <fieldset :disabled="wayAndAmountSet">
          <b-row>
            <b-col lg="6">
              <b-form-group
                label="Percentage to increase / decrease"
                label-for="percent">
                <b-input-group
                  append="%">
                  <b-form-input
                    type="number"
                    id="percent"
                    v-model="update.percent"
                    required
                    placeholder="Percentage"
                    min="0.1"
                    max="15"
                    step="0.1">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Direction"
                label-for="direction">
                <b-form-select
                  v-model="update.way"
                  id="direction"
                  :options="updateWays"
                  required>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="12" class="text-right">
              <b-button type="submit" variant="primary" v-if="!wayAndAmountSet">Preview Changes</b-button>
            </b-col>
          </b-row>
         </fieldset>
      </b-form>
      <b-row>
        <b-col class="text-right">
          <b-button type="submit" variant="warning" v-if="wayAndAmountSet" @click="clearWayAndAmount">Cancel</b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="wayAndAmountSet">
      <b-row>
        <b-col cols="12">
          <h2>Rates Preview</h2>
          <p>Please note - these are your net rates (the amounts you will receive)</p>
        </b-col>
      </b-row>
      <div class="villa-feed">
        <feed-item
          v-for="(property, key) in propertyRatesFeed"
          :key="property.villaCode"
          :imgSrc="property.coverImage.imgUrl+(property.coverImage.imgUrl.includes('googleusercontent')?'':'')"
          :villaId="property.villaCode"
          :villaAddress="property.address1">
          <b-row>
            <b-col>
              <b-table stacked="sm" :items="property.rate.rateYears" :fields="rateFields" :caption-top="true" :caption="'Existing Rate (' + property.rate.currencyCode + ')'"></b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table stacked="sm" :items="property.rateYearsPreview" :fields="rateFields" :caption-top="true" :caption="'Rate Changes Preview (' + property.rate.currencyCode + ')'"></b-table>
            </b-col>
          </b-row>
        </feed-item>
      </div>
      <b-row>
        <b-col class="text-left">
          <b-button type="submit" variant="warning" v-if="wayAndAmountSet" @click="clearWayAndAmount">Cancel</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button type="submit" variant="primary" @click="confirmChanges">Confirm And Make Changes</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import loader from '@/components/global/loader'
export default {
  components: {
    loader
  },

  name: 'VillaRatesFeed',

  mounted () {
    this.fetchRates()
  },

  methods: {

    roundToNumberOfDigits (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },

    clearWayAndAmount () {
      this.wayAndAmountSet = false
    },

    setWayAndAmount () {
      let existingRateYears
      let yearPreview
      let rateYearsPreview
      const tariffs = ['rateLow', 'rateMid', 'rateHigh', 'ratePeak']
      let multiplier = 1
      switch (this.update.way) {
        case 'increase':
          multiplier += (this.update.percent / 100)
          break
        case 'decrease':
          multiplier -= (this.update.percent / 100)
          break
      }
      for (const i in this.propertyRatesFeed) {
        existingRateYears = this.propertyRatesFeed[i].rate.rateYears
        rateYearsPreview = []
        for (const j in existingRateYears) {
          yearPreview = {
            year: existingRateYears[j].yearDisplay
          }
          tariffs.forEach(tariff => {
            yearPreview[tariff] = this.roundToNumberOfDigits((existingRateYears[j][tariff] * multiplier), 2)
          })
          rateYearsPreview.push(yearPreview)
        }
        this.$set(this.propertyRatesFeed[i], 'rateYearsPreview', rateYearsPreview)
      }
      this.wayAndAmountSet = true
    },

    confirmChanges () {
      const rateUpdateData = []
      let propertyRateUpdate
      this.propertyRatesFeed.forEach(property => {
        propertyRateUpdate = {
          propertyId: property.villaId,
          rateYears: {}
        }
        property.rateYearsPreview.forEach(rateYear => {
          propertyRateUpdate.rateYears[rateYear.year] = {
            rateLow: rateYear.rateLow,
            rateMid: rateYear.rateMid,
            rateHigh: rateYear.rateHigh,
            ratePeak: rateYear.ratePeak
          }
        })
        rateUpdateData.push(propertyRateUpdate)
      })

      this.setLoadingState(true)
      this.$apiCaller({
        route: '/property-rates/mass-update',
        method: 'POST',
        data: rateUpdateData
      })
        .then(response => {
          const alertMessage = 'Property rates have all been updated'
          this.$dialog.alert({ body: alertMessage, title: 'Property rates updated' }, { html: false })
          this.$router.push({ path: '/' }) // redirect back to /rates dashboard
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    fetchRates () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/property-rates/feed'
      })
        .then(response => {
          this.propertyRatesFeed = []
          let propertyInfo
          response.data.feed.forEach(feedItem => {
            propertyInfo = feedItem
            if (response.data.rates.hasOwnProperty(propertyInfo.villaId)) {
              propertyInfo.rate = response.data.rates[propertyInfo.villaId]

              if (propertyInfo.rate.external) {
                return
              }

              this.propertyRatesFeed.push(propertyInfo)
            }
          })
          // console.log(this.propertyRatesFeed)
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    },

    displayAmount (value) {
      // https://blog.abelotech.com/posts/number-currency-formatting-javascript/
      return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  },

  data () {
    return {
      loading: true,
      propertyRatesFeed: [],
      update: {
        way: null,
        percent: 0
      },
      updateWays: [
        { text: '-- Choose Direction --', value: null },
        { text: 'Increase', value: 'increase' },
        { text: 'Decrease', value: 'decrease' }
      ],
      wayAndAmountSet: false,
      rateFields: [
        {
          key: 'year'
        },
        {
          key: 'rateLow',
          label: 'Low',
          formatter: this.displayAmount
        },
        {
          key: 'rateMid',
          label: 'Mid',
          formatter: this.displayAmount
        },
        {
          key: 'rateHigh',
          label: 'High',
          formatter: this.displayAmount
        },
        {
          key: 'ratePeak',
          label: 'Peak',
          formatter: this.displayAmount
        }
      ]
    }
  }
}
</script>

<style lang="scss">
	// @import './src/assets/styles/_variables.scss';
	// @import './node_modules/bootstrap/scss/bootstrap.scss';
</style>
