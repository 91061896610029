<template>
	<div v-if="!loading">
		<div class="title-container">
      <b-row>
				<b-col cols="12" lg="8">
					<h1>{{ $route.name }}</h1>
				</b-col>
				<b-col cols="12" lg="4">
					<b-button variant="secondary" :to="newTariffLink">
						New Tariff
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div class="villa-feed">
			<feed-item
					:villaAddress="rate.itemName + ' - ' + rate.currencyCode"
					:to="'/pool-heating/' + rate.owner_link_to_item_id"
					buttonText="Edit Tariff"
					v-for="rate in poolHeatingRates"
					:key="rate.itemName">
				<ul class="rates">
					<li>
						<div class="rate-container">
							<h3>Setup Rate</h3>
							{{ rate.setupRate | currency }}
							<small>{{ rate.currencyCode }}</small>
						</div>
					</li>
					<li>
						<div class="rate-container">
							<h3>Daily Rate</h3>
							{{ rate.nightlyRate | currency }}
							<small>{{ rate.currencyCode }}</small>
						</div>
					</li>
					<li>
						<div class="rate-container">
							<h3>Minimum Charge</h3>
							{{ rate.minNights }}
							<small>Nights</small>
						</div>
					</li>
				</ul>
			</feed-item>
      <p v-if="poolHeatingRates.length < 1">
        You have not created any Pool Heating Tariffs. Click <b-link :to="newTariffLink"><strong>New Tariff</strong></b-link> to create one.
      </p>
		</div>
	</div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import loader from '@/components/global/loader'
export default {
  components: {
    loader
  },
  name: 'PoolHeatingFeed',

  mounted () {
    this.fetchPoolHeating()
  },

  methods: {
    fetchPoolHeating () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/pool-heating'
      })
        .then(response => {
          this.poolHeatingRates = response.data.feedItems
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },

  data () {
    return {
      loading: true,
      newTariffLink: '/pool-heating/0',
      poolHeatingRates: []
    }
  }
}
</script>

<style lang="scss">
	// @import './src/assets/styles/_variables.scss';
	// @import './node_modules/bootstrap/scss/bootstrap.scss';
</style>
