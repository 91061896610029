<template>
  <div class="view-edit-rates" v-if="!loading">
    <div class="title-container blue">
      <b-row>
        <b-col cols="12">
          <h1 class="alt">
            <template v-if="$route.params.oltiId === '0'">New cleaning fee</template>
            <template v-else>Edit cleaning fee</template>
          </h1>
        </b-col>
      </b-row>
    </div>

    <div class="season-container" ref="ratesContainer">
      <div class="pricing pt-0 mt-0">
        <b-form @submit.prevent="saveTariff" autocomplete="off" novalidate>
          <fieldset :disabled="formSubmitting">
            <div class="year-container">
              <h2>{{ tariffTitle }}</h2>
              <b-row>
                <b-col lg="6">
                  <div class="season-price">
                    <label for="itemName">Tariff Name</label>
                    <b-input-group>
                      <b-form-input
                      v-model="cleaningFee.itemName"
                      id="itemName"
                      type="text"
                      :value="cleaningFee.itemName"
                      data-vv-as="Tariff Name"
                      name="itemName"
                      :state="$validateState(veeFields, errors, 'itemName')"
                      :maxlength="validationRules.itemName.max"
                      v-validate="validationRules.itemName"></b-form-input>

                      <b-form-invalid-feedback id="itemName_feedback">
                        <ul style="padding-left: 0; list-style: none;">
                          <li v-for="error in errors.collect('itemName')">{{ error }}</li>
                        </ul>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </b-col>

                <b-col lg="6">
                  <div class="season-price">
                    <label for="tariffCurrency">Currency</label>
                    <b-input-group>
                      <b-form-select
                      id="tariffCurrency"
                      v-model="cleaningFee.currencies_id"
                      :options="availableCurrencies"
                      data-vv-as="Currency"
                      name="currencies_id"
                      :state="$validateState(veeFields, errors, 'currencies_id')"
                      v-validate="validationRules.currencies_id">
                    </b-form-select>

                    <b-form-invalid-feedback id="currency_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('currencies_id')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <div class="season-price">
                  <label for="amount">Amount</label>
                  <b-input-group>
                    <template #prepend v-if="cleaningFee.currencyCode">
                      <b-input-group-text>
                        <span v-html="currencySymbol(cleaningFee.currencyCode)"></span>
                      </b-input-group-text>
                    </template>

                    <b-form-input
                    v-model="cleaningFee.amount"
                    id="amount"
                    type="number"
                    name="amount"
                    :min="validationRules.amount.min_value"
                    :max="validationRules.amount.max_value"
                    :value="cleaningFee.amount"
                    :state="$validateState(veeFields, errors, 'amount')"
                    v-validate="validationRules.amount"
                    data-vv-as="Tariff Amount"></b-form-input>

                    <b-form-invalid-feedback id="amount_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('amount')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>

              <b-col lg="6">
                <div class="season-price">
                  <label for="disabledDays">Fee Disabled <small>if greater than this No. nights</small></label>
                  <b-input-group>
                    <b-form-input
                    v-model="cleaningFee.disabledDays"
                    id="disabledDays"
                    type="number"
                    :value="cleaningFee.disabledDays"
                    :min="validationRules.disabledDays.min_value"
                    :max="validationRules.disabledDays.max_value"
                    name="disabledDays"
                    :state="$validateState(veeFields, errors, 'disabledDays')"
                    v-validate="validationRules.disabledDays"
                    data-vv-as="Disabled Days"></b-form-input>

                    <b-form-invalid-feedback id="disabledDays_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('disabledDays')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="saver">
            <b-row align-h="end">
              <b-col v-if="$route.params.oltiId !== '0'">
                <i>Prices of existing bookings will not be affected.</i>
              </b-col>
              <b-col class="m-2 m-lg-0" lg="3" v-if="assignedProperties.length < 1 && $route.params.oltiId !== '0'">
                <b-button variant="danger" v-confirm="{
                      ok: dialog => deleteTariff(),
                      message: 'This will delete this cleaning fee - are you sure?',
                      okText: 'Yes',
                      cancelText: 'No'
                  }">Delete</b-button>
              </b-col>
              <b-col lg="3" v-else>
                <small v-if="$route.params.oltiId !== '0'">Cleaning fee cannot be deleted as it has properties assigned</small>
              </b-col>
              <b-col class="m-2 m-lg-0" lg="3" v-if="$route.params.oltiId === '0'">
                <b-button variant="info" @click="$router.go(-1)">Cancel</b-button>
              </b-col>
              <b-col class="m-2 m-lg-0" lg="3">
                <b-button variant="primary" type="submit" :disabled="JSON.stringify(cleaningFee) === cleaningFeeDiff">
                  <template v-if="$route.params.oltiId === '0'">
                    Create Tariff
                  </template>
                  <template v-else>
                    Update
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </b-form>
    </div>
  </div>

  <property-list
    v-if="$route.params.oltiId !== '0'"
    noPropertiesMessage="This tariff does not have any properties assigned to it."
    :properties="assignedProperties">
  </property-list>
  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import propertyList from '@/components/global/miniPropertyList.vue'
import loader from '@/components/global/loader'
export default {
  components: {
    'property-list': propertyList,
    loader
  },

  mounted () {
    this.fetchCleaningFee()
  },

  methods: {
    deleteTariff () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/cleaning-fees/' + this.$route.params.oltiId,
        method: 'DELETE'
      })
        .then(response => {
          this.$router.push({ path: '/cleaning-fees' })
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    saveTariff () {
      this.$validator.validate().then(isValid => {
        if (isValid) {
          this.formSubmitting = true
          this.setLoadingState(true)
          const cleaningFee = {
            itemName: this.cleaningFee.itemName,
            currencies_id: this.cleaningFee.currencies_id,
            disabledDays: this.cleaningFee.disabledDays,
            amount: this.cleaningFee.amount
          }
          this.$apiCaller({
            route: '/pricing-service/cleaning-fees/' + this.$route.params.oltiId,
            method: 'POST',
            data: cleaningFee
          })
            .then(response => {
              const alertMessage = 'This cleaning fee has been saved'
              this.$dialog.alert({ body: alertMessage, title: 'Cleaning fee saved' }, { html: false })
            })
            .finally(() => {
              this.formSubmitting = false
              this.setLoadingState(false)
              if (this.$route.params.oltiId === '0') {
                // this is a new one created - we navigate back to the feed else the oltiId is incorrect
                this.$router.push({ path: '/cleaning-fees' })
              }
            })
        } else {
          const invalidFields = document.getElementsByClassName('is-invalid')
          this.$scrollTo(invalidFields[0])
        }
      })
    },

    fetchCleaningFee () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/cleaning-fees/' + this.$route.params.oltiId
      })
        .then(response => {
          this.$set(this, 'validationRules', this.$validationRules(response.data.validationRules))

          // Set up available currencies
          const availableCurrenciesArray = [
            {
              text: 'Select an option',
              value: null,
              disabled: true
            }
          ]
          response.data.availableCurrencies.forEach(availableCurrency => {
            availableCurrenciesArray.push({
              text: availableCurrency.currencyCode,
              value: availableCurrency.currencies_id
            })
          })
          this.availableCurrencies = availableCurrenciesArray

          if (parseInt(this.$route.params.oltiId) !== 0) {
            this.$set(this.cleaningFee, 'currencies_id', response.data.item.currencies_id)
            this.$set(this.cleaningFee, 'itemName', response.data.item.itemName)
            this.$set(this.cleaningFee, 'amount', response.data.item.amount)
            this.$set(this.cleaningFee, 'disabledDays', response.data.item.disabledDays)
            const assignedProperties = []
            if (response.data.assignedTo.hasOwnProperty('property') && response.data.hasOwnProperty('assignedPropertiesInfo')) {
              response.data.assignedTo.property.forEach(propertyId => {
                if (response.data.assignedPropertiesInfo.hasOwnProperty(propertyId)) {
                  assignedProperties.push(response.data.assignedPropertiesInfo[propertyId])
                }
              })
            }
            this.assignedProperties = assignedProperties
          //
          //   this.cleaningFeeDiff = JSON.stringify(this.cleaningFee)
          }
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },

  watch: {
    'cleaningFee.currencies_id': {
      handler: function (newVal) {
        for (let i = 0; i < this.availableCurrencies.length; i++) {
          if (this.availableCurrencies[i].value === newVal) {
            this.cleaningFee.currencyCode = this.availableCurrencies[i].text
            break
          }
        }
      }
    }
  },

  computed: {
    tariffTitle () {
      let cleaningFeeTitle = ''

      if (this.cleaningFee.itemName === null || this.cleaningFee.currencyCode === null || this.cleaningFee.amount === null || this.cleaningFee.disabledDays === null) {
        cleaningFeeTitle = 'Cleaning fee tariff'
      } else {
        cleaningFeeTitle = this.cleaningFee.itemName
      }

      return cleaningFeeTitle
    }
  },

  data () {
    return {
      loading: true,
      formSubmitting: false,

      cleaningFee: {
        currencies_id: null,
        currencyCode: '',
        itemName: null,
        amount: null,
        disabledDays: null
      },

      assignedProperties: [],

      validationRules: {},

      cleaningFeeDiff: '',

      availableCurrencies: []
    }
  }
}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/sections/rates/view-edit-rates.scss';
</style>
