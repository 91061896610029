<template>
  <div v-if="!loading">
    <div class="title-container blue">
      <b-row>
        <b-col>
          <h1>Create new password</h1>
        </b-col>
      </b-row>
    </div>
    <div v-if="invalidCredentialsOrError">
      <p>{{errorMessage}}</p>
    </div>
    <div v-else>
      <div v-if="passwordChangeSuccess">
        <p>Your password has been successfully changed. Please return to <b-link @click="logout">log-in</b-link> page to sign back in using your new password.</p>
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <p>Please enter your chosen new password in the boxes below.</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form @submit.prevent="checkForm" autocomplete="off" :novalidate="true">
              <fieldset :disabled="newPasswordFormSubmitting">
                <passwordAndConfirm @updated="passwordUpdated" ref="passwordChange" passwordLabel="Choose a new password"></passwordAndConfirm>
                <b-row>
                  <b-col>
                    <div class="text-center">
                      <loader v-if="newPasswordFormSubmitting"></loader>
                      <div v-else>
                        <b-btn variant="primary" size="lg" type="submit">Set new password</b-btn>
                        <p v-if="formValidationErrors === true">Please fix the form errors above before submitting this form</p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </fieldset>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </div>
  	</div>
    <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import loader from '@/components/global/loader'
import passwordAndConfirm from '@/components/widgets/form-fields/password-and-confirm'
import { LOG_OUT } from '@/store/actions/authnew'
export default {
  components: {
    loader,
    passwordAndConfirm
  },
  mounted () {
    this.checkPasswordResetCredentials()
  },
  methods: {
    logout () {
      this.$store.dispatch(LOG_OUT, { timedOut: false })
    },
    checkPasswordResetCredentials () {
      this.setLoadingState(true)
      const apiUrl = '/reset-password/' + this.$route.params.apiUserId + '/' + this.$route.params.token + '/' + this.$route.params.apiUserPasswordResetId
      this.$apiCaller({
        route: apiUrl,
        displayErrorAlert: false
      })
        .then(response => {
          // console.log(response)
        })
        .catch(() => {
          this.invalidCredentialsOrError = true
          this.errorMessage = 'Sorry we are unable to locate your details, it may be that the password reset link has expired.'
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },
    checkForm (event) {
      this.formValidationErrors = false
      const passwordsValidator = this.$refs.passwordChange.validator()
      Promise.all([passwordsValidator.validate()]).then(values => {
        if (values.includes(false)) {
          this.formValidationErrors = true
        } else {
          this.submitForm()
        }
      })
    },
    submitForm () {
      this.newPasswordFormSubmitting = true
      const apiUrl = '/reset-password/' + this.$route.params.apiUserId + '/' + this.$route.params.token + '/' + this.$route.params.apiUserPasswordResetId
      this.$apiCaller({ route: apiUrl, method: 'POST', data: { password: this.newPassword }, displayErrorAlert: true })
        .then(response => {
          this.passwordChangeSuccess = true // change to true to progress to next form
        })
        .catch(error => {
          if (error.response.data.hasOwnProperty('validationErrors')) {
            // has server validation errors - allow it to try again
          } else {
            this.invalidCredentialsOrError = true
            this.errorMessage = 'Sorry we are unable to update your password - please contact us for help'
          }
        })
        .finally(() => {
          this.newPasswordFormSubmitting = false
        })
    },
    passwordUpdated (event) {
      this.formValidationErrors = false
      this.newPassword = event.data.password
    },
    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },
  data () {
    return {
      loading: false,
      invalidCredentialsOrError: false,
      passwordChangeSuccess: false,
      newPasswordFormSubmitting: false,
      formValidationErrors: false,
      newPassword: ''
    }
  }
}
</script>
