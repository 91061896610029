// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import router from './router'
import store from './store'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import vSelect from 'vue-select'
import VueTimers from 'vue-timers'
import CurrencySymbol from 'vue-currency-symbol'
import VueCurrencyFilter from 'vue-currency-filter'
import VeeValidate from 'vee-validate'
import VueScrollTo from 'vue-scrollto'
import feedItem from './components/global/feedItem'

import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'

import apiCaller from '@/plugins/apiCaller'
import DVFormValidator from './plugins/fieldValidationRules'
import moment from 'moment'
import VuejsDialog from 'vuejs-dialog'

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import {
  LOG_OUT
} from '@/store/actions/authnew'

import {
  MODAL_WARNING_THRESHOLD
} from '@/store/actions/config'

import { routesNotRedirectedToLogin } from './variables.js'

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API,
    releaseStage: process.env.VUE_APP_RELEASE_STAGE,
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
    // Get Local storage and add to meta data
      const localStorage = window.localStorage
      event.addMetadata('Session', {
        ...localStorage
      })

      event.setUser(localStorage.getItem('userId'), localStorage.getItem('userEmail'))
    }
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}

// Tell Vue to install the plugin.
Vue.use(VuejsDialog, {
  html: true
})

Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  // locale: 'en-US',
  datePickerShowDayPopover: false,
  popoverVisibility: 'focus',
  screens: {
    sm: '576px', // (min-width: 640px)
    md: '768px', // (min-width: 768px)
    lg: '992px', // (min-width: 1024px)
    xl: '1200px' // (min-width: 1280px)
  }
})
Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.component('v-select', vSelect)
Vue.use(VueTimers)
Vue.use(CurrencySymbol)
Vue.use(VueCurrencyFilter, {
  symbol: '',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.use(apiCaller, {
  baseUrl: process.env.VUE_APP_API_URL,
  hooks: {
    onRequest: (config) => {
      if (localStorage.hasOwnProperty('user-token')) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('user-token')
      }
      if (localStorage.hasOwnProperty('MC_OWNER_ON_BEHALF')) {
        const MC_OWNER_ON_BEHALF = JSON.parse(localStorage.getItem('MC_OWNER_ON_BEHALF'))
        config.headers['x-mc-owner-on-behalf-id'] = MC_OWNER_ON_BEHALF.mcId
      }

      return config
    },

    onResponse: (response) => {
      let sessionTimeRemaining = 0
      const latency = 10
      if (response.headers.hasOwnProperty('x-api-user-token-expiry')) {
        sessionTimeRemaining = parseInt(response.headers['x-api-user-token-expiry'])
        // sessionTimeRemaining = 140 // for debug purposes to test the session expiry window opening
      }
      const sessionExpiry = moment().add((sessionTimeRemaining - latency), 'seconds').format('YYYY-MM-DD HH:mm:ss')
      const logoutWarning = moment(sessionExpiry, 'YYYY-MM-DD HH:mm:ss').subtract(MODAL_WARNING_THRESHOLD, 'seconds').format('YYYY-MM-DD HH:mm:ss')
      localStorage.setItem('session-expires', sessionExpiry)
      localStorage.setItem('logout-warning-opens', logoutWarning)
      return response
    },

    onRequestError: (error) => {
      return new Promise((resolve, reject) => {
        // console.log('Could not make request to server')
        // console.log(error.response)
        reject(error)
      })
    },

    onResponseError: (error) => {
      return new Promise((resolve, reject) => {
        // console.log('The response returned an error. :(')
        const defaultErrorMessage = 'Sorry there was an error performing the request - please refresh the page, try again or please contact us if the problem persists.'
        let displayAlertError = true
        if (error.config.hasOwnProperty('displayErrorAlert')) {
          displayAlertError = error.config.displayErrorAlert
        }
        let displayAlertErrorMessage = defaultErrorMessage
        let displayAlertTitle = 'ERROR'
        if (!error.response) {
          // generic axios network error - no response - display default error message and title above
          // will be triggered by no internet connection
          // can also be triggered by a timeout on the server its connecting to (eg not continuing on a breakpoint locally (nginx local timeout 10 mins triggers this))
          // see issue relating to axios network error - https://github.com/axios/axios/issues/383
        } else {
          // this should have a response status and data
          switch (error.response.status) {
            case 401:
              displayAlertError = false
              if (store.getters.isAuthenticated) {
                store.dispatch(LOG_OUT, { timedOut: false })
              }
              break
            case 404:
              displayAlertError = true
              displayAlertErrorMessage = 'Sorry the page or requested resource either doesn\'t exist or no longer exists.'
              break
            case 422:
              displayAlertTitle = 'The following form fields contain errors'
              // validation errors
              if (error.response.data) {
                const errorData = error.response.data
                if (errorData.length) {
                  const errorDataParsed = JSON.parse(errorData)
                  let response
                  if (errorDataParsed.hasOwnProperty('response')) { // api server ones are nested under response
                    response = errorDataParsed.response
                  } else {
                    response = errorDataParsed // cloud functions dont have it nested under response
                  }
                  if (response.hasOwnProperty('validationErrors')) {
                    displayAlertErrorMessage = '<ul>'
                    const responseValidationErrors = response.validationErrors
                    let validationError
                    for (const i in responseValidationErrors) {
                      if (typeof responseValidationErrors[i] === 'object' && responseValidationErrors[i].hasOwnProperty('error')) {
                        validationError = responseValidationErrors[i].error
                      } else {
                        validationError = responseValidationErrors[i]
                      }
                      displayAlertErrorMessage += '<li>' + validationError + '</li>'
                    }
                    displayAlertErrorMessage += '</ul>'
                  }
                }
              }
              break
            case 500:
              displayAlertError = true
              // server error - default error message ok
              break
            case 503:
              router.push({ path: '/maintenance' })
              break
            default:
              // 400 errors should have an error message
              let errors = false
              if (error.response.data) {
                const errorData = error.response.data
                if (errorData.length) {
                  const errorDataParsed = JSON.parse(errorData)
                  if (errorDataParsed.hasOwnProperty('errors')) {
                    errors = errorDataParsed.errors.join(',')
                  }
                }
              }
              if (errors) {
                displayAlertErrorMessage = errors
              }
          }
        }
        if (displayAlertError) {
          Vue.dialog.alert({ body: displayAlertErrorMessage, title: displayAlertTitle })
        }
        reject(error)
      })
    }
  }
})

Vue.use(DVFormValidator)

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
})

VeeValidate.Validator.extend('verify_password', {
  getMessage: field => 'The password must be at least 8 characters long, and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)',
  validate: value => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
    return strongRegex.test(value)
  }
})

Vue.config.productionTip = false

Vue.component('feed-item', feedItem)

router.beforeEach((to, from, next) => {
  const currentRole = localStorage.getItem('userRole')
  const loggedIn = (localStorage.getItem('user-token') !== null)
  if (loggedIn) {
    if (to.fullPath.includes('/login')) {
      return next('/') // already logged in, no need to go to the login page - redirect to home page
    }
    if (currentRole === 'MC_OWNER_DV_ADMIN_MASTER' && !to.fullPath.includes('/master-admin-select-mc') && !localStorage.getItem('MC_OWNER_ON_BEHALF')) {
      return next('/master-admin-select-mc')
    }
    return next()
  } else {
    let i
    for (i in routesNotRedirectedToLogin) {
      // console.log(routesNotRedirectedToLogin[i])
      if (to.fullPath.indexOf(routesNotRedirectedToLogin[i]) > -1) {
        return next()
      }
    }
    // not logged in
    if (!to.fullPath.includes('/login')) {
      return next('/login')
    }
    return next() // else we should be going to /login - or /login/:asMaster
  }
})

/* mixin to randomise the string in the autocomplete field so autocomplete not filled out as can be lots of different customer names and addresses */
Vue.mixin({
  mounted () {
    if (this.$options.name === 'BFormInput') {
      if (this.$options.propsData.autocomplete === 'off') {
        const randomAutocompleteString = this.makeRandomString(12)
        this.$el.setAttribute('autocomplete', randomAutocompleteString)
      }
    }
  },
  methods: {
    makeRandomString (length) {
      const result = []
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
      }
      return result.join('')
    }
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
