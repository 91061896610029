import {
  UPDATE_USER_ROLE,
  SET_USER_ROLE,
  UPDATE_MC_OWNER_INFO,
  SET_MC_OWNER_INFO,
  UPDATE_MC_OWNER_ON_BEHALF,
  SET_MC_OWNER_ON_BEHALF
} from '@/store/actions/usernew'

const state = {
  userRole: null,
  mcOwnerInfo: null,
  mcOwnerOnBehalf: null
}

const getters = {
  userRole: state => state.userRole,
  mcOwnerInfo: state => state.mcOwnerInfo,
  mcOwnerOnBehalf: state => state.mcOwnerOnBehalf,
  isAdmin: state => state.userRole === 'MC_OWNER_DV_ADMIN_MASTER'
}

const actions = {
  [UPDATE_USER_ROLE]: ({ commit }, userRole) => {
    commit(SET_USER_ROLE, userRole)
  },
  [UPDATE_MC_OWNER_INFO]: ({ commit }, mcOwnerInfo) => {
    commit(SET_MC_OWNER_INFO, mcOwnerInfo)
  },
  [UPDATE_MC_OWNER_ON_BEHALF]: ({ commit }, mcOwnerOnBehalf) => {
    commit(SET_MC_OWNER_ON_BEHALF, mcOwnerOnBehalf)
  }
}

const mutations = {
  [SET_USER_ROLE]: (state, userRole) => {
    state.userRole = userRole
  },
  [SET_MC_OWNER_INFO]: (state, mcOwnerInfo) => {
    state.mcOwnerInfo = mcOwnerInfo
  },
  [SET_MC_OWNER_ON_BEHALF]: (state, mcOwnerOnBehalf) => {
    state.mcOwnerOnBehalf = mcOwnerOnBehalf
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
