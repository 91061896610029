<template>
  <div>
    <div class="calendar-key" v-if="calendarKey.length > 0">
      <div class="key-outer" v-for="keys in calendarKey">
        <span class="key-label">{{ keys.name.replace('-', ' ') }}</span>
        <span :class="keys.id"></span>
      </div>
    </div>

    <div class="calendar-container" v-show="calendar.data.length > 0">
      <div class="calendar" v-for="month in calendar.data">
        <div class="month-heading ">{{ month.monthName }} {{ month.year }}</div>
        <div :class="'cell day-heading ' + dayHeading.day.toLowerCase()" v-for="dayHeading in calendar.dayHeadings" :data-day="dayHeading.heading"></div>
        <div class="cell non-day-cell" v-for="n in month.emptyCells.start">&nbsp;</div>
        <div :class="'cell ' + mday.class" v-for="mday in month.cells"
          :style="'cursor: pointer'"
          :data-day="mday.day"
          :data-date="mday.date"
          v-on="{mouseover: selectBooking, mouseout: deselectBooking}"
          @click="dateClicked">
          <div class="events-container" style="pointer-events: none;" v-if="mday.calendarEvent">
            <template v-if="isAvailabilityCalendar()">
              <a href="" :class="availabilityCalendarCellClass(mday.calendarEvent)">&nbsp;</a>
            </template>
            <template v-else>
            <a href="" :class="mday.calendarEvent.class">&nbsp;</a>
            </template>
          </div>
        </div>
        <div class="cell non-day-cell" v-for="n in month.emptyCells.end">&nbsp;</div>
      </div>
    </div>

    <loader v-if="!(calendar.data.length > 0)"></loader>

    <b-modal ref="seasonModal" size="lg"
      modal-class="availability-modal" data-modaldark="true"
      lazy centered hide-footer>
      <season-new-edit-form
        @submit="submitForm"
        @delete="deleteSeasonBlock"
        :seasonStart="selectedStartDate"
        :seasonEnd="selectedEndDate"
        :selectedSeason="selectedSeason"
        :availableSeasons="calendarKey"
        :maxDate="dateRanges.max"
        :minDate="dateRanges.min"
        :options="options"
        >
      </season-new-edit-form>
    </b-modal>
  </div>
</template>

<script>
import SeasonNewEditForm from '../logged-in/rates/SeasonNewEditForm'
import loader from '@/components/global/loader'
import moment from 'moment'

export default {
  components: {
    'season-new-edit-form': SeasonNewEditForm,
    loader
  },

  props: {
    calendarDates: {
      required: true
    },

    calendarDefaults: {
      required: false
    },

    calendarKey: {
      required: false
    },

    options: {
      required: false
    }
  },

  mounted () {
    this.buildCalendarUI_new()
  },
  //
  // watch: {
  //   calendarDates: {
  //     handler: function (newVal) {
  //       if (newVal) {
  //         this.buildCalendarUI()
  //       }
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    submitForm (event) {
      this.$emit('saveSeason', event)
      this.$refs.seasonModal.hide()
    },

    deleteSeasonBlock (event) {
      this.$emit('deleteSeason', event)
      this.$refs.seasonModal.hide()
    },

    parseDates_new (dates) {
      const parsedDates = {}
      let currentDate, targetDate
      dates.forEach(dateInfo => {
        currentDate = moment(dateInfo.dateFrom, 'YYYY-MM-DD')
        targetDate = moment(dateInfo.dateTo, 'YYYY-MM-DD')
        while (currentDate.isBefore(targetDate) || currentDate.isSame(targetDate)) {
          if (!parsedDates.hasOwnProperty(currentDate.format('YYYY'))) {
            parsedDates[currentDate.format('YYYY')] = {}
          }

          if (!parsedDates[currentDate.format('YYYY')].hasOwnProperty(currentDate.format('MM'))) {
            parsedDates[currentDate.format('YYYY')][currentDate.format('MM')] = {}
          }
          if (!parsedDates[currentDate.format('YYYY')][currentDate.format('MM')].hasOwnProperty(currentDate.format('DD'))) {
            parsedDates[currentDate.format('YYYY')][currentDate.format('MM')][currentDate.format('DD')] = {
              seasonName: this.calendarKey[(dateInfo.seasons_id - 1)].name, // not sure if we need this?
              season_set_dates_id: dateInfo.season_set_dates_id,
              seasons_id: dateInfo.seasons_id,
              // date: currentDate.format('YYYY-MM-DD'), // not sure if we need this - as we're keyed under this value
              dateFrom: dateInfo.dateFrom,
              dateTo: dateInfo.dateTo,
              reference: dateInfo.reference || null
            }
          }
          currentDate.add(1, 'days')
        }
      })
      return parsedDates
    },

    buildCalendarUI_new () {
      const defaultDates = this.parseDates_new(this.calendarDefaults)
      const seasons = this.parseDates_new(this.calendarDates)
      const monthsToDisplay = 36
      const loopDate = moment().date(1).startOf('day') // current month, but starting at the 1st
      const today = moment()
      const dates = []
      const calendarEvents = {}

      let previousCell = null

      let monthInfo, cellInfo, emptyStartCells, cellClasses, loopDay, calendarEvent, yyyy, mm, dd, dateHasSeason, eventClass, formattedDate
      for (let m = 1; m <= monthsToDisplay; m++) {
        yyyy = loopDate.format('YYYY')
        mm = loopDate.format('MM')
        monthInfo = {
          monthName: loopDate.format('MMMM'),
          year: yyyy,
          // mm: mm,
          cells: []
        }
        // empty starting cells
        emptyStartCells = parseInt(loopDate.format('E'), 10)
        if (emptyStartCells === 7) {
          emptyStartCells = 0
        }
        // add empty start and end cell counts to each month data
        monthInfo.emptyCells = {
          start: emptyStartCells,
          end: (6 * 7) - (loopDate.daysInMonth() + emptyStartCells)
        }

        loopDay = moment(loopDate)
        for (let d = 1; d <= loopDate.daysInMonth(); d++) {
          dd = loopDay.format('DD')
          // console.log(loopDay)
          cellClasses = []
          // add date in past classes
          if (loopDay.isBefore(today, 'day')) {
            cellClasses.push('calendar-past')
          }
          // add its today class
          if (loopDay.isSame(today, 'day')) {
            cellClasses.push('today')
          }
          // add defaultDates info
          if (defaultDates.hasOwnProperty(yyyy)) {
            if (defaultDates[yyyy].hasOwnProperty(mm)) {
              if (defaultDates[yyyy][mm].hasOwnProperty(dd)) {
                cellClasses.push('default_dv-' + defaultDates[yyyy][mm][dd].seasonName.toLowerCase())
              }
            }
          }
          calendarEvent = false
          // add events
          dateHasSeason = false
          if (seasons.hasOwnProperty(yyyy)) {
            if (seasons[yyyy].hasOwnProperty(mm)) {
              if (seasons[yyyy][mm].hasOwnProperty(dd)) {
                dateHasSeason = true
              }
            }
          }
          if (dateHasSeason) {
            eventClass = this.getTypeClass() + seasons[yyyy][mm][dd].seasonName.toLowerCase() + '-' + seasons[yyyy][mm][dd].season_set_dates_id

            let checkIn = false
            let checkOut = false

            if (seasons[yyyy][mm][dd].dateFrom === loopDay.format('YYYY-MM-DD')) {
              checkIn = true
            }

            if (seasons[yyyy][mm][dd].dateTo === loopDay.format('YYYY-MM-DD')) {
              checkOut = true
            }

            if (previousCell) {
              checkOut = true
            }

            calendarEvent = {
              class: eventClass,
              checkIn,
              checkOut,
              previousCell
            }
          } else {
            if (previousCell) {
              calendarEvent = {
                checkOut: true,
                previousCell
              }
            }
          }
          formattedDate = loopDay.format('YYYY-MM-DD')
          cellInfo = {
            date: formattedDate,
            day: d, // is used as an attr for the content after in the css to display the day of the month
            class: cellClasses.join(' '),
            calendarEvent
          }
          if (calendarEvent) {
            calendarEvents[formattedDate] = calendarEvent
          }

          if (previousCell === null && cellInfo.calendarEvent.checkOut) {
            previousCell = cellInfo

            // Set to false because we are not checking out this cell day
            cellInfo.calendarEvent.checkOut = false
          } else {
            previousCell = null
          }

          monthInfo.cells.push(cellInfo)
          loopDay.add(1, 'days')
        }
        dates.push(monthInfo)
        loopDate.add(1, 'months')
      }
      this.$set(this.calendar, 'data', dates)
      this.$set(this.calendar, 'events', calendarEvents)
      this.$set(this.calendar, 'seasons', seasons) // we store this to look up info when a date is clicked.
      // this.$set(this.calendar, 'defaultDates', defaultDates)
    },

    selectBooking () {
      // Select the booking elements we're interacting with
      const formattedDate = event.target.dataset.date
      if (this.calendar.events.hasOwnProperty(formattedDate)) {
        const selectedEventClass = this.calendar.events[formattedDate].class
        Array.from(document.getElementsByClassName(selectedEventClass)).forEach(selectedEventElement => {
          selectedEventElement.classList.add('selected')
        })
      } else {
        event.target.classList.add('selected')
      }
    },

    deselectBooking () {
      // Deselect the event on mouseout.
      const formattedDate = event.target.dataset.date
      if (this.calendar.events.hasOwnProperty(formattedDate)) {
        const selectedEventClass = this.calendar.events[formattedDate].class
        Array.from(document.getElementsByClassName(selectedEventClass)).forEach(selectedEventElement => {
          selectedEventElement.classList.remove('selected')
        })
      } else {
        event.target.classList.remove('selected')
      }
    },

    findEventBeforeOrAfter (startDate, way) {
      let loopDay = moment(startDate, 'YYYY-MM-DD').startOf('day')
      const today = moment().startOf('day')
      const endOfCalendar = moment().add((36 - 1), 'months').endOf('month')
      let canLoop = true
      let eventDateBeforeOrAfter = moment(loopDay)
      let dayToCheck, yyyy, mm, dd
      while (canLoop) {
        dayToCheck = moment(loopDay)
        switch (way) {
          case 'before':
            dayToCheck.subtract(1, 'days')
            break
          case 'after':
            dayToCheck.add(1, 'days')
            break
        }
        yyyy = dayToCheck.format('YYYY')
        mm = dayToCheck.format('MM')
        dd = dayToCheck.format('DD')
        // check if we have a season date
        if (this.calendar.seasons.hasOwnProperty(yyyy)) {
          if (this.calendar.seasons[yyyy].hasOwnProperty(mm)) {
            if (this.calendar.seasons[yyyy][mm].hasOwnProperty(dd)) {
              canLoop = false
            }
          }
        }
        // check if we're at the beginnning of the calendar
        if (canLoop) {
          if (dayToCheck.isBefore(today)) {
            canLoop = false
          }
        }
        // check if we're at the end of the calendar
        if (canLoop) {
          if (dayToCheck.isSameOrAfter(endOfCalendar)) {
            canLoop = false
          }
        }
        // update the date with this date that is not on a season
        if (canLoop) {
          eventDateBeforeOrAfter = moment(dayToCheck)
        }
        // make the loopDay the one incremented or deincremented
        loopDay = moment(dayToCheck)
      }
      return eventDateBeforeOrAfter.format('YYYY-MM-DD')
    },

    dateClicked () {
      const formattedDate = event.target.dataset.date
      let startOfPeriod = formattedDate
      let endOfPeriod = formattedDate
      let selectedSeason
      if (this.calendar.events.hasOwnProperty(formattedDate) && this.calendar.events[formattedDate].class) {
        const classParts = this.calendar.events[formattedDate].class.split('-')
        const seasonSetDatesId = parseInt(classParts[2], 10)
        let i, calendarDateInfo
        // TODO we should create an object when we parse the dates so we can lookup this info from the seasonSetDatesId so we dont have to loop through
        for (i in this.calendarDates) {
          calendarDateInfo = this.calendarDates[i]
          if (calendarDateInfo.season_set_dates_id === seasonSetDatesId) {
            startOfPeriod = calendarDateInfo.dateFrom
            endOfPeriod = calendarDateInfo.dateTo
            selectedSeason = {
              seasons_id: calendarDateInfo.seasons_id,
              season_set_dates_id: calendarDateInfo.season_set_dates_id,
              reference: calendarDateInfo.reference
            }

            break
          }
        }
      } else {
        // day without a season selected.
        selectedSeason = null
      }
      if (moment(endOfPeriod, 'YYYY-MM-DD').isBefore(moment().startOf('day'))) {
        return
      }

      // If it's a DV/External Booking, they cannot edit the dates
      if (this.isAvailabilityCalendar()) {
        if (selectedSeason && (selectedSeason.seasons_id === 3 || selectedSeason.seasons_id === 4)) {
          return
          // If it's blank, then set default values so the modal form can work properly
        } else if (selectedSeason === null) {
          selectedSeason = {
            seasons_id: 2, // Owner Booking
            season_set_dates_id: 0, // Used to identify if it's a new booking/season
            reference: ''
          }
        }
      }

      this.selectedSeason = selectedSeason
      this.selectedStartDate = moment(startOfPeriod, 'YYYY-MM-DD').toDate()
      this.selectedEndDate = moment(endOfPeriod, 'YYYY-MM-DD').toDate()

      const earliestDateBefore = this.findEventBeforeOrAfter(startOfPeriod, 'before')
      const latestDateAfter = this.findEventBeforeOrAfter(endOfPeriod, 'after')

      this.dateRanges.min = moment(earliestDateBefore, 'YYYY-MM-DD').toDate()
      this.dateRanges.max = moment(latestDateAfter, 'YYYY-MM-DD').toDate()

      this.$refs.seasonModal.show()
    },
    isAvailabilityCalendar () {
      return this.options && this.options.type === 'availability'
    },
    availabilityCalendarCellClass (calendarEvent) {
      const eventClass = calendarEvent.class

      if (calendarEvent.checkIn && calendarEvent.checkOut) {
        return eventClass + ` previous-${calendarEvent.previousCell.calendarEvent.class} ` + ' _both'
      }

      if (calendarEvent.checkOut && calendarEvent.previousCell) {
        return `${calendarEvent.previousCell.calendarEvent.class} ` + ' _out'
      }

      if (calendarEvent.checkIn) {
        return eventClass + ' _in'
      }

      if (calendarEvent.checkOut) {
        return eventClass + ' _out'
      }

      return eventClass
    },
    getTypeClass () {
      return this.isAvailabilityCalendar() ? '' : 'season_dv-'
    }
  },

  data () {
    return {
      selectedStartDate: null,
      selectedEndDate: null,
      selectedSeason: null,
      dateRanges: {
        min: null,
        max: null
      },

      calendar: {
        dayHeadings: [
          {
            day: 'Sunday',
            heading: 'S'
          },
          {
            day: 'Monday',
            heading: 'M'
          },
          {
            day: 'Tuesday',
            heading: 'T'
          },
          {
            day: 'Wednesday',
            heading: 'W'
          },
          {
            day: 'Thursday',
            heading: 'T'
          },
          {
            day: 'Friday',
            heading: 'F'
          },
          {
            day: 'Saturday',
            heading: 'S'
          }
        ],

        data: [],
        events: {},
        seasons: {}
        // defaultDates: {}
      }
    }
  }
}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/calendar.scss';
</style>
