<template>
  <div v-if="!loading">
    <div class="title-container blue">
			<b-row>
				<b-col>
					<h1>
						Master Admin Select MC / Owner
					</h1>
				</b-col>
			</b-row>
		</div>

    <b-form @submit.prevent="setOnBehalfMc" autocomplete="off" novalidate v-if="!hasAValueSelected">
      <b-row>
        <b-col>
          <v-select
            placeholder=" -- Select an MC / Owner -- "
            :options="mc_owner_options"
            v-model="mc_owner_selected">
          </v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right mt-3">
          <b-button type="submit" variant="primary">Submit</b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <b-row>
        <b-col cols="12">
          <p>Currently selected: <b>{{ mc_owner_selected.label }}</b></p>
        </b-col>
        <b-col cols="12" class="text-right  mt-3">
          <b-button variant="warning" @click="clearSelectedOwner">Clear and Select Another</b-button>
        </b-col>
      </b-row>
    </div>
	</div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import {
  UPDATE_MC_OWNER_ON_BEHALF
} from '@/store/actions/usernew'
import loader from '@/components/global/loader'
export default {
  components: {
    loader
  },
  mounted () {
    this.fetchMcs()
  },
  methods: {
    fetchMcs () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/master-admin-select-mc'
      })
        .then(response => {
          this.mc_owner_options = []
          response.data.response.forEach(mcIdAndName => {
            this.mc_owner_options.push({
              label: mcIdAndName.mcCodeAndName,
              value: mcIdAndName.mcId
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          // set previously selected / stored one
          if (localStorage.hasOwnProperty('MC_OWNER_ON_BEHALF')) {
            const MC_OWNER_ON_BEHALF = JSON.parse(localStorage.getItem('MC_OWNER_ON_BEHALF'))
            this.mc_owner_selected = {
              label: MC_OWNER_ON_BEHALF.mcCodeAndName,
              value: MC_OWNER_ON_BEHALF.mcId
            }
            this.hasAValueSelected = true
          }
          this.setLoadingState(false)
        })
    },
    setOnBehalfMc () {
      if (this.mc_owner_selected !== null) {
        const mcOwnerOnBehalfJson = JSON.stringify({
          mcCodeAndName: this.mc_owner_selected.label,
          mcId: parseInt(this.mc_owner_selected.value, 10)
        })
        localStorage.setItem('MC_OWNER_ON_BEHALF', mcOwnerOnBehalfJson)
        this.hasAValueSelected = true
        this.$store.dispatch(UPDATE_MC_OWNER_ON_BEHALF, this.mc_owner_selected.label)
      } else {
        this.clearSelectedOwner()
      }
    },
    clearSelectedOwner () {
      localStorage.removeItem('MC_OWNER_ON_BEHALF')
      this.hasAValueSelected = false
      this.mc_owner_selected = null
      this.$store.dispatch(UPDATE_MC_OWNER_ON_BEHALF, null)
    },
    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },
  data () {
    return {
      loading: true,
      hasAValueSelected: false,
      mc_owner_selected: null,
      mc_owner_options: []
    }
  }
}
</script>
