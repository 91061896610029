<template>
  <div class="view-edit-rates" v-if="!loading">
    <div class="title-container">
      <b-row>
        <b-col cols="12">
          <h1>{{ $route.name }}</h1>
        </b-col>
      </b-row>
    </div>

    <div class="season-container">
      <b-form @submit.prevent="checkDefaultRatesForm" autocomplete="off" novalidate>
        <fieldset :disabled="formSubmitting">

          <div v-for="(discountType) in ['late', 'long']" :key="discountType">
            <div class="pricing pt-0 vld-parent">
              <div class="year-container mb-0">
                <h2 v-if="discountType == 'late'">Late Deal Discount <small>(booking is made at this number of nights or less before arrival)</small></h2>
                <h2 v-if="discountType == 'long'">Long Stay Discount <small>(booking lasts equal to or longer than a number of nights)</small></h2>
                <b-row>
                  <b-col lg="4">
                    <div class="season-price">
                      <b-form-group :label="discountType + ' Discount'" style="text-transform:capitalize;">
                        <b-form-select v-model="defaults.discounts[discountType].available" @change="zeroOutDiscountPercentages($event, discountType)">
                          <option :value="true">Enabled</option>
                          <option :value="false">Disabled</option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col lg="4">
                    <div class="season-price">
                      <label :for="discountType + 'Days'" style="text-transform:capitalize;">{{discountType}} Days</label>
                      <b-input-group>
                        <b-form-input
                        :data-vv-as="discountType + ' Days'"
                        :name="discountType + '_days'"
                        :state="$validateState(veeFields, errors, (discountType + '_days'), !defaults.discounts[discountType].available)"
                        v-validate="validationRules[discountType + 'Days']"
                        :disabled="!defaults.discounts[discountType].available"
                        v-model="defaults.discounts[discountType].days"
                        :id="discountType + 'Days'"
                        type="number"
                        :min="validationRules[discountType + 'Days'].min_value"
                        :max="validationRules[discountType + 'Days'].max_value"
                        ></b-form-input>
                        <b-form-invalid-feedback :id="discountType + 'Days_feedback'">
                            <ul style="padding-left: 0; list-style: none;">
                              <li v-for="error in errors.collect(discountType + '_days')">{{ error }}</li>
                            </ul>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </b-col>

                  <b-col lg="4">
                    <div class="season-price">
                      <label :for="discountType + 'percentage'" style="text-transform:capitalize;">{{discountType}} Discount Percentage</label>
                      <b-input-group append="%">
                        <b-form-input
                        :data-vv-as="discountType + ' Days Discount'"
                        :name="discountType + '_days_discount'"
                        :state="$validateState(veeFields, errors, (discountType + '_days_discount'), !defaults.discounts[discountType].available)"
                        v-validate="validationRules[discountType + 'DiscountPercentage']"
                        :disabled="!defaults.discounts[discountType].available"
                        v-model="defaults.discounts[discountType].percentage"
                        :id="discountType + 'percentage'"
                        type="number"
                        step="0.1"
                        :min="validationRules[discountType + 'DiscountPercentage'].min_value"
                        :max="validationRules[discountType + 'DiscountPercentage'].max_value"
                        ></b-form-input>

                        <b-form-invalid-feedback :id="discountType + 'Discount_feedback'" style="order: 99;">
                          <ul style="padding-left: 0; list-style: none;">
                            <li v-for="error in errors.collect(discountType + '_days_discount')">{{ error }}</li>
                          </ul>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div> <!-- end discounts -->

          <div class="pricing pt-0 vld-parent" ref="peakContainer">
            <div class="year-container mb-0">
              <h2>Peak Nights Discount</h2>
              <b-row>
                <b-col lg="6">
                  <div class="season-price">
                    <b-form-group label="Discount Peak Nights">
                      <b-form-select v-model="defaults.discountPeak">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div> <!-- end discount peak  -->

          <div class="pricing pt-0 vld-parent">
            <div class="year-container mb-0">
              <h2>Pricing Currency</h2>
              <b-row>
                <b-col lg="6">
                  <div class="season-price">
                    <b-form-group label="Pricing Currency">
                      <b-form-select
                        id="tariffCurrency"
                        v-model="defaults.currencies_id"
                        :options="availableCurrencies"
                        data-vv-as="Currency"
                        name="currencies_id"
                        :state="$validateState(veeFields, errors, 'currencies_id')"
                        v-validate="validationRules.currencies_id">
                      </b-form-select>

                      <b-form-invalid-feedback>
                          <ul style="padding-left: 0; list-style: none;">
                            <li v-for="error in errors.collect('currencies_id')">{{ error }}</li>
                          </ul>
                      </b-form-invalid-feedback>

                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div> <!-- end currency -->

          <!-- start dvmarkup -->
          <div v-if="isDvAdmin">
            <div class="pricing pt-0 vld-parent">
              <div class="year-container mb-0">
                <h2>DV Markup</h2>
                <b-row>
                  <b-col lg="6">
                    <div class="season-price">
                      <label for="DVMarkupPercentage" style="text-transform:capitalize;">DV Markup Percentage</label>
                      <b-input-group append="%">
                        <b-form-input
                        data-vv-as="DV Markup Percentage"
                        name="DVMarkupPercentage"
                        :state="$validateState(veeFields, errors, 'DVMarkupPercentage')"
                        v-validate="validationRules.DVMarkupPercentage"
                        v-model="defaults.DVMarkupPercentage"
                        id="DVMarkupPercentage"
                        type="number"
                        step="0.1"
                        :min="validationRules.DVMarkupPercentage.min_value"
                        :max="validationRules.DVMarkupPercentage.max_value"
                        ></b-form-input>

                        <b-form-invalid-feedback style="order: 99;">
                          <ul style="padding-left: 0; list-style: none;">
                            <li v-for="error in errors.collect('DVMarkupPercentage')">{{ error }}</li>
                          </ul>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div> <!-- end DVMarkupPercentage -->

          <!-- start minBookableNights and minBookableNightsPeakInclusive -->
          <div v-if="isDvAdmin">
            <div class="pricing pt-0 vld-parent">
              <div class="year-container mb-0">
                <h2>Minimum Bookable Nights</h2>
                <b-row>
                  <b-col lg="6" v-for="minBookableType in [{type:'bookableNightsMin', name:'Minimum Bookable Nights'}, {type:'bookableNightsMinPeakInclusive', name:'Minimum Bookable Nights Peak Inclusive'}]" :key="minBookableType.type">
                    <div class="season-price">
                      <label :for="minBookableType.type">{{minBookableType.name}}</label>
                      <b-input-group>

                        <b-form-input
                        :data-vv-as="minBookableType.name"
                        :name="minBookableType.type"
                        :state="$validateState(veeFields, errors, minBookableType.type)"
                        v-validate="validationRules[minBookableType.type]"
                        v-model.number="defaults[minBookableType.type]"
                        :id="minBookableType.type"
                        type="number"
                        step="1"
                        :min="validationRules[minBookableType.type].min_value"
                        :max="validationRules[minBookableType.type].max_value"
                        ></b-form-input>

                        <b-form-invalid-feedback style="order: 99;">
                          <ul style="padding-left: 0; list-style: none;">
                            <li v-for="error in errors.collect(minBookableType.type)">{{ error }}</li>
                          </ul>
                        </b-form-invalid-feedback>

                      </b-input-group>
                      <small v-if="minBookableType.type === 'bookableNightsMinPeakInclusive'">
                        This is the minimum number of nights that can be booked if any 1 night is a peak date
                      </small>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div> <!-- end minBookableNights and minBookableNightsPeakInclusive -->

          <div class="pricing vld-parent" ref="saveContainer">
            <div class="saver">
              <b-row>
                <b-col md="12">
                  <i>This will populate the rates page when new property rates are setup - this will not affect any rates already set up or modified in the future.</i>
                </b-col>
              </b-row>
              <b-row align-h="end" class="mt-4">
                <b-col sm="6" lg="3">
                  <b-button class="mt-4 mt-sm-0" variant="primary" type="submit">Save</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </fieldset>
      </b-form>
  </div>

  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import loader from '@/components/global/loader'
export default {
  components: {
    loader
  },

  mounted () {
    this.fetchDefaultRates()
  },

  computed: {
    // poolHeatingDetails () {
    //   if (this.propertyRates.selectedPoolHeating === null) {
    //     return this.propertyRates.defaultPoolHeating
    //   } else {
    //     let customPoolHeat = this.rateInfo.poolHeating_available.filter(obj => {
    //       return obj.owner_link_to_item_id === this.propertyRates.selectedPoolHeating
    //     })
    //     return customPoolHeat[0]
    //   }
    // },

    // cleaningFeeDetails () {
    //   if (this.propertyRates.selectedCleaningFee === null) {
    //     return false // doesnt really return anything anywhere as this is only in the dom if the selected cleaning fee is not null
    //   } else {
    //     let customCleaningFee = this.rateInfo.cleaningFees_available.filter(obj => {
    //       return obj.owner_link_to_item_id === this.propertyRates.selectedCleaningFee
    //     })
    //     return customCleaningFee[0]
    //   }
    // }
  },

  methods: {

    // examplePoolHeatingRate (type, nights) {
    //   let rate = (this.poolHeatingDetails.setupRate + ((this.poolHeatingDetails.minNights > nights ? this.poolHeatingDetails.minNights : nights) * this.poolHeatingDetails.nightlyRate))
    //   return Number.parseFloat(rate).toFixed(2)
    // },

    zeroOutDiscountPercentages (enabled, discountPrefix) {
      if (!enabled) {
        this.defaults.discounts[discountPrefix].days = ''
        this.defaults.discounts[discountPrefix].percentage = ''
      }
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    },

    // refreshPropertyRatesUI () {
    //
    //
    //   let discountPeak = 1
    //   if (this.rateInfo.propertyRate) {
    //     discountPeak = this.rateInfo.propertyRate.discountPeak
    //   }
    //   this.$set(this.propertyRates, 'discountPeak', Boolean(discountPeak))
    //
    //   let availablePoolHeatingArray = [
    //     {
    //       text: 'Default',
    //       value: null
    //     }
    //   ]
    //   let poolHeating
    //   for (let i in this.rateInfo.poolHeating_available) {
    //     poolHeating = this.rateInfo.poolHeating_available[i]
    //     availablePoolHeatingArray.push({
    //       text: poolHeating.currencyCode + '-' + poolHeating.itemName,
    //       value: poolHeating.owner_link_to_item_id
    //     })
    //   }
    //
    //   // console.log(availablePoolHeatingArray)
    //   this.$set(this.propertyRates, 'availablePoolHeatingOptions', availablePoolHeatingArray)
    //   this.$set(this.propertyRates, 'defaultPoolHeating', this.rateInfo.poolHeating_default)
    //   if (this.rateInfo.propertyRate.hasOwnProperty('pool_heating_id') && this.rateInfo.propertyRate.pool_heating_id > 0) {
    //     this.$set(this.propertyRates, 'selectedPoolHeating', this.rateInfo.propertyRate.pool_heating_id)
    //   }
    //
    //   let cleaningFeesArray = [
    //     {
    //       text: 'Default',
    //       value: null
    //     }
    //   ]
    //   let cleaningFee
    //   for (let i in this.rateInfo.cleaningFees_available) {
    //     cleaningFee = this.rateInfo.cleaningFees_available[i]
    //     cleaningFeesArray.push({
    //       text: cleaningFee.currencyCode + '-' + cleaningFee.itemName,
    //       value: cleaningFee.owner_link_to_item_id
    //     })
    //   }
    //
    //   // console.log(cleaningFeesArray)
    //   this.$set(this.propertyRates, 'availableCleaningFeesOptions', cleaningFeesArray)
    //   if (this.rateInfo.propertyRate.hasOwnProperty('cleaning_fees_id') && this.rateInfo.propertyRate.cleaning_fees_id > 0) {
    //     this.$set(this.propertyRates, 'selectedCleaningFee', this.rateInfo.propertyRate.cleaning_fees_id)
    //   }
    //
    //   let seasonSetsArray = [
    //     {
    //       text: 'Default',
    //       value: null
    //     }
    //   ]
    //   let seasonSet
    //   for (let i in this.rateInfo.seasonSets_available) {
    //     seasonSet = this.rateInfo.seasonSets_available[i]
    //     seasonSetsArray.push({
    //       text: seasonSet.itemName,
    //       value: seasonSet.owner_link_to_item_id
    //     })
    //   }
    //   // console.log(seasonSetsArray)
    //   this.$set(this.propertyRates, 'availableSeasonSetsOptions', seasonSetsArray)
    //   if (this.rateInfo.propertyRate.hasOwnProperty('season_sets_id') && this.rateInfo.propertyRate.season_sets_id > 0) {
    //     this.$set(this.propertyRates, 'selectedSeasonSet', this.rateInfo.propertyRate.season_sets_id)
    //   }
    // },

    fetchDefaultRates () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/property-rates/defaults'
      })
        .then(response => {
          const responseData = response.data
          // Set up available currencies
          const availableCurrenciesArray = [
            {
              text: 'Select an option',
              value: '',
              disabled: true
            }
          ]
          let currency
          for (const i in responseData.availableCurrencies) {
            currency = responseData.availableCurrencies[i]
            availableCurrenciesArray.push({
              text: currency.currencyCode,
              value: currency.currencies_id
            })
          }

          this.$set(this, 'availableCurrencies', availableCurrenciesArray)
          if (responseData.ownerDefaults.property_rates) { // returns false if not yet set.
            const defaultRates = responseData.ownerDefaults.property_rates
            const discountTypes = ['late', 'long']
            let available, days, percentage
            discountTypes.forEach(discountType => {
              days = ''
              available = Boolean(defaultRates[discountType + 'DiscountAvailable'])
              percentage = ''
              if (available) {
                days = defaultRates[discountType + 'Days']
                percentage = defaultRates[discountType + 'DiscountPercentage']
              }
              this.$set(this.defaults.discounts, discountType, {
                days,
                available,
                percentage
              })
            })
            this.$set(this.defaults, 'discountPeak', Boolean(defaultRates.discountPeak))
            this.$set(this.defaults, 'currencies_id', defaultRates.currencies_id)
            if (defaultRates.hasOwnProperty('DVMarkupPercentage')) {
              this.$set(this.defaults, 'DVMarkupPercentage', defaultRates.DVMarkupPercentage) // will only be returned on dv_admin logged in account
            }

            // add the minimum bookable nights properties if they are in the response - these will only be editable if we're dvadmin as mcs cannot edit these values
            const minimumBookableNightTypes = ['bookableNightsMin', 'bookableNightsMinPeakInclusive']
            let minimumBookableNightType
            for (const i in minimumBookableNightTypes) {
              minimumBookableNightType = minimumBookableNightTypes[i]
              // copy it from the defaults first and then overide the individual one if its set
              if (defaultRates.hasOwnProperty(minimumBookableNightType)) {
                this.$set(this.defaults, minimumBookableNightType, defaultRates[minimumBookableNightType])
              }
              if (defaultRates.hasOwnProperty(minimumBookableNightType)) {
                this.$set(this.defaults, minimumBookableNightType, defaultRates[minimumBookableNightType])
              }
            }
          }
          this.$set(this, 'validationRules', this.$validationRules(response.data.validationRules))
          // console.log(this.$store.getters.userRole) ## todo <- we should be able to use the store value rather than checking local storage directly
          const userRole = localStorage.getItem('userRole')
          if (userRole === 'MC_OWNER_DV_ADMIN_MASTER') {
            this.isDvAdmin = true
          } else {
            this.isDvAdmin = false
          }
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    checkDefaultRatesForm () {
      // console.log(this.validationRules)
      Promise.all([this.$validator.validate()]).then(values => {
        const validValues = values[0]
        if (!validValues) {
          setTimeout(() => {
            const invalidFields = document.getElementsByClassName('is-invalid')
            this.$scrollTo(invalidFields[0])
          }, 250)
        }
        if (validValues) {
          this.submitDefaultDatesForm() // errors of this function will be caught in this catch method.
        }
      })
        .catch(error => {
          console.log(error)
        })
    },

    submitDefaultDatesForm () {
      this.formSubmitting = true
      this.setLoadingState(true)
      // console.dir(this.defaults)
      const defaultRates = {
        lateDays: 0,
        lateDiscountPercentage: 0,
        lateDiscountAvailable: 0,
        longDays: 0,
        longDiscountPercentage: 0,
        longDiscountAvailable: 0,
        discountPeak: (this.defaults.discountPeak ? 1 : 0),
        currencies_id: this.defaults.currencies_id
      }

      if (this.isDvAdmin) {
        defaultRates.DVMarkupPercentage = this.defaults.DVMarkupPercentage
      }

      // add the minBookableNights fields if we're dvadmin
      if (this.isDvAdmin && this.defaults.hasOwnProperty('bookableNightsMin') && this.defaults.hasOwnProperty('bookableNightsMinPeakInclusive')) {
        defaultRates.bookableNightsMin = this.defaults.bookableNightsMin
        defaultRates.bookableNightsMinPeakInclusive = this.defaults.bookableNightsMinPeakInclusive
      }

      /*
        let propertyRates = {
          pool_heating_id: (this.propertyRates.selectedPoolHeating ? this.propertyRates.selectedPoolHeating : 0),
          cleaning_fees_id: (this.propertyRates.selectedCleaningFee ? this.propertyRates.selectedCleaningFee : 0),
          season_sets_id: (this.propertyRates.selectedSeasonSet ? this.propertyRates.selectedSeasonSet : 0),
        }
        */
      const discountTypes = ['late', 'long']
      let discountType, discountInfo, discountAvailable, discountDays, discountPercentage
      for (const i in discountTypes) {
        discountType = discountTypes[i]
        discountInfo = this.defaults.discounts[discountType]
        discountDays = discountInfo.days
        discountAvailable = discountInfo.available
        discountPercentage = discountInfo.percentage
        if (discountAvailable) { // discount is enabled - overwrite the zeroed values
          defaultRates[discountType + 'Days'] = discountDays
          defaultRates[discountType + 'DiscountPercentage'] = discountPercentage
          defaultRates[discountType + 'DiscountAvailable'] = 1 // we want this as a one - if we use the discountAvailable value - thats true so we hard code a 1 here as thats what the backend database wants
        }
      }
      this.$apiCaller({
        route: '/property-rates/defaults',
        method: 'POST',
        data: defaultRates
      })
        .then(response => {
          const alertMessage = 'These default rates have been saved'
          this.$dialog.alert({ body: alertMessage, title: 'Default rates saved' }, { html: false })
        })
        .finally(() => {
          this.formSubmitting = false
          this.setLoadingState(false)
        })
    }
  },

  data () {
    return {
      isDvAdmin: false,
      loading: true,
      formSubmitting: false,
      availableCurrencies: [],
      //
      //   defaultPoolHeating: {},
      //   availablePoolHeatingOptions: [],
      //   selectedPoolHeating: null,
      //   availableCleaningFeesOptions: [],
      //   selectedCleaningFee: null,
      //   availableSeasonSetsOptions: [],
      //   selectedSeasonSet: null,
      //
      defaults: {
        discounts: {
          late: {
            days: '',
            percentage: '',
            available: false
          },
          long: {
            days: '',
            percentage: '',
            available: false
          }
        },
        discountPeak: true,
        currencies_id: '',
        DVMarkupPercentage: '',
        bookableNightsMin: '',
        bookableNightsMinPeakInclusive: ''
      },
      validationRules: {}
    }
  }
}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/sections/rates/view-edit-rates.scss';
</style>
