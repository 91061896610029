<template>
	<b-container class="login-container">
		<div class="l-c">
			<div class="l-i">
				<b-row align-h="center" align-v="center">
					<b-col cols="12" md="8" lg="6">
						<b-alert :show="alertType === 'AUTH_ERROR'" variant="danger" dismissible fade>
							<h4 class="alert-heading">Oh dear...</h4>
							<p>We could not log you in. Ensure your username and password are correct.</p>
						</b-alert>
            <template v-if="alertType === 'LOGGED_OUT'">
              <b-alert :show="$store.getters.sessionTimedOut" variant="info" dismissible fade>
                <h4>Just so you know...</h4>
                <p>You were inactive for a long period of time. For your security, we automatically logged you out.</p>
                <p><strong>Changes that you made, but did not save, will have been lost.</strong></p>
              </b-alert>
              <b-alert :show="!$store.getters.sessionTimedOut" variant="success" dismissible fade>
                <h4>Logged out</h4>
                <p>You have logged out.</p>
              </b-alert>
          </template>

          <template v-if="alertType === 'FORGOT_PASSWORD' && passwordRequested">
            <b-alert :show="passwordResetSuccessful" variant="success" dismissible fade>
              <h4>Check your email</h4>
              <p>We've sent you an email with further instructions on resetting your password.</p>
            </b-alert>
            <b-alert :show="!passwordResetSuccessful" variant="danger" dismissible fade>
              <h4>Oops!</h4>
              <p>We tried to reset your password, but there was a problem. Make sure the email address is correct or alternatively contact us for assistance.</p>
            </b-alert>
          </template>

						<div class="login-container shadow"
							 v-bind:class="{ loading: showLoginLoader, error: ($store.getters.authStatus === 'error')}">

              <h2>
                <template v-if="!forgotPassword">Log-in to your owner account</template>
                <template v-if="forgotPassword">To reset your password please enter the email address used to register</template>
              </h2>

							<b-form @submit.prevent="onSubmit">
								<b-form-group id="emailAddressGroup">
									<label class="sr-only" for="emailAddress">Email address</label>
									<b-form-input id="emailAddress" type="email"
												  v-model="form.email" required
												  placeholder="Enter email addresss"></b-form-input>
								</b-form-group>

								<b-form-group id="passwordGroup" v-if="(forgotPassword === false)">
									<label class="sr-only" for="password">Password</label>
									<b-form-input id="password" type="password"
												  v-model="form.password" required
												  placeholder="Enter password"></b-form-input>
								</b-form-group>

								<b-row class="mt-4">
									<b-col cols="6">
										<b-link href="#" @click="initiatePasswordRecovery" v-if="!forgotPassword">Forgot password</b-link>
                    <b-link href="#" @click="cancelPasswordRecovery" v-else>Go Back</b-link>
									</b-col>
									<b-col cols="6">
										<div class="text-right">
											<b-button type="submit" variant="primary" v-if="forgotPassword === false">
												Log in
											</b-button>

											<b-button type="submit" variant="primary" v-if="forgotPassword === true" :disabled="passwordResetSuccessful">
												Reset password
											</b-button>
										</div>
									</b-col>
								</b-row>
							</b-form>
						</div>

					</b-col>
				</b-row>
			</div>
		</div>
		<div class="bg"></div>

		<div class="loader-outer">
			<loader v-show="showLoginLoader"></loader>
		</div>
	</b-container>
</template>

<script>
// import {
//   UPDATE_CONFIG
// } from '@/store/actions/config'

import {
  LOG_IN_SUCCESS
} from '@/store/actions/authnew'

import {
  UPDATE_USER_ROLE,
  UPDATE_MC_OWNER_INFO,
  UPDATE_MC_OWNER_ON_BEHALF
} from '@/store/actions/usernew'

import loader from '@/components/global/loader.vue'

export default {
  components: {
    loader
  },

  mounted () {
    // reset values
    // this.forgotPassword = false
    // this.passwordRequested = false
    // this.passwordResetSuccessful = null
    if (this.$store.getters.wasAuthenticated) {
      this.alertType = 'LOGGED_OUT'
    }
  },

  computed: {
    showLoginLoader () {
      // switch (this.$store.getters.authStatus) {
      //   case 'loading':
      //     return true
      //
      //   case 'success':
      //     return false
      //
      //   case 'error':
      //     return false
      //
      //   default:
      //     return false
      // }
      return this.loading
    }
  },

  methods: {
    onSubmit () {
      this.form.user_type = 13 // MC_OWNER
      if (this.form.email.toUpperCase().includes('@DEBBIESVILLAS.CO.UK')) {
        this.form.user_type = 14 // MC_OWNER_DV_ADMIN_MASTER
      }
      this.alertType = ''
      this.showWelcomeMessage = false
      this.loading = true
      this.passwordRequested = false
      if (this.forgotPassword) { // user forgot password
        this.passwordResetSuccessful = null
        this.$apiCaller({ route: '/reset-password', method: 'POST', data: this.form, displayErrorAlert: false }).then(response => {
          this.passwordResetSuccessful = true
        })
          .catch(() => {
            this.passwordResetSuccessful = false
          })
          .finally(() => {
            this.loading = false
            this.alertType = 'FORGOT_PASSWORD'
            this.passwordRequested = true
          })
      } else { // user logging in
        this.$apiCaller({ route: '/login', method: 'POST', data: this.form, displayErrorAlert: false }).then(response => {
          const token = response.data.token
          localStorage.setItem('user-token', token)
          if (response.data.hasOwnProperty('userRole')) {
            localStorage.setItem('userRole', response.data.userRole)
            this.$store.dispatch(UPDATE_USER_ROLE, response.data.userRole)
          }
          if (response.data.hasOwnProperty('mcOwnerInfo')) {
            localStorage.setItem('mcOwnerInfo', JSON.stringify(response.data.mcOwnerInfo))
            this.$store.dispatch(UPDATE_MC_OWNER_INFO, response.data.mcOwnerInfo)
          }
          // if (response.data.hasOwnProperty('config')) {
          //   localStorage.setItem('config', JSON.stringify(response.data.config))
          //   this.$store.dispatch(UPDATE_CONFIG, response.data.config)
          // }
          // if (response.data.hasOwnProperty('accountSignupIncomplete') && response.data.accountSignupIncomplete) {
          // localStorage.setItem('accountSignupIncomplete', 1)
          // }
          // if (response.headers.hasOwnProperty('x-api-user-id')) {
          //   this.$ga.set('userId', response.headers['x-api-user-id']) - $ga = google analytics - not on owner login
          // }

          // Set User Id and email (used by bugsnag to identify users)
          let userId = 0
          if (response.headers.hasOwnProperty('x-api-user-id')) {
            userId = response.headers['x-api-user-id']
          }

          localStorage.setItem('userId', userId)
          localStorage.setItem('userEmail', this.form.email)

          this.$store.dispatch(LOG_IN_SUCCESS)
          let redirectUrl = '/'
          if (this.$store.getters.userRole === 'MC_OWNER_DV_ADMIN_MASTER') {
            this.$store.dispatch(UPDATE_MC_OWNER_ON_BEHALF, null)
            redirectUrl = '/master-admin-select-mc'
          }
          // redirecting to '/' after successful login and then relying on the router guard to redirect to /master-admin-select-mc throws uncaught in promise errors in the console - spent ages trying to make it work that way - but this seems to be the quick fix.
          this.$router.push(redirectUrl) // redirect to homepage
        })
          .catch(() => {
            this.alertType = 'AUTH_ERROR'
            localStorage.removeItem('user-token')/* don't think this is needed here? */
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    initiatePasswordRecovery (evt) {
      evt.preventDefault()
      this.alertType = ''
      this.forgotPassword = true
      this.passwordResetSuccessful = null
      this.passwordRequested = false
    },

    cancelPasswordRecovery (evt) {
      evt.preventDefault()
      this.alertType = ''
      this.forgotPassword = false
    }
  },

  data () {
    return {
      showWelcomeMessage: true,
      loading: false,
      form: {
        email: '',
        password: '',
        user_type: 13 // 'MC_OWNER'
      },
      forgotPassword: false,
      passwordRequested: false,
      passwordResetSuccessful: null,
      alertType: 'WELCOME'
    }
  }

}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/sections/login.scss';
</style>
