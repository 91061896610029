<template>
  <div class="view-edit-rates" v-if="!loading">
    <div class="title-container blue">
      <b-row>
        <b-col cols="12">
          <h1 class="alt">
            <template v-if="$route.params.oltiId === '0'">
              New pool heating tariff
            </template>
            <template v-else>
              Edit pool heating tariff
            </template>
          </h1>
        </b-col>
      </b-row>
    </div>

    <div class="season-container" ref="ratesContainer">
      <div class="pricing pt-0 mt-0">
        <b-form @submit.prevent="saveTariff" autocomplete="off" novalidate>
          <fieldset :disabled="formSubmitting">
            <div class="year-container">
              <h2>{{ tariffTitle }}</h2>
              <b-row>
                <b-col lg="6">
                  <div class="season-price">
                    <label for="itemName">Tariff Name</label>
                    <b-input-group>
                      <b-form-input
                      v-model="poolHeating.itemName"
                      id="itemName"
                      type="text"
                      data-vv-as="Tariff Name"
                      name="itemName"
                      :state="$validateState(veeFields, errors, 'itemName')"
                      :maxlength="validationRules.itemName.max"
                      v-validate="validationRules.itemName"></b-form-input>

                      <b-form-invalid-feedback id="itemName_feedback">
                        <ul style="padding-left: 0; list-style: none;">
                          <li v-for="error in errors.collect('itemName')">{{ error }}</li>
                        </ul>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </b-col>

                <b-col lg="6">
                  <div class="season-price">
                    <label for="tariffCurrency">Currency</label>
                    <b-input-group>
                      <b-form-select
                      v-model="poolHeating.currencies_id"
                      :options="availableCurrencies"
                      data-vv-as="Currency"
                      name="currencies_id"
                      :state="$validateState(veeFields, errors, 'currencies_id')"
                      v-validate="validationRules.currencies_id">
                    </b-form-select>

                    <b-form-invalid-feedback id="currency_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('currencies_id')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <div class="season-price">
                  <label for="tariffSetup">Set Up Fee  <small>(optional)</small></label>
                  <b-input-group>
                    <template #prepend v-if="poolHeating.currencyCode">
                      <b-input-group-text>
                        <span v-html="currencySymbol(poolHeating.currencyCode)"></span>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                    v-model="poolHeating.setupRate"
                    id="setupRate"
                    type="number"
                    name="setupRate"
                    :min="validationRules.setupRate.min_value"
                    :max="validationRules.setupRate.max_value"
                    :state="$validateState(veeFields, errors, 'setupRate')"
                    v-validate="validationRules.setupRate"
                    data-vv-as="Set Up Fee"></b-form-input>

                    <b-form-invalid-feedback id="setupRate_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('setupRate')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>

              <b-col lg="4">
                <div class="season-price">
                  <label for="tariffNightly">Nightly Fee</label>
                  <b-input-group>
                    <template #prepend v-if="poolHeating.currencyCode">
                      <b-input-group-text>
                        <span v-html="currencySymbol(poolHeating.currencyCode)"></span>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                    v-model="poolHeating.nightlyRate"
                    id="nightlyRate"
                    type="number"
                    :min="validationRules.nightlyRate.min_value"
                    :max="validationRules.nightlyRate.max_value"
                    name="nightlyRate"
                    :state="$validateState(veeFields, errors, 'nightlyRate')"
                    v-validate="validationRules.nightlyRate"
                    data-vv-as="Nightly Fee"></b-form-input>

                    <b-form-invalid-feedback id="nightlyRate_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('nightlyRate')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>

              <b-col lg="4">
                <div class="season-price">
                  <label for="minNights">Minimum Nights <small>(to charge)</small></label>
                  <b-input-group>
                    <b-form-input
                    v-model="poolHeating.minNights"
                    id="minNights"
                    type="number"
                    :min="validationRules.minNights.min_value"
                    :max="validationRules.minNights.max_value"
                    name="minNights"
                    :state="$validateState(veeFields, errors, 'minNights')"
                    v-validate="validationRules.minNights"
                    data-vv-as="Minimum Nights"></b-form-input>

                    <b-form-invalid-feedback id="minNights_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('minNights')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>

            <h2 class="mt-4">Long Stay Pool Heating Discount <small>(booking lasts equal to or longer than a number of nights)</small></h2>
            <b-row>
              <b-col lg="4">
                <div class="season-price">
                  <b-form-group label="Long Discount">
                    <b-form-select v-model="poolHeating.longDiscountAvailable" @change="zeroOutDiscountPercentages($event, 'long')">
                      <option :value="true">Enabled</option>
                      <option :value="false">Disabled</option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>

              <b-col lg="4">
                <div class="season-price">
                  <label for="longDays">Long Days</label>
                  <b-input-group>
                    <b-form-input
                    data-vv-as="Long Days"
                    name="long_days"
                    :state="$validateState(veeFields, errors, 'long_days', !poolHeating.longDiscountAvailable)"
                    v-validate="validationRules.longDays"
                    :disabled="!poolHeating.longDiscountAvailable"
                    v-model="poolHeating.longDays"
                    id="longDays"
                    type="number"
                    :min="validationRules.longDays.min_value"
                    :max="validationRules.longDays.max_value"
                    ></b-form-input>
                    <b-form-invalid-feedback id="longDays_feedback">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('long_days')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>

              <b-col lg="4">
                <div class="season-price">
                  <label for="longDiscountPercentage">Long Discount Percentage</label>
                  <b-input-group append="%">
                    <b-form-input
                    data-vv-as="Long Days Discount"
                    name="long_days_discount"
                    :state="$validateState(veeFields, errors, 'long_days_discount', !poolHeating.longDiscountAvailable)"
                    v-validate="validationRules.longDiscountPercentage"
                    :disabled="!poolHeating.longDiscountAvailable"
                    v-model="poolHeating.longDiscountPercentage"
                    id="longDiscountPercentage"
                    type="number"
                    step="0.1"
                    :min="validationRules.longDiscountPercentage.min_value"
                    :max="validationRules.longDiscountPercentage.max_value"
                    ></b-form-input>

                    <b-form-invalid-feedback id="longDiscount_feedback" style="order: 99;">
                      <ul style="padding-left: 0; list-style: none;">
                        <li v-for="error in errors.collect('long_days_discount')">{{ error }}</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="saver">
            <b-row align-h="end">
              <b-col v-if="$route.params.oltiId !== '0'">
                <i>
                  Prices of existing bookings will not be affected.
                </i>
              </b-col>
              <b-col lg="3" v-if="assignedProperties.length < 1 && $route.params.oltiId !== '0'">
                <b-button variant="danger" v-confirm="{
                      ok: dialog => deleteTariff(),
                      message: 'This will delete this pool heating tariff - are you sure?',
                      okText: 'Yes',
                      cancelText: 'No'
                  }">Delete</b-button>
              </b-col>
              <b-col lg="3" v-else>
                <small v-if="$route.params.oltiId !== '0'">Tariff cannot be deleted as it has properties assigned</small>
              </b-col>
              <b-col lg="3" class="mt-4 mt-lg-0" v-if="$route.params.oltiId === '0'">
                <b-button variant="info" @click="$router.go(-1)">Cancel</b-button>
              </b-col>
              <b-col lg="3" class="mt-4 mt-lg-0">
                <b-button variant="primary" type="submit" :disabled="JSON.stringify(poolHeating) === poolHeatingDiff">
                  <template v-if="$route.params.oltiId === '0'">Create Tariff</template>
                  <template v-else>Update</template>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </fieldset>
      </b-form>
    </div>
  </div>

  <property-list
    v-if="$route.params.oltiId !== '0'"
    noPropertiesMessage="This tariff does not have any properties assigned to it."
    :properties="assignedProperties">
  </property-list>
  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import propertyList from '../../global/miniPropertyList.vue'
import loader from '@/components/global/loader'
export default {
  components: {
    'property-list': propertyList,
    loader
  },

  mounted () {
    this.fetchPoolHeating()
  },

  methods: {
    zeroOutDiscountPercentages (enabled, discountPrefix) {
      if (!enabled) {
        this.poolHeating[discountPrefix + 'Days'] = ''
        this.poolHeating[discountPrefix + 'DiscountPercentage'] = ''
      }
    },

    deleteTariff () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/pool-heating/' + this.$route.params.oltiId,
        method: 'DELETE'
      })
        .then(response => {
          this.$router.push({ path: '/pool-heating' })
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    saveTariff () {
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.formSubmitting = true
          this.setLoadingState(true)
          const poolHeating = {
            itemName: this.poolHeating.itemName,
            currencies_id: this.poolHeating.currencies_id,
            setupRate: this.poolHeating.setupRate,
            nightlyRate: this.poolHeating.nightlyRate,
            minNights: this.poolHeating.minNights,
            longDays: 0,
            longDiscountPercentage: 0,
            longDiscountAvailable: 0
          }
          if (!poolHeating.setupRate) {
            poolHeating.setupRate = 0
          }
          if (this.poolHeating.longDiscountAvailable) {
            poolHeating.longDays = this.poolHeating.longDays
            poolHeating.longDiscountPercentage = this.poolHeating.longDiscountPercentage
            poolHeating.longDiscountAvailable = 1
          }
          this.$apiCaller({
            route: '/pricing-service/pool-heating/' + this.$route.params.oltiId,
            method: 'POST',
            data: poolHeating
          })
            .then(response => {
              const alertMessage = 'This pool heating tariff has been saved'
              this.$dialog.alert({ body: alertMessage, title: 'Pool heating tariff saved' }, { html: false })
            })
            .finally(() => {
              this.formSubmitting = false
              this.setLoadingState(false)
              if (this.$route.params.oltiId === '0') {
                // this is a new one created - we navigate back to the feed else the oltiId is incorrect
                this.$router.push({ path: '/pool-heating' })
              }
            })
        } else {
          const invalidFields = document.getElementsByClassName('is-invalid')
          this.$scrollTo(invalidFields[0])
        }
      })
    },

    fetchPoolHeating () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/pool-heating/' + this.$route.params.oltiId
      })
        .then(response => {
          // change setup rate to not be required - its populated with a 0 if not filled out
          response.data.validationRules.setupRate = response.data.validationRules.setupRate.replace('required|', '')
          this.$set(this, 'validationRules', this.$validationRules(response.data.validationRules))

          // Set up available currencies
          const availableCurrenciesArray = [
            {
              text: 'Select an option',
              value: null,
              disabled: true
            }
          ]
          response.data.availableCurrencies.forEach(availableCurrency => {
            availableCurrenciesArray.push({
              text: availableCurrency.currencyCode,
              value: availableCurrency.currencies_id
            })
          })
          this.availableCurrencies = availableCurrenciesArray
          //
          if (parseInt(this.$route.params.oltiId) !== 0) {
            this.$set(this.poolHeating, 'currencies_id', response.data.item.currencies_id)
            this.$set(this.poolHeating, 'itemName', response.data.item.itemName)
            this.$set(this.poolHeating, 'setupRate', response.data.item.setupRate)
            this.$set(this.poolHeating, 'nightlyRate', response.data.item.nightlyRate)
            this.$set(this.poolHeating, 'minNights', response.data.item.minNights)
            this.$set(this.poolHeating, 'longDiscountAvailable', Boolean(response.data.item.longDiscountAvailable))
            let days = ''
            let percentage = ''
            if (this.poolHeating.longDiscountAvailable) {
              days = response.data.item.longDays
              percentage = response.data.item.longDiscountPercentage
            }
            this.$set(this.poolHeating, 'longDays', days)
            this.$set(this.poolHeating, 'longDiscountPercentage', percentage)
            const assignedProperties = []
            if (response.data.assignedTo.hasOwnProperty('property') && response.data.hasOwnProperty('assignedPropertiesInfo')) {
              response.data.assignedTo.property.forEach(propertyId => {
                if (response.data.assignedPropertiesInfo.hasOwnProperty(propertyId)) {
                  assignedProperties.push(response.data.assignedPropertiesInfo[propertyId])
                }
              })
            }
            this.assignedProperties = assignedProperties
          //   this.poolHeatingDiff = JSON.stringify(this.poolHeating)
          }
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },

  watch: {
    'poolHeating.currencies_id': {
      handler: function (newVal) {
        for (let i = 0; i < this.availableCurrencies.length; i++) {
          if (this.availableCurrencies[i].value === newVal) {
            this.poolHeating.currencyCode = this.availableCurrencies[i].text
            break
          }
        }
      }
    }
  },

  computed: {

    tariffTitle () {
      let poolHeatingTitle = ''

      if (this.poolHeating.itemName === null || this.poolHeating.currencyCode === null || this.poolHeating.setupRate === null || this.poolHeating.nightlyRate === null || this.poolHeating.minNights === null) {
        poolHeatingTitle = 'Pool heating tariff'
      } else {
        poolHeatingTitle = this.poolHeating.itemName
      }

      return poolHeatingTitle
    }
  },

  data () {
    return {
      loading: true,
      formSubmitting: false,
      contentLoaded: false,
      poolHeating: {
        currencies_id: null,
        currencyCode: '',
        itemName: null,
        nightlyRate: null,
        setupRate: null,
        minNights: null,
        longDays: '',
        longDiscountPercentage: '',
        longDiscountAvailable: false
      },

      assignedProperties: [],

      validationRules: {},

      poolHeatingDiff: '',

      availableCurrencies: []
    }
  }
}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/sections/rates/view-edit-rates.scss';
</style>
