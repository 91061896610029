<template>
  <div id="app">
    <!-- <div class="header">
      Header stuff goes here, if applicable
    </div> -->

    <router-view></router-view>

    <!--<div class="footer">
      Footer stuff goes here, if applicable
    </div>-->

    <b-modal ref="logoutWarning" id="logoutWarning" size="lg" title="Are you still there?" @hide="modalEvents" centered hide-header-close cancel-title="Log out" ok-title="Keep me logged in">
      <b-container fluid>
        <b-row>
          <b-col>
            <p>You have been inactive for a little while, and will be logged out.</p>
            <p>
              <strong>Any unsaved changes in your account will be lost.</strong><br/>If you need more time, click <strong>keep me logged in</strong>.
            </p>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col cols="auto">
            <div class="seconds-remaining">
              <div class="inner">
                {{ sessionTimeHMS }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-progress
              height="2rem"
              :value="parseInt(modalWarningThreshold, 10) - parseInt(sessionTimeRemaining, 10)"
              :max="parseInt(modalWarningThreshold, 10)"
              class="mb-2"
              variant="danger"></b-progress>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import {
  LOG_OUT,
  LOG_IN_SUCCESS
} from '@/store/actions/authnew'
import {
  UPDATE_USER_ROLE,
  UPDATE_MC_OWNER_INFO,
  UPDATE_MC_OWNER_ON_BEHALF
} from '@/store/actions/usernew'
import {
  MODAL_WARNING_THRESHOLD
} from '@/store/actions/config'
import moment from 'moment'
import { routesNotRedirectedToLogin } from './variables.js'

export default {
  name: 'app',

  timers: {
    sessionCountDown: {
      time: 1000,
      repeat: true,
      autostart: true
    }
  },

  created () {
    // these followiong 2 lines disable the invalid browser from triggering - as if we get here this browser we are using can handle a vue app - perhaps their may be other features that dont work though?
    document.getElementById('warningMess').classList.remove('vue-error')
    document.getElementById('loading').classList.add('hidden')

    if (localStorage.hasOwnProperty('userRole')) {
      // update user role from values last stored in localStorage
      this.$store.dispatch(UPDATE_USER_ROLE, localStorage.getItem('userRole'))
    }

    if (localStorage.hasOwnProperty('mcOwnerInfo')) {
      // update UPDATE_MC_OWNER_INFO from values last stored in localStorage
      this.$store.dispatch(UPDATE_MC_OWNER_INFO, JSON.parse(localStorage.getItem('mcOwnerInfo')))
    }

    if (localStorage.hasOwnProperty('MC_OWNER_ON_BEHALF')) {
      const mcOwnerOnBehalfJson = JSON.parse(localStorage.getItem('MC_OWNER_ON_BEHALF'))
      this.$store.dispatch(UPDATE_MC_OWNER_ON_BEHALF, mcOwnerOnBehalfJson.mcCodeAndName)
    }
  },

  computed: {
    modalTime () {
      return this.$store.getters.getWarningThreshold
    },

    sessionTimeHMS () {
      return new Date(this.sessionTimeRemaining * 1000).toISOString().substr(14, 5)
    },

    modalWarningThreshold () {
      return MODAL_WARNING_THRESHOLD
    }
  },

  methods: {
    openModal () {
      this.$refs.logoutWarning.show()
    },

    sessionCountDown () {
      let i
      for (i in routesNotRedirectedToLogin) {
        if (this.$route.fullPath.indexOf(routesNotRedirectedToLogin[i]) > -1) {
          return
        }
      }

      if (this.$store.getters.isAuthenticated) {
        if (localStorage.hasOwnProperty('user-token')) {
          this.sessionTimeRemaining = moment(localStorage.getItem('session-expires'), 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'seconds')
          const now = moment()
          if (now > moment(localStorage.getItem('session-expires'), 'YYYY-MM-DD HH:mm:ss')) {
            this.$refs.logoutWarning.hide()
            this.$store.dispatch(LOG_OUT, { timedOut: true })
          } else {
            if (now >= moment(localStorage.getItem('logout-warning-opens'), 'YYYY-MM-DD HH:mm:ss')) {
              this.$refs.logoutWarning.show()
            } else {
              this.$refs.logoutWarning.hide()
            }
          }
        } else {
          this.$refs.logoutWarning.hide()
          // probably logged out from another window
          this.logout()
        }
      } else {
        if (localStorage.hasOwnProperty('user-token')) {
          // probably logged in from another window
          this.$store.dispatch(LOG_IN_SUCCESS)
          return
        }

        this.sessionTimeRemaining = 0

        if (!this.$route.fullPath.includes('/login')) {
          this.$router.push('/login')
        }
      }
    },

    logout () {
      this.$store.dispatch(LOG_OUT, { timedOut: false })
    },

    modalEvents (evt) {
      switch (evt.trigger) {
        case 'backdrop': // modal backdrop clicked
          this.renewSession()
          break
        case 'ok': // keep me logged in
          this.renewSession()
          break
        case 'esc': // escape key pressed
          this.renewSession()
          break
        case 'cancel': // log out button
          this.logout()
          break
      }
    },

    renewSession () {
      this.$timer.stop('sessionCountDown')
      this.$refs.logoutWarning.hide('renew-session')
      this.$apiCaller({ route: '/extend-session', method: 'GET', data: '' }).then(response => {

      })
        .finally(() => {
          this.$timer.start('sessionCountDown')
        })
    }
  },

  data () {
    return {
      sessionTimeRemaining: 0
    }
  }
}
</script>

<style lang="scss">
  @import "./assets/styles/_variables";
  @import './node_modules/bootstrap/scss/bootstrap.scss';
  @import "./assets/styles/global";
</style>
