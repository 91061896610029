<template>
  <div class="assigned-villas">
    <h3>Assigned Properties:</h3>
    <ul v-if="hasProperties">
      <li v-for="property in properties" :key="property">{{ property }}</li>
    </ul>
    <template v-else>
      <i>{{ errorMessage }}</i>
    </template>
  </div>
</template>

<script>
export default {
  name: 'property-list',

  props: {
    properties: {
      type: Array,
      required: false
    },

    noPropertiesMessage: {
      type: String,
      required: false
    }
  },

  computed: {
    errorMessage () {
      let message = 'There are no properties in this list.'

      if (this.noPropertiesMessage) {
        if (this.noPropertiesMessage.replace(/\s+/g, '') !== '') {
          message = this.noPropertiesMessage
        }
      }

      return message
    },

    hasProperties () {
      let returnVal = false

      if (this.properties) {
        if (this.properties.length > 0) {
          returnVal = true
        }
      }

      return returnVal
    }
  },

  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/styles/_variables.scss';
@import './node_modules/bootstrap/scss/bootstrap.scss';

.assigned-villas {
  background: $gray-200;
  padding: $grid-gutter-width;
  margin-top: $grid-gutter-width;

  h3 {
    font-size: 1.1em;
    padding-bottom: $grid-gutter-width / 2;
    margin: 0;
  }

  ul {
    padding: 0;
    width: 100%;
    list-style: none;
    margin: 0;

    li {
      padding-top: $grid-gutter-width / 2;
      padding-bottom: $grid-gutter-width / 2;
      border-top: 1px solid $gray-500;

      a {
        color: inherit;
        text-decoration: none;
      }

      &:nth-last-of-type(-n+2) {
        border-bottom: 1px solid $gray-500;
      }

      strong {
        color: $light-blue;
      }
    }
  }
}
</style>
