import Vue from 'vue'
import Router from 'vue-router'

import Main from '@/components/Main'
import SectionIndex from '@/components/logged-in/SectionIndex'
import NotFound from '@/components/PageNotFound'

// import LoginView from '@/components/Login'
import LoginView from '@/components/Login'

// import Dashboard from '@/components/logged-in/dashboard'
// const Dashboard = () => ({
//   component: import('@/components/logged-in/dashboard'),
//   loading: loader
// })

// import Bookings from '@/components/logged-in/bookings/bookings'
// const Bookings = () => ({
//   component: import('@/components/logged-in/bookings/bookings'),
//   loading: loader
// })
//
// // import RecentBookingsFeed from '@/components/logged-in/bookings/RecentBookingsFeed'
// const RecentBookingsFeed = () => ({
//   component: import('@/components/logged-in/bookings/RecentBookingsFeed'),
//   loading: loader
// })
//
// // import NewBooking from '@/components/logged-in/bookings/NewBooking'
// const NewBooking = () => ({
//   component: import('@/components/logged-in/bookings/NewBooking'),
//   loading: loader
// })
//
//
// // import Availability from '@/components/logged-in/bookings/availability'
// const Availability = () => ({
//   component: import('@/components/logged-in/bookings/availability'),
//   loading: loader
// })

// import RatesFeed from '@/components/logged-in/rates/RatesFeed'
import RatesFeed from '@/components/logged-in/rates/RatesFeed'

import RatesMassUpdate from '@/components/logged-in/rates/MassUpdate'

// import ViewEditRates from '@/components/logged-in/rates/ViewEditRates'
import RatesViewEdit from '@/components/logged-in/rates/RatesViewEdit'
import RatesDefault from '@/components/logged-in/rates/RatesDefault'

import CleaningFeesFeed from '@/components/logged-in/rates/CleaningFeesFeed'

import CleaningFeesViewEdit from '@/components/logged-in/rates/CleaningFeesViewEdit'

import PoolHeatingFeed from '@/components/logged-in/rates/PoolHeatingFeed'

import PoolHeatingViewEdit from '@/components/logged-in/rates/PoolHeatingViewEdit'

import DiscountPeriodsFeed from '@/components/logged-in/rates/DiscountPeriodsFeed'

import DiscountPeriodsViewEdit from '@/components/logged-in/rates/DiscountPeriodsViewEdit'

import SeasonSetsFeed from '@/components/logged-in/rates/SeasonSetsFeed'

import SeasonSetsVewEdit from '@/components/logged-in/rates/SeasonSetsVewEdit'

import Availability from '@/components/logged-in/bookings/Availability'
import VillaAvailabilityFeed from '@/components/logged-in/bookings/VillaAvailabilityFeed'

// const RankingsFeed = () => ({
//   component: import('@/components/logged-in/rates/RankingsFeed'),
//   loading: loader
// })

// const VillaRankings = () => ({
//   component: import('@/components/logged-in/rates/Rankings'),
//   loading: loader
// })

// // import VillaFeed from '@/components/logged-in/villas/VillaFeed'
// const VillaFeed = () => ({
//   component: import('@/components/logged-in/villas/VillaFeed'),
//   loading: loader
// })

// // import NewVilla from '@/components/logged-in/villas/NewVilla'
// const NewVilla = () => ({
//   component: import('@/components/logged-in/villas/NewVilla'),
//   loading: loader
// })

// // import NewVillaTestimonial from '@/components/logged-in/villas/NewTestimonial'
// const NewVillaTestimonial = () => ({
//   component: import('@/components/logged-in/villas/NewTestimonial'),
//   loading: loader
// })
//
// // import VillaInfo from '@/components/logged-in/villas/VillaInfo'
// const VillaInfo = () => ({
//   component: import('@/components/logged-in/villas/VillaInfo'),
//   loading: loader
// })

// // import FinanceFeed from '@/components/logged-in/finances/FinanceFeed'
// const FinanceFeed = () => ({
//   component: import('@/components/logged-in/finances/FinanceFeed'),
//   loading: loader
// })
//
// // import PaymentFeed from '@/components/logged-in/finances/PaymentFeed'
// const PaymentFeed = () => ({
//   component: import('@/components/logged-in/finances/PaymentFeed'),
//   loading: loader
// })
//
// // import VillaFinances from '@/components/logged-in/finances/VillaFinances'
// const VillaFinances = () => ({
//   component: import('@/components/logged-in/finances/VillaFinances'),
//   loading: loader
// })
//
// // import PastPayments from '@/components/logged-in/finances/PastPayments'
// const PastPayments = () => ({
//   component: import('@/components/logged-in/finances/PastPayments'),
//   loading: loader
// })

// const MessageInbox = () => ({
//   component: import('@/components/logged-in/messages/Inbox'),
//   loading: loader
// })
//
// // import MessageThread from '@/components/logged-in/messages/Thread'
// const MessageThread = () => ({
//   component: import('@/components/logged-in/messages/Thread'),
//   loading: loader
// })
//
// // import NewMessage from '@/components/logged-in/messages/NewMessage'
// const NewMessage = () => ({
//   component: import('@/components/logged-in/messages/NewMessage'),
//   loading: loader
// })

// import Account from '@/components/logged-in/account/Account'

import masterAdminSelectMc from '@/components/logged-in/masterAdminSelectMc'
import Welcome from '@/components/logged-in/Welcome'
import accountResetPassword from '@/components/accountResetPassword'

import maintenance from '@/components/maintenance'

Vue.use(Router)

// window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
//   console.log(promiseRejectionEvent)
//     // handle error here, for example log
// })

// Meta item description:
// - breadcrumb: (STRING) show in breadcrumbs [default: false]
// - breadcrumbTitle: (STRING) Title of breadcrumb item [default, if not specified: route name]
// - breadcrumbAjax: (BOOL) to update the :id param with breadcrumbs via AJAX [default: false]
// - showInIndex: (BOOL) show this item in the index [default: true]
// - showIndex: (BOOL) show section index at this level [default: inherits from parent]
// - indexIcon: (STRING) icon to use on the index page

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login', // :asDVMaster(as-dv-master)?',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/',
      name: 'Main', // do not rename as the navigation is calculated from the children of this route - and looked up by name
      component: Main,
      // redirect: 'rates', // dashboard
      children: [
        {
          path: '',
          name: 'Welcome',
          component: Welcome,
          meta: {
            showInNav: false
          }
        },
        {
          path: '/master-admin-select-mc',
          name: 'Master Admin Select MC',
          component: masterAdminSelectMc,
          meta: {
            showInNav: true,
            userRoles: ['MC_OWNER_DV_ADMIN_MASTER']
          }
        },
        {
          path: '/rates/feed',
          name: 'All Villa Rates',
          component: RatesFeed
        },
        {
          path: '/rates/rates-defaults',
          name: 'Default Rates',
          component: RatesDefault
          // meta: {
          //   breadcrumb: true,
          //   description: 'Default rates'
          // }
        },
        {
          path: '/rates/rates-mass-update',
          name: 'Mass update rates',
          component: RatesMassUpdate
          // meta: {
          //   breadcrumb: true,
          //   description: 'Mass update rates'
          // }
        },
        {
          path: '/rates/:propertyId',
          name: 'Villa Rate',
          component: RatesViewEdit,
          meta: {
            showInNav: false
          }
          // meta: {
          //   breadcrumb: true,
          //   showIndex: false,
          //   showInIndex: false,
          //   description: 'Villa rates'
          //   // breadCrumbAjax: true
          // }
        },
        {
          path: '/season-sets',
          name: 'Season Sets',
          component: SeasonSetsFeed
          // meta: {
          //   breadcrumb: true,
          //   description: 'Season Sets'
          // }
        },
        {
          path: '/season-sets/:oltiId',
          name: 'Season set',
          component: SeasonSetsVewEdit,
          meta: {
            showInNav: false
          }
          // meta: {
          //   breadcrumb: true,
          //   description: 'Season Set'
          // }
        },
        {
          path: '/discount-periods',
          name: 'Discount Periods',
          component: DiscountPeriodsFeed
          // meta: {
          //   breadcrumb: true,
          //   description: 'Discount Periods'
          // }
        },
        {
          path: '/discount-periods/:oltiId',
          name: 'Discount Period',
          component: DiscountPeriodsViewEdit,
          meta: {
            showInNav: false
          }
          // meta: {
          //   breadcrumb: true,
          //   description: 'Discount Period',
          //   showInIndex: false
          // }
        },
        {
          path: '/pool-heating',
          name: 'Pool Heating Tariffs',
          component: PoolHeatingFeed
          // meta: {
          //   breadcrumb: true,
          //   showIndex: false,
          //   description: 'Pool Heating'
          // }
        },
        {
          path: '/pool-heating/:oltiId',
          name: 'Pool heating tariff',
          component: PoolHeatingViewEdit,
          meta: {
            showInNav: false
          }
          // meta: {
          //   breadcrumb: true,
          //   description: 'Pool heating rates',
          //   showInIndex: false
          // }
        },
        {
          path: 'cleaning-fees',
          name: 'Cleaning Fees',
          component: CleaningFeesFeed
          // meta: {
          //   breadcrumb: true,
          //   showIndex: false,
          //   description: 'Cleaning Fees'
          // }
        },
        {
          path: 'cleaning-fees/:oltiId',
          name: 'Cleaning Fees tariff',
          component: CleaningFeesViewEdit,
          meta: {
            showInNav: false
          }
          // meta: {
          //   breadcrumb: true,
          //   description: 'Cleaning fee rates',
          //   showInIndex: false
          // }
        },
        // {
        //   path: 'dashboard',
        //   name: 'Dashboard',
        //   component: Dashboard,
        //   meta: {
        //     description: 'Main entry point',
        //     breadcrumb: true,
        //     breadcrumbTitle: 'Welcome to your owner account'
        //   }
        // },
        {
          path: 'bookings-availability',
          // Uncomment this if you need the section index
          // Currently redirecting to villa availability as that's the only thing in this section
          redirect: 'bookings-availability/availability',
          name: 'Availability',
          component: SectionIndex,
          meta: {
            description: 'Bookings & Availability',
            indexIcon: 'homes',
            breadcrumb: true,
            showIndex: true,
            dashboardGroups: [
              {
                items: [
                  {
                    name: 'Availability',
                    subpath: '/availability'
                  }
                ]
              }
            ]
          },
          children: [
            // {
            //   path: 'bookings',
            //   name: 'Bookings',
            //   component: SectionIndex,
            //   meta: {
            //     description: 'Bookings',
            //     breadcrumb: true,
            //     showIndex: true
            //   },
            //   children: [
            //     {
            //       path: 'feed',
            //       name: 'Recent villa bookings',
            //       component: RecentBookingsFeed,
            //       meta: {
            //         description: 'Booking feed',
            //         breadcrumb: true,
            //         indexIcon: 'homes',
            //         showIndex: false
            //       }
            //     },
            //     {
            //       path: 'view',
            //       name: 'All villa bookings',
            //       component: Bookings,
            //       meta: {
            //         description: 'Full bookings list',
            //         breadcrumb: true,
            //         indexIcon: 'calendar',
            //         showIndex: false
            //       },
            //       children: [
            //         {
            //           path: ':ownerId/:recordId',
            //           name: 'Villa bookings by ID',
            //           component: Bookings,
            //           meta: {
            //             description: 'Bookings list',
            //             breadcrumb: true,
            //             breadcrumbTitle: 'Villa bookings list'
            //           }
            //         }
            //       ]
            //     },
            //     {
            //       path: 'add',
            //       name: 'Add a booking',
            //       component: NewBooking,
            //       meta: {
            //         description: 'New booking',
            //         breadcrumb: true,
            //         indexIcon: 'home',
            //         showIndex: false
            //       }
            //     }
            //   ]
            // },
            {
              path: 'availability',
              name: 'Availability',
              component: VillaAvailabilityFeed,
              meta: {
                description: 'Availability'
              },
              children: [
                {
                  path: ':villaId',
                  name: 'Villa availability',
                  component: Availability,
                  meta: {
                    description: 'Availability for a specific villa'
                  }
                }
              ]
            }
          ]
        },
        //
        // {
        //   path: 'rates',
        //   name: 'Rates',
        //   component: SectionIndex,
        //   meta: {
        //     description: 'Rates',
        //     breadcrumb: true,
        //     showIndex: true,
        //     dashboardGroups: [
        //       {
        //         items: [
        //           {
        //             name: 'All villa rates',
        //             subpath: '/feed'
        //           },
        //           {
        //             name: 'Mass Update Rates',
        //             subpath: '/rates-mass-update'
        //           },
        //           {
        //             name: 'Defaults',
        //             subpath: '/rates-defaults'
        //           }
        //         ]
        //       },
        //       {
        //         items: [
        //           {
        //             name: 'Season sets',
        //             subpath: '/season-sets'
        //           },
        //           {
        //             name: 'Discount periods',
        //             subpath: '/discount-periods'
        //           }
        //         ]
        //       },
        //       {
        //         items: [
        //           {
        //             name: 'Pool heating tariffs',
        //             subpath: '/pool-heating'
        //           },
        //           {
        //             name: 'Cleaning fees',
        //             subpath: '/cleaning-fees'
        //           }
        //         ]
        //       }
        //     ]
        //   },
        // children: [
        //   {
        //     path: 'feed',
        //     name: 'All Villa Rates',
        //     component: RatesFeed,
        //     meta: {
        //       breadcrumb: true,
        //       description: 'All Villa Rates'
        //     }
        //   },
        // {
        //   path: 'rates-mass-update',
        //   name: 'Mass update rates',
        //   component: RatesMassUpdate,
        //   meta: {
        //     breadcrumb: true,
        //     description: 'Mass update rates'
        //   }
        // },
        // {
        //   path: 'rates-defaults',
        //   name: 'Default Rates',
        //   component: RatesDefault,
        //   meta: {
        //     breadcrumb: true,
        //     description: 'Default rates'
        //   }
        // },
        // {
        //   path: 'rankings',
        //   name: 'All Villa Rankings',
        //   component: RankingsFeed,
        //   meta: {
        //     breadcrumb: false,
        //     description: 'Villa rankings & price experimenter'
        //   },
        //   children: [
        //     {
        //       path: ':ownerId/:recordId',
        //       name: 'Villa Ranking',
        //       component: VillaRankings,
        //       meta: {
        //         breadcrumb: true,
        //         description: 'Villa rankings & price experimenter'
        //       }
        //     }
        //   ]
        // },

        // ]
        // }
        // {
        //   path: 'villas',
        //   name: 'Your Villas',
        //   component: SectionIndex,
        //   meta: {
        //     description: 'Your Villas',
        //     breadcrumb: true,
        //     showIndex: true
        //   },
        //   children: [
        //     {
        //       path: 'feed',
        //       alias: 'info',
        //       name: 'Villas',
        //       component: VillaFeed,
        //       meta: {
        //         description: 'View villa statuses',
        //         breadcrumb: true
        //       },
        //       children: [
        //         {
        //           path: ':ownerId/:recordId',
        //           name: 'Villa Info',
        //           component: VillaInfo,
        //           meta: {
        //             description: 'Villa information',
        //             breadcrumb: true,
        //             breadCrumbAjax: true
        //           }
        //         }
        //       ]
        //     },
        //     {
        //       path: 'new',
        //       name: 'New Villa',
        //       component: NewVilla,
        //       meta: {
        //         description: 'Add a new villa',
        //         breadcrumb: true
        //       }
        //     },
        //     {
        //       path: 'testimonials',
        //       name: 'Villa Testimonials',
        //       redirect: 'testimonials/new',
        //       component: SectionIndex,
        //       meta: {
        //         description: 'Villa Testimonials',
        //         breadcrumb: true,
        //         showIndex: false
        //       },
        //       children: [
        //         {
        //           path: 'new',
        //           name: 'New Villa Testimonial',
        //           component: NewVillaTestimonial,
        //           meta: {
        //             description: 'Add a new testimonial',
        //             breadcrumb: true
        //           }
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   path: 'finances',
        //   name: 'Finances',
        //   component: SectionIndex,
        //   meta: {
        //     description: 'Finances',
        //     breadcrumb: true,
        //     showIndex: true
        //   },
        //   children: [
        //     {
        //       path: 'feed',
        //       alias: 'view',
        //       name: 'View Finances',
        //       component: FinanceFeed,
        //       meta: {
        //         description: 'View finances',
        //         breadcrumb: true
        //       },
        //       children: [
        //         {
        //           path: ':ownerId/:recordId',
        //           name: 'Villa finances',
        //           component: VillaFinances,
        //           meta: {
        //             description: 'Villa finances',
        //             breadcrumb: true,
        //             breadCrumbAjax: true
        //           }
        //         }
        //       ]
        //     },
        //     {
        //       path: 'payments',
        //       name: 'Past Payments',
        //       component: PaymentFeed,
        //       meta: {
        //         description: 'View past payments',
        //         breadcrumb: true
        //       },
        //       children: [
        //         {
        //           path: ':ownerId/:recordId',
        //           name: 'Past payments for a villa',
        //           component: PastPayments,
        //           meta: {
        //             description: 'Past payments',
        //             breadcrumb: true,
        //             breadCrumbAjax: true
        //           }
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   path: 'messages',
        //   name: 'Messages',
        //   alias: 'feedback',
        //   component: SectionIndex,
        //   meta: {
        //     description: 'Messages',
        //     breadcrumb: true,
        //     showIndex: true
        //   },
        //   children: [
        //     {
        //       path: 'inbox',
        //       name: 'Inbox',
        //       component: MessageInbox,
        //       meta: {
        //         description: 'Inbox',
        //         breadcrumb: true
        //       }
        //     },
        //     {
        //       path: 'new',
        //       name: 'New message',
        //       component: NewMessage,
        //       meta: {
        //         description: 'New message',
        //         breadcrumb: true
        //       }
        //     },
        //     {
        //       path: ':ownerId/:recordId',
        //       name: 'Read message',
        //       component: MessageThread,
        //       meta: {
        //         description: 'Read message',
        //         breadcrumb: true,
        //         showInIndex: false
        //       }
        //     }
        //   ]
        // },
        // {
        //   path: 'account',
        //   name: 'Your account',
        //   component: Account,
        //   meta: {
        //     breadcrumb: true,
        //     description: 'Edit your account information'
        //   }
        // }
        {
          path: '/account/password-reset/:apiUserId/:token/:apiUserPasswordResetId',
          component: accountResetPassword,
          meta: {
            showInNav: false
          }
        }
      ]
    },
    {
      path: '/maintenance',
      component: maintenance,
      meta: {
        showInNav: false
      }
    },
    {
      path: '*',
      component: NotFound
    }
  ]
})
