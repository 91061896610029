import {
  LOG_IN_SUCCESS,
  SET_WAS_AUTHENTICATED,
  SET_IS_AUTHENTICATED,
  LOG_OUT,
  SET_SESSION_TIMED_OUT
} from '@/store/actions/authnew'

import router from '@/router'

const state = {
  isAuthenticated: false,
  sessionTimedOut: false,
  wasAuthenticated: false
}

const getters = {
  isAuthenticated: state => !!state.isAuthenticated,
  wasAuthenticated: state => !!state.wasAuthenticated,
  sessionTimedOut: state => !!state.sessionTimedOut
}

const actions = {
  [LOG_IN_SUCCESS]: ({ commit }) => {
    commit(SET_IS_AUTHENTICATED, true)
    commit(SET_WAS_AUTHENTICATED, true)
  },
  [LOG_OUT]: ({ commit }, { timedOut }) => {
    commit(SET_SESSION_TIMED_OUT, (!!timedOut))
    commit(SET_IS_AUTHENTICATED, false)
    const loginUrl = '/login'
    localStorage.clear()
    router.push(loginUrl)
  }
}

const mutations = {
  [SET_IS_AUTHENTICATED]: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated
  },
  [SET_WAS_AUTHENTICATED]: (state, wasAuthenticated) => {
    state.wasAuthenticated = wasAuthenticated
  },
  [SET_SESSION_TIMED_OUT]: (state, timedOut) => {
    state.sessionTimedOut = timedOut
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
