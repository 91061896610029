<template>
  <router-view v-if="villaID"></router-view>
  <div v-else>
    <template v-if="!loading">
      <div class="villa-feed">
        <div class="feed-container">
          <div class="title-container">
            <b-row>
              <b-col cols="12">
                <h1>{{ $route.name }}</h1>
              </b-col>
            </b-row>
          </div>
          <div id="filterBar" style="margin-bottom:20px;">
            <b-row>
              <b-col cols="12" lg="4">
                <b-form inline>
                  <b-input-group size="sm" style="width:100%;" class="mt-2 mt-lg-0">
                    <label for="bedroomFilterSelect" class="mr-3">Filter:</label>
                    <b-form-select :options="bedroomFilterOptions" v-model="bedroomFilterSelected"
                      id="bedroomFilterSelect">
                    </b-form-select>
                  </b-input-group>
                </b-form>
              </b-col>
              <b-col cols="12" lg="4">
                <b-form inline>
                  <b-input-group size="sm" style="width:100%;" class="mt-2 mt-lg-0">
                    <label for="textFilter" class="mr-3">Search:</label>
                    <b-form-input v-model="searchTextFilter" placeholder="Search" debounce="300"
                      id="textFilter"></b-form-input>
                    <template v-slot:append v-if="searchTextFilter.length">
                      <b-button @click="resetSearchTextFilter">✕</b-button>
                    </template>
                  </b-input-group>
                </b-form>
              </b-col>
            </b-row>
          </div>
          <div class="feed">
            <feed-item :imgSrc="villa.coverImage.imgUrl" :villaCode="villa.villaCode" :villaId="villa.villaId"
              :villaAddress="villa.address1" :paused="villa.paused" :unbookable="villa.unbookable"
              :knockedBack="villa.unbookable || villa.paused" :to="{
                name: 'Villa availability',
                params: {
                  villaId: villa.villaId
                }
              }" :buttonText="'Edit Availability'" v-for="villa in filteredVillas" :key="villa.villaID">

              <div class="info">
                <b-row>
                  <b-col md="12" lg="9">
                    <ul>
                      <li>Address: <strong>{{ villa.address1 }}</strong></li>
                      <li>Community: <strong>{{ villa.mcVillaCode }}</strong></li>
                    </ul>

                    <div class="cont-outer">
                      <div class="cont">
                        Bedrooms <span class="highlight">{{ villa.bedrooms }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </feed-item>
          </div>
        </div>
      </div>
    </template>
    <loader width="100px" height="100px" v-else></loader>
  </div>
</template>

<script>
import loader from '@/components/global/loader'

export default {
  components: {
    loader
  },

  name: 'VillaAvailabilityFeed',

  mounted () {
    this.fetchFeed()
  },

  methods: {
    fetchFeed () {
      this.$apiCaller({
        route: '/properties/availability-feed'
      })
        .then(response => {
          this.villas = response.data.feed

          const uniqueBedrooms = []
          const uniqueBedroomCounts = {}

          response.data.feed.forEach(feedItem => {
            if (!uniqueBedroomCounts.hasOwnProperty(feedItem.bedrooms)) {
              uniqueBedroomCounts[feedItem.bedrooms] = 0
            }

            uniqueBedroomCounts[feedItem.bedrooms]++
            uniqueBedrooms.push(feedItem.bedrooms)
          })

          this.bedroomFilterOptions = [
            { value: null, text: '-- All Bedrooms -- (' + response.data.feed.length + ')' }
          ]
          Object.keys(uniqueBedroomCounts).forEach(numberOfBeds => {
            this.bedroomFilterOptions.push(
              {
                value: numberOfBeds,
                text: numberOfBeds + ' Bedrooms (' + uniqueBedroomCounts[numberOfBeds] + ')'
              }
            )
          })
        }).finally(() => {
          this.loading = false
        })
    },
    resetSearchTextFilter () {
      this.searchTextFilter = ''
    }
  },
  computed: {
    villaID: function () {
      return this.$route.params.villaId
    },
    filteredVillas () {
      let filteredVillas = this.villas

      if (this.bedroomFilterSelected) {
        filteredVillas = filteredVillas.filter(villa => {
          return villa.bedrooms === this.bedroomFilterSelected
        })
      }

      if (this.searchTextFilter.length) {
        filteredVillas = filteredVillas.filter(villa => {
          return villa.address1.toLowerCase().includes(this.searchTextFilter.toLowerCase()) ||
            villa.mcVillaCode.toLowerCase().includes(this.searchTextFilter.toLowerCase()) ||
            villa.villaCode.toLowerCase().includes(this.searchTextFilter.toLowerCase())
        })
      }

      return filteredVillas
    }
  },

  data () {
    return {
      villas: [],
      bedroomFilterOptions: [],
      bedroomFilterSelected: null,
      searchTextFilter: '',
      loading: true
    }
  }
}
</script>
