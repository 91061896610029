<template>
  <div class="feed-entry">
    <div :class="{
      'content': true,
      'knocked-back': knockedBack
    }">
      <b-row align-v="center">
        <b-col cols="12" sm="12" lg="3" xl="3" v-if="imgSrc">
          <b-img center :src="imgSrc" :alt="villaAddress" fluid rounded="true" />
        </b-col>

        <b-col cols="12" lg="9" xl="9">
          <div class="inner">
            <slot>
              This villa entry has no content...
            </slot>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="footer">
      <b-row>
        <b-col lg="3" v-if="villaCode">
          <div class="villaCode">
            {{ villaCode }}
          </div>
        </b-col>

        <b-col v-if="villaAddress">
          <div class="address">
            {{ villaAddress }}
          </div>
        </b-col>

        <b-col cols="12" lg="3" v-if="unbookable || paused" class="mt-2 mt-lg-0">
          <span class="available-statuses">
            <template v-if="unbookable">Unbookable</template>
            <template v-if="unbookable && paused"> & </template>
            <template v-if="paused">Under Maintenance</template>
          </span>
        </b-col>

        <b-col lg="3">
          <b-button variant="secondary" :to="to" block v-if="to">
            <span v-if="buttonText">
              {{ buttonText }}
            </span>
            <span v-else>
              View
            </span>
          </b-button>

          <b-button variant="secondary" :href="href" block v-if="href">
            <span v-if="buttonText">
                {{ buttonText }}
              </span>
              <span v-else>
                View
            </span>
          </b-button>

        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'feed-item',
  props: {
    imgSrc: {
      type: String,
      required: false,
      default: null
    },
    villaId: {
      required: false
    },
    villaCode: {
      required: false
    },
    villaAddress: {
      type: String,
      required: true
    },
    unbookable: {
      type: Boolean,
      default: false
    },
    paused: {
      type: Boolean,
      default: false
    },
    knockedBack: {
      type: Boolean,
      default: false
    },
    to: {
      required: false
    },
    href: {
      type: String,
      required: false
    },
    buttonText: {
      type: String,
      required: false
    }
  },

  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/styles/_variables.scss';
@import './node_modules/bootstrap/scss/bootstrap.scss';

// Individual villas
.feed-entry {
  margin-bottom: $grid-gutter-width / 2;
  background-color: $gray-100;
  border: 1px solid $gray-400;

  .content {
    padding: $grid-gutter-width / 2;

    &.knocked-back {
      opacity: 0.3;
    }
  }

  img {
    border: 1px solid $gray-500;

    @include media-breakpoint-only(xs) {
      max-width: 150px;
      margin-bottom: $grid-gutter-width / 2;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .info {
      padding-left: $grid-gutter-width / 3;
      padding-right: $grid-gutter-width / 3;

    ul {
      padding: 0;
      margin: 0 0 ($grid-gutter-width / 3) 0;
      list-style: none;
      color: $gray-700;
    }

    .cont-outer {
      display: flex;
      flex-direction: row;

      .cont {
        font-size: 1.1em;
        color: $gray-500;
        border-left: 1px solid $gray-500;
        padding: 0 ($grid-gutter-width / 2);

        @include media-breakpoint-only(md) {
          font-size: 0.8em;
        }

        @include media-breakpoint-down(md) {
          padding: 0;
          flex-grow: 1;
          text-align: center;
          margin-bottom: $grid-gutter-width / 2;
        }

        &:last-of-type {
          // border-right: 1px solid $gray-500;
        }

        .highlight {
          font-weight: bold;
          color: $blue;
        }
      }
    }

    .status-cont {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-content: flex-end;
      align-items: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .status {
        display: block;
        text-align: center;
        padding: ($grid-gutter-width / 3);
        width: 100%;
        background: $gray-700;
        color: #ffffff;
        font-weight: 400;
        text-transform: uppercase;

        @include media-breakpoint-only(lg) {
          font-size: 0.7em
        }

        @include media-breakpoint-down(md) {
          flex-grow: 1;
          border-right: 2px solid #ffffff;

          &:last-of-type {
            border-right: none;
          }
        }

        &.live {
          background: $red;
        }

        &.unbookable {
          // font-size: 0.7em;
          border-bottom: 2px solid #ffffff;

          @include media-breakpoint-down(md) {
            border-bottom: none;
            font-size: 1em;
          }
        }
      }
    }

    }

    ul {
      &.bookings {
        list-style: none;
        padding: 0;

        li {
          color: $gray-500;
          margin-bottom: $grid-gutter-width / 2;

          &:last-of-type {
            margin-bottom: 0;
          }

          strong {
            color: $blue;
          }
        }
      }

      &.rates {
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-bottom: 0;

        li {
          margin-bottom: 0;
          color: $blue;
          text-align: center;
          flex-basis: 25%;

          &:first-of-type {
            .rate-container {
              border-left: 1px solid $gray-700;
            }
          }

          .rate-container {
            font-size: 1.3em;
            font-weight: bold;
            border-right: 1px solid $gray-700;

            h3 {
              margin-bottom: 0;
              color: $gray-500;
              font-size: 0.85em;
              font-weight: 300;
            }

            small {
              display: block;
              font-weight: 300;
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }

  .footer {
    background: $light-yellow;
    padding: $grid-gutter-width / 2;
    margin-top: $grid-gutter-width / 2;
    border: none;

    .villaCode, .address {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    .villaCode {
      font-weight: bold;
      text-align: left;
      font-size: 1.1em;
      color: $gray-700;
    }

    .address {
      color: $gray-600;
      font-size: 1.1em;

      @include media-breakpoint-down(md) {
        text-align: center;
        margin-bottom: $grid-gutter-width / 2;
      }
    }

    .btn-secondary {
      padding: ($grid-gutter-width / 4) 0;
      @include background-green-gradient;
      border: none;
    }
  }
}
</style>
