<template>
	<div>
    <b-row>
      <b-col>
        <p>Welcome, this is where you can set your rates for this year and the next two years, tell us your cleaning fees if you want to keep those separate and your pool heating charges. Everything is on a villa by villa basis so if you have more than one property listed with us you can modify each one separately.</p>
        <p>Any changes you make and save will become effective immediately but any changes you do make will not apply to previously placed bookings.</p>
        <p>If you require assistance or have any questions about Owner Log-in then please contact us and we will help guide you through anything you don’t understand.</p>
        <p>We will be adding more and more features during the coming few months so any feedback you wish to share with us is important.</p>
        <p>We recommend you start by reading through our user guide, which you can always find in the "Need help?" section or by clicking the following link: <b-link :href="theUserGuidePdfUrl" target="_blank">View User Guide</b-link></p>
      </b-col>
    </b-row>
	</div>
</template>

<script>
import { userGuidePdfUrl } from '@/variables.js'
export default {
  data () {
    return {
      theUserGuidePdfUrl: userGuidePdfUrl
    }
  }
}
</script>
