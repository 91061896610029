const fieldValidationRules = {
  install (Vue) {
    Vue.prototype.$validationRules = (validationScheema) => {
      const validation = {}
      for (const key in validationScheema) {
        if (validationScheema.hasOwnProperty(key)) {
          validation[key] = (() => {
            const parseValidation = (validationRules) => {
              const rulesObj = {}

              if (typeof validationRules === 'object' && validationRules !== null) {
                for (const validationKey in validationRules) {
                  if (validationRules.hasOwnProperty(validationKey)) {
                    rulesObj[validationKey] = parseValidation(validationRules[validationKey])
                  }
                }
              }

              if (typeof validationRules === 'string' && validationRules !== null) {
                const rules = validationRules.split('|')

                for (let i = 0; i < rules.length; i++) {
                  const ruleParams = rules[i].split(',')

                  if (ruleParams[0] !== 'boolean') { // vee-validate doesn't validate boolean values by default
                    let ruleKey = null
                    let ruleValue = null

                    switch (ruleParams[0]) {
                      case 'contains_list':
                        ruleValue = ruleParams[1].split(';')
                        ruleKey = 'included'
                        break

                      case 'doesnt_contain_list':
                        ruleValue = ruleParams[1].split(';')
                        ruleKey = 'excluded'
                        break

                      case 'min_numeric':
                        ruleValue = ruleParams[1]
                        ruleKey = 'min_value'
                        break

                      case 'max_numeric':
                        ruleValue = ruleParams[1]
                        ruleKey = 'max_value'
                        break

                      case 'integer':
                        ruleValue = true
                        ruleKey = 'numeric'
                        break

                      case 'numeric':
                        ruleValue = true
                        ruleKey = 'decimal'
                        break

                      case 'decimal':
                        ruleValue = ruleParams[1]
                        ruleKey = 'decimal'
                        break

                      case 'max_len':
                        ruleValue = ruleParams[1]
                        ruleKey = 'max'
                        break

                      case 'date':
                        ruleValue = 'yyyy-MM-dd'
                        ruleKey = 'date_format'
                        break

                      default:
                        ruleValue = true
                        ruleKey = ruleParams[0]
                    }

                    rulesObj[ruleKey] = ruleValue
                  }
                }
              }

              return rulesObj
            }

            return parseValidation(validationScheema[key])
          })()
        }
      }

      return validation
    }

    // veeFields = this.veeFields
    // fieldErrors = this.errors
    Vue.prototype.$validateState = (veeFields, fieldErrors, ref, fieldDisabled = false/*, forceInvalidState = false */) => {
      // console.log('validating')
      // console.log(veeFields)
      // console.log(fieldErrors)
      // console.log(ref)
      if (fieldDisabled) {
        return null
      }

      if (veeFields[ref] && (veeFields[ref].dirty || veeFields[ref].validated)) {
        return !fieldErrors.has(ref)
      }

      // if (forceInvalidState) {
      // return false
      // }
    }
  }
}

export default fieldValidationRules
