<template>
  <div v-show="matchesBedroomFilter && matchesSearchTextFilter">
    <b-container class="rates-feed-property">
      <b-row>
        <b-col>
          <div :class="knockedBack?'knocked-back':''">
            <!-- <slot></slot> -->
            <b-row>
              <b-col cols="12" sm="3" class="text-center text-sm-left">
                <b-img :src="imgSrc" fluid></b-img>
              </b-col>
              <b-col cols="12" sm="9" class="text-center text-sm-left">
                <template v-if="rateAssigned">
                  <template v-if="!(external && sourceType === 'ciirusXML')">
                    <strong>Pool Heating Tariff:</strong> {{rate.poolHeatingName}}<br />
                    <strong>Cleaning Fee:</strong> {{rate.cleaningFeeName}}<br />
                    <template v-if="!external">
                      <strong>Season Set Name:</strong> {{rate.seasonSetName}}<br />
                    </template>
                  </template>
                  <template v-if="!paused">
                    <b-link :href="'https://www.debbiesvillas.co.uk/orlando-properties/' + villaCode" target="_blank">View villa on Debbie's Villas</b-link>
                  </template>
                  <template v-if="external">
                    <strong class="non-assigned-rate">This is using an external rates source from {{ sourceType }} ({{ formatSourceTypeId(sourceType, sourceTypeId) }}).</strong>
                  </template>
                </template>
                <template v-else>
                  <strong class="non-assigned-rate">Rates have not been set on this property yet.</strong>
                </template>
              </b-col>
            </b-row>
            <template v-if="rateAssigned && !external">
              <b-row>
                <b-col>
                  <b-table :items="rate.rateYears" :fields="rateYearFields" class='rateyears' borderless stacked="lg">
                    <template v-slot:cell(rateLow)="data">
                      <template v-if="webPriceInsteadOfNetPricesWDisplayed">
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.item.displayRatesGBP[data.field.key] | currency }}</span> <small>(GBP)</small>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.value | currency }}</span> <small>({{ rate.currencyCode }})</small>
                      </template>
                    </template>
                    <template v-slot:cell(rateMid)="data">
                      <template v-if="webPriceInsteadOfNetPricesWDisplayed">
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.item.displayRatesGBP[data.field.key] | currency }}</span> <small>(GBP)</small>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.value | currency }}</span> <small>({{ rate.currencyCode }})</small>
                      </template>
                    </template>
                    <template v-slot:cell(rateHigh)="data">
                      <template v-if="webPriceInsteadOfNetPricesWDisplayed">
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.item.displayRatesGBP[data.field.key] | currency }}</span> <small>(GBP)</small>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.value | currency }}</span> <small>({{ rate.currencyCode }})</small>
                      </template>
                    </template>
                    <template v-slot:cell(ratePeak)="data">
                      <template v-if="webPriceInsteadOfNetPricesWDisplayed">
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.item.displayRatesGBP[data.field.key] | currency }}</span> <small>(GBP)</small>
                      </template>
                      <template v-else>
                        <span class="font-weight-bold" style="font-size:20px;">{{ data.value | currency }}</span> <small>({{ rate.currencyCode }})</small>
                      </template>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="footer">
      <b-row>
        <b-col cols="12" lg="3">
          <div class="villaCode">
            {{ villaCode }}
          </div>
        </b-col>
        <b-col cols="12" :lg="unbookable || paused? 3: 6" class="mt-2 mt-lg-0">
          <div class="address">
            {{ villaAddress }}
          </div>
        </b-col>
        <b-col cols="12" lg="3" v-if="unbookable || paused" class="mt-2 mt-lg-0">
          <span class="available-statuses">
            <template v-if="unbookable">Unbookable</template>
            <template v-if="unbookable && paused"> & </template>
            <template v-if="paused">Under Maintenance</template>
          </span>
        </b-col>
        <b-col cols="12" lg="3" class="mt-2 mt-lg-0" v-if="!external || $store.getters.isAdmin || sourceType === 'priceLabs'">
          <span class="button-area">
            <b-button variant="secondary" :to="to" block>
              {{ buttonText }}
            </b-button>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'rates-feed-property',
  props: {
    matchesBedroomFilter: {
      type: Boolean,
      required: true
    },
    matchesSearchTextFilter: {
      type: Boolean,
      required: true
    },
    villaCode: {
      type: String,
      required: true
    },
    villaAddress: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    knockedBack: {
      type: Boolean,
      required: true
    },
    paused: {
      type: Boolean,
      required: true
    },
    unbookable: {
      type: Boolean,
      required: true
    },
    rateAssigned: {
      type: Boolean,
      required: true
    },
    rate: {
      type: Object,
      required: true
    },
    webPriceInsteadOfNetPricesWDisplayed: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    external: {
      type: Boolean,
      required: false,
      default: false
    },
    sourceType: {
      type: String,
      required: false,
      default: ''
    },
    sourceTypeId: {
      type: [Number, String],
      required: false,
      default: 0
    }
  },

  methods: {
    formatSourceTypeId (sourceType, sourceTypeId) {
      switch (sourceType) {
        case 'priceLabs':
          const values = sourceTypeId.replace('id=', '').replace('pms=', '').split('|')
          return `Id: ${values[0]}, PMS: ${values[1]}`
        default:
          return sourceTypeId
      }
    }
  },

  data () {
    return {
      rateYearFields: [
        {
          key: 'yearDisplay',
          label: 'Year',
          thClass: 'invisible',
          tdClass: 'bg-secondary text-white font-weight-bold'
        },
        {
          key: 'rateLow',
          label: 'Low',
          tdClass: 'text-center text-primary bg-white'
        },
        {
          key: 'rateMid',
          label: 'Mid',
          tdClass: 'text-center text-primary bg-white'
        },
        {
          key: 'rateHigh',
          label: 'High',
          tdClass: 'text-center text-primary bg-white'
        },
        {
          key: 'ratePeak',
          label: 'Peak',
          tdClass: 'text-center text-primary bg-white'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import './src/assets/styles/_variables.scss';
@import './node_modules/bootstrap/scss/bootstrap.scss';

.rates-feed-property {
  background-color: $gray-100!important;
  border: 1px solid $gray-400;
  border-bottom: none;
  margin-top:20px;
  .knocked-back {
    opacity:0.3;
  }
}

.non-assigned-rate{
    display: block;
    @include media-breakpoint-down(xs) {
      margin-top:4px;
    }
}

.footer {
    background: $light-yellow!important;
    border: 1px solid $gray-400;
    border-top: none;
//     padding: $grid-gutter-width / 3;
//
    .villaCode, .address, .button-area, .available-statuses {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    .villaCode {
      font-weight: bold;
      text-align: left;
      font-size: 1.1em;
      color: $gray-700;
    }

    .address {
      color: $gray-600;
      font-size: 1.1em;
    }

    .available-statuses{
      color: $red;
      font-weight: bold;
      font-size: 0.8em;
      text-align: center;
    }

    .btn-secondary {
      padding-left:0px;
      padding-right:0px;
      @include background-green-gradient;
      border: none;
    }
  }

$rateYearsBorderColor: $gray-600;

table.rateyears {
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  margin-bottom:0;
  @include media-breakpoint-down(md) {
    /* stacked at this breakpoint down */
    margin-top:14px;
  }
  td, th {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  thead {
     tr {
       th {
        border: none;
        text-align:center;
        padding: 2px;
        padding-top:10px;
        color: $gray-600;
        font-weight: normal;
      }
    }
  }
  tbody {
    td {
      border-top:1px solid $rateYearsBorderColor;
      border-left:1px solid $rateYearsBorderColor;
      @include media-breakpoint-down(md) {
        /* stacked at this breakpoint down */
        border-right: 1px solid $rateYearsBorderColor;
      }
    }
    td:first-of-type{
      font-size:20px;
    }
    td:last-of-type{
      border-right: 1px solid $rateYearsBorderColor;
    }
    tr:first-of-type{
      td:last-of-type{
        border-top-right-radius: 8px;
        @include media-breakpoint-down(md) {
          /* stacked at this breakpoint down */
          border-top-right-radius: 0px;
        }
      }
    }
    tr:last-of-type{
      td {
        border-bottom: 1px solid $rateYearsBorderColor;
        @include media-breakpoint-down(md) {
          /* stacked at this breakpoint down */
          border-bottom: 0;
        }
      }
      td:last-of-type{
        border-bottom-right-radius: 8px;
        @include media-breakpoint-down(md) {
          /* stacked at this breakpoint down */
          border-bottom: 1px solid $rateYearsBorderColor;
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
}
</style>
