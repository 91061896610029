<template>
	<div class="page-503">
		<div class="content">
			<font-awesome-icon :icon="frownFace" size="2x"></font-awesome-icon>
			<h1>Currently Down for Maintenance</h1>
			<p>
				The portal is currently down for maintenance. Please check back later.<br />
        <b-link to="/">Return to homepage</b-link>
			</p>
		</div>
		<div class="bg">
			<span class="prop-ref">Background image from villa: FGSCJ-91121</span>
		</div>
	</div>
</template>

<script>
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import faFrown from '@fortawesome/fontawesome-free-solid/faFrown'

export default {
  components: {
    FontAwesomeIcon
  },

  computed: {
    frownFace () {
      return faFrown
    }
  }
}
</script>

<style lang="scss">
@import './src/assets/styles/_variables.scss';
@import './node_modules/bootstrap/scss/bootstrap.scss';
@import './src/assets/styles/404.scss';
</style>
