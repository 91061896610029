<template>
  <!-- <div class="loader-outer" > -->
	<div class="loader-container">
		<div class="loader loader--style2">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       :width="width" :height="height" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
    <path :fill="color" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
      <animateTransform attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="0.6s"
        repeatCount="indefinite"/>
      </path>
    </svg>
		</div>
	</div>
  <!-- </div> -->
</template>

<style scoped>
  /* .loader-outer {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
	.loader-container {
		position: relative;
		height: 50px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.loader{
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		z-index: 10;
		background: transparent;
		text-align: center;
		padding: 1em;
		margin: 0 auto 1em;
		display: inline-block;
		vertical-align: top;
	}
</style>

<script>
export default {
  name: 'loading',
  props: {
    width: {
      default: '40px',
      type: String
    },
    height: {
      default: '40px',
      type: String
    },
    color: {
      default: '#ffa035', // $orange
      type: String
    }
  },
  data () {
    return {}
  }
}
</script>
