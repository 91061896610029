<template>
  <div v-if="!loading">
    <div class="year-select mb-4" >
      <b-row>
        <b-col cols="12" lg="8">
          <h1 style="font-size: 1em; height: 100%; line-height: 61px;" class="mb-0">
            {{ seasonSet.itemName }}
          </h1>
        </b-col>
        <b-col cols="12" lg="4">
          <div class="pl-2 pr-2">
            <b-button style="margin: 10px 0; padding: 10px 0; text-align: center;" block variant="secondary" @click.prevent="openNewSeasonSetEditModal">
              Modify Season Set
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <calendar
      :calendarDates="dates"
      :calendarDefaults="datesDefault"
      :calendarKey="calendar.key"
      @saveSeason="saveSeasonSetDates"
      @deleteSeason="deleteSeasonSetDates">
    </calendar>
    <property-list
      noPropertiesMessage="This season set does not have any properties assigned to it."
      :properties="assignedProperties">
    </property-list>

    <b-modal id="seasonSetModal" size="lg"
      modal-class="availability-modal"
      lazy centered hide-footer title="Edit season set">
      <b-form @submit.prevent="updateSeasonSet" autocomplete="off" novalidate>
        <fieldset :disabled="formSubmitting">
      <b-row>
        <b-col>
          <label for="itemName">Season Set Name</label>
          <b-input-group>
            <b-form-input
              v-model="newSeasonSetName"
              id="itemName"
              type="text"
              data-vv-as="Season Set Name"
              name="itemName"
              :state="$validateState(veeFields, errors, 'itemName')"
              :maxlength="validationRules.itemName.max"
              v-validate="validationRules.itemName">
            </b-form-input>
            <b-form-invalid-feedback id="itemName_feedback">
              <ul style="padding-left: 0; list-style: none;">
                <li v-for="error in errors.collect('itemName')">{{ error }}</li>
              </ul>
            </b-form-invalid-feedback>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col :md="buttonColumns">
          <b-button class="m-2 m-md-0" variant="info" @click.prevent="closeEditModal">Cancel</b-button>
        </b-col>
        <b-col md="4" v-if="buttonColumns === 4">
          <b-button class="m-2 m-md-0" variant="danger" v-confirm="{
                ok: dialog => deleteSeasonSet(),
                message: 'This will delete this season set - are you sure?',
                okText: 'Yes',
                cancelText: 'No'
            }">Delete</b-button>
        </b-col>
        <b-col :md="buttonColumns">
          <b-button class="m-2 m-md-0" variant="primary" type="submit">Update</b-button>
        </b-col>
      </b-row>
    </fieldset>
  </b-form>
    </b-modal>
  </div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import loader from '@/components/global/loader'
import calendar from '../../global/calendar'
import propertyList from '../../global/miniPropertyList.vue'
import moment from 'moment'

export default {
  components: {
    loader,
    calendar,
    'property-list': propertyList
  },

  mounted () {
    this.fetchSeasonSet()
  },

  computed: {
    buttonColumns () {
      if (this.hasOwnProperty('assignedProperties')) {
        if (this.assignedProperties.length === 0) {
          return 4
        } else {
          return 6
        }
      } else {
        return 6
      }
    }
  },

  methods: {
    deleteSeasonSet () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/season-sets/' + this.$route.params.oltiId,
        method: 'DELETE'
      })
        .then(response => {
          const alertMessage = 'The season set ' + this.seasonSet.itemName + ' has now been deleted.'
          this.$dialog.alert({ body: alertMessage, title: 'Season set deleted' }, { html: false }).then(() => {
            this.$router.push({ path: '/season-sets' })
          })
        })
    },

    updateSeasonSet () {
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.setLoadingState(true)
          this.formSubmitting = true
          const seasonSets = {
            itemName: this.newSeasonSetName
          }
          this.$apiCaller({
            route: '/pricing-service/season-sets/' + this.$route.params.oltiId,
            method: 'POST',
            data: seasonSets
          })
            .then(response => {
              // let alertMessage = 'The season set ' + this.newSeasonSetName + ' has just been updated.'
              // this.$dialog.alert({body: alertMessage, title: 'Season set updated'}, {html: false})
            })
            .finally(() => {
              this.seasonSet.itemName = this.newSeasonSetName
              this.setLoadingState(false)
              this.formSubmitting = false
            })
        }
      })
    },

    openNewSeasonSetEditModal () {
      this.newSeasonSetName = this.seasonSet.itemName
      this.$bvModal.show('seasonSetModal')
    },

    closeEditModal () {
      this.$bvModal.hide('seasonSetModal')
    },

    deleteSeasonSetDates (event) {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/season-set-dates/' + this.$route.params.oltiId,
        method: 'DELETE',
        data: { season_set_dates_id: event.season.season_set_dates_id }
      })
        .then(response => {

        })
        .finally(() => {
          this.setLoadingState(false)
          this.$nextTick(() => {
            this.fetchSeasonSet()
          })
        })
    },

    saveSeasonSetDates (event) {
      const seasonSetDates = {
        dateFrom: moment(event.selectedDates.start).format('YYYY-MM-DD'),
        dateTo: moment(event.selectedDates.end).format('YYYY-MM-DD'),
        seasons_id: event.season.seasons_id
      }
      if (event.season.season_set_dates_id) {
        // editing an existing seasonSetDates
        seasonSetDates.season_set_dates_id = event.season.season_set_dates_id
      }
      this.setLoadingState(true)

      this.$apiCaller({
        route: '/pricing-service/season-set-dates/' + this.$route.params.oltiId,
        method: 'POST',
        data: seasonSetDates
      })
        .then(response => {

        })
        .finally(() => {
          this.setLoadingState(false)
          this.$nextTick(() => {
            this.fetchSeasonSet()
          })
        })
    },

    fetchSeasonSet () {
      this.setLoadingState(true)
      this.$apiCaller({
        route: '/pricing-service/season-sets/' + this.$route.params.oltiId
      })
        .then(response => {
          this.seasonSet.itemName = response.data.item.itemName
          this.newSeasonSetName = response.data.item.itemName
          this.dates = response.data.dates
          this.datesDefault = response.data.datesDefault
          this.validationRules = this.$validationRules(response.data.validationRules)
          const assignedProperties = []
          if (response.data.assignedTo.hasOwnProperty('property') && response.data.hasOwnProperty('assignedPropertiesInfo')) {
            response.data.assignedTo.property.forEach(propertyId => {
              if (response.data.assignedPropertiesInfo.hasOwnProperty(propertyId)) {
                assignedProperties.push(response.data.assignedPropertiesInfo[propertyId])
              }
            })
          }
          this.assignedProperties = assignedProperties
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    }
  },

  data () {
    return {
      loading: true,
      formSubmitting: false,
      seasonSet: {
        itemName: null
      },
      assignedProperties: [],
      newSeasonSetName: null,
      dates: [],
      datesDefault: [],
      validationRules: {},
      calendar: {
        key: [
          {
            name: 'Low',
            id: 'season_dv-low'
          },
          {
            name: 'Mid',
            id: 'season_dv-mid'
          },
          {
            name: 'High',
            id: 'season_dv-high'
          },
          {
            name: 'Peak',
            id: 'season_dv-peak'
          }
        ]
      }
    }
  }
}

</script>

<style lang="scss">
  // @import './src/assets/styles/_variables.scss';
  // @import './node_modules/bootstrap/scss/bootstrap.scss';
</style>
