import Vue from 'vue'
import Vuex from 'vuex'
import usernew from './modules/usernew'
import authnew from './modules/authnew'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    usernew,
    authnew
  },
  strict: debug
})
