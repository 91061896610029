<template>
	<div class="section-index" v-if="hasDashboard">
		<div class="dashboard-items" v-if="dashboardGroups.length">
			<b-card-group deck v-for="(dashGroup) in dashboardGroups" :key="dashGroup[0].name+dashGroup[0].path">
				<b-card no-body class="shadow" v-for="dashItem in dashGroup" :key="dashItem.path">
					<b-link :to="dashItem.path">
						<b-card-body>
							<div class="cont">
								<h4>{{ dashItem.name }}</h4>
							</div>
							<!-- <div class="cont">
								<b-img src="~/../../assets/images/icons/calendar.png" fluid
									   alt="Add a booking"/>
							</div> -->
						</b-card-body>
					</b-link>
				</b-card>
			</b-card-group>
		</div>
	</div>
  <router-view v-else></router-view>
</template>

<script>
export default {

  mounted () {
    this.createDashboard()
  },

  methods: {
    createDashboard () {
      const dashboardGroups = []
      let dashboardGroupItems
      if (this.$route.hasOwnProperty('meta')) {
        if (this.$route.meta.hasOwnProperty('dashboardGroups')) {
          this.$route.meta.dashboardGroups.forEach(dashboardGroup => {
            // console.log(dashboardGroup)
            dashboardGroupItems = []
            dashboardGroup.items.forEach(dashboardGroupItem => {
              // console.log(dashboardGroupItem)
              dashboardGroupItems.push({
                name: dashboardGroupItem.name,
                path: this.$route.fullPath + dashboardGroupItem.subpath
              })
            })
            // console.log(dashboardGroupItems)
            dashboardGroups.push(dashboardGroupItems)
          })
        }
      }
      // console.log(dashboardGroups)
      this.dashboardGroups = dashboardGroups
    }
  },

  watch: {
    hasDashboard: {
      handler: function (newValue, oldValue) { // cannot use arrow function here to do with binding? https://stackoverflow.com/questions/43965714/method-is-not-a-function-in-watcher-callback-vuejs
        this.createDashboard()
      }
    }
  },

  computed: {
    hasDashboard () {
      let hasDashboard = false
      if (this.$route.hasOwnProperty('meta')) {
        if (this.$route.meta.hasOwnProperty('dashboardGroups')) {
          hasDashboard = true
        }
      }
      return hasDashboard
    }
  },

  data () {
    return {
      dashboardGroups: []
    }
  }
}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/sections/dashboard.scss';
</style>
