<template>
	<div>
    <template v-if="!loading">
      <div class="title-container blue" v-if="propertyInfo.hasOwnProperty('coverImage')">
      <b-row>
        <b-col cols="12" lg="8" order="2" order-lg="1">
          <h1 class="alt">Edit Availability ({{ propertyInfo.villaCode }})<br/>
            <small>{{ propertyInfo.address1 }}</small>
          </h1>
        </b-col>
        <b-col cols="12" lg="4" order="1" order-lg="2">
          <div class="circle-image-container">
            <b-img :src="propertyInfo.coverImage.imgUrl+(propertyInfo.coverImage.imgUrl.includes('googleusercontent')?'-c':'')" rounded="circle" fluid/>
          </div>
        </b-col>
      </b-row>
    </div>
      <p>
        This is where you can set the availability for your property.<br/>You can set the availability for a specific date range.
      </p>
      <ul>
        <li>
          <strong>Available</strong> - Available for bookings
        </li>
        <li>
          <strong>Owner Booking</strong> - A booking from the owner which is not a Debbie's Villas booking or an external booking
        </li>
        <li>
          <strong>DV Booking</strong> - A Debbie's Villas booking
        </li>
        <li>
          <strong>External Booking</strong> - A booking from an external source
        </li>
      </ul>
      <p>Click on the calendar to select the dates you want to set the availability for.</p>
      <calendar
        :calendarDates="calendar.data"
        :calendarDefaults="[]"
        :calendarKey="calendar.keys"
        :options="{
          type: 'availability'
        }"
        @saveSeason="saveSeasonSetDates"
        @deleteSeason="saveSeasonSetDates"
      />
    </template>
    <loader width="100px" height="100px" v-else></loader>
	</div>
</template>

<script>
import calendar from '../../global/calendar'
import moment from 'moment'
import loader from '@/components/global/loader'

export default {
  data () {
    return {
      calendar: {
        data: [],
        keys: []
      },
      propertyInfo: {},
      loading: false
    }
  },

  components: {
    calendar,
    loader
  },

  mounted () {
    this.getAvailability()
  },

  methods: {
    getAvailability () {
      this.loading = true

      this.$apiCaller({
        route: '/properties/availability-calendar/' + this.$route.params.villaId
      })
        .then(async response => {
          this.calendar = response.data.response.calendar

          await this.$apiCaller({
            route: '/property-rates/' + this.$route.params.villaId
          })
            .then(response => {
              this.propertyInfo = response.data.propertyInfo
            })
        }).finally(() => {
          this.loading = false
        })
    },

    saveSeasonSetDates (event) {
      const seasonSetDates = {
        dateFrom: moment(event.selectedDates.start).format('YYYY-MM-DD'),
        dateTo: moment(event.selectedDates.end).format('YYYY-MM-DD'),
        previousDateFrom: moment(event.previousDates.start).format('YYYY-MM-DD'),
        previousDateTo: moment(event.previousDates.end).format('YYYY-MM-DD'),
        seasons_id: event.season.seasons_id,
        reference: event.reference || ''
      }

      this.$apiCaller({
        route: '/properties/availability-calendar/' + this.$route.params.villaId,
        method: 'POST',
        data: seasonSetDates
      })
        .then(() => {
          this.getAvailability()
        })
    }
  }
}

</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/calendar.scss';
</style>
