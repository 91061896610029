<template>
	<div class="period-feed-container" v-if="!loading">
		<div class="title-container">
			<b-row>
				<b-col cols="12" lg="8">
					<h1>Active Discount Periods</h1>
				</b-col>
				<b-col cols="12" lg="4">
					<b-button variant="secondary" :to="newDiscountPeriodLink">
						New Discount Period
					</b-button>
				</b-col>
			</b-row>
		</div>

		<div class="period-feed">
			<div class="discount-periods" v-if="discountPeriods.length > 0">
				<div class="header">
					<b-row>
						<b-col md="3">
							<div class="inner">
								Percentage Discount
							</div>
						</b-col>
						<b-col md="3">
							<div class="inner">
								Period Name
							</div>
						</b-col>
						<b-col md="2">
							<div class="inner">
								Start
							</div>
						</b-col>
						<b-col md="2">
							<div class="inner">
								&nbsp;
							</div>
						</b-col>
						<b-col md="2">
							<div class="inner">
								End
							</div>
						</b-col>
					</b-row>
				</div>

				<b-row v-for="discountPeriod in discountPeriods" :key="discountPeriod.owner_link_to_item_id">
					<b-col cols="12">
						<b-row>
							<b-col cols="8" sm="6" lg="3" order="2" order-lg="1" offset="2" offset-sm="3" offset-lg="0">
								<div class="discount-percentage-container">
									<div class="discount-percentage">
										<div class="discount-percentage-inner">
											<div class="fb-center">
												<div class="vp-size">
													{{ discountPeriod.discountPercentage }}%
												</div>
											</div>
										</div>
									</div>
								</div>
							</b-col>
							<b-col cols="12" lg="3" order="1" order-lg="2">
								<div class="period-name">
									<div class="inner">
										{{ discountPeriod.itemName }}
									</div>
								</div>
							</b-col>
							<b-col cols="5" lg="2" order="3" order-lg="3">
								<div class="period-start">
									<div class="inner">
										{{ parseDate(discountPeriod.startDate) }}
									</div>
								</div>
							</b-col>
							<b-col cols="2" lg="2" order="4" order-lg="4">
								<div class="period-divider">
									<div class="inner">
										<span class="divider">&nbsp;</span>
									</div>
								</div>
							</b-col>
							<b-col cols="5" lg="2" order="5" order-lg="5">
								<div class="period-end">
									<div class="inner">
										{{ parseDate(discountPeriod.endDate) }}
									</div>
								</div>
							</b-col>
						</b-row>

						<div class="footer-row">
							<b-row align-h="end">
								<b-col cols="4">
									<b-button size="sm" variant="primary" :to="'/discount-periods/' + discountPeriod.owner_link_to_item_id">
										Edit Period
									</b-button>
								</b-col>
							</b-row>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="no-discount-periods" v-else>
				There are no discount periods set up. Click <b-link :to="newDiscountPeriodLink"><strong>New Discount Period</strong></b-link> to create one.
			</div>
		</div>
	</div>
  <loader width="100px" height="100px" v-else></loader>
</template>

<script>
import moment from 'moment'
import loader from '@/components/global/loader'
export default {
  components: {
    loader
  },
  name: 'DiscountPeriodFeed',

  mounted () {
    this.fetchDiscountPeriods()
  },

  methods: {
    fetchDiscountPeriods () {
      this.setLoadingState(true)

      this.$apiCaller({
        route: '/pricing-service/discount-periods'
      })
        .then(response => {
          this.discountPeriods = response.data.feedItems
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },

    setLoadingState (loadingState) {
      this.loading = loadingState
    },

    parseDate (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
  },

  data () {
    return {
      loading: true,
      newDiscountPeriodLink: '/discount-periods/0',
      discountPeriods: []
    }
  }
}
</script>

<style lang="scss">
	@import './src/assets/styles/_variables.scss';
	@import './node_modules/bootstrap/scss/bootstrap.scss';
	@import './src/assets/styles/sections/rates/discounts/period-feed.scss';
</style>
